import React from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  InputLabel,
  Grid,
} from '@mui/material';
import dayjs from 'dayjs';
import {Tag} from 'antd';

const TeamSelection = ({
    selectedTeam,
    setSelectedTeam,
    teamNames,
    teamRoster,
    handleRosterSelection,
    handleVehicleSelection,
    fetchTeamRoster,
    selectedRoster,
    setSelectedRoster,
    days, // Pass days state from parent
}) => {
  // Utilize the existing calculation methods to calculate total hours
  const calculateTotalHoursForMember = () => {
    return days.reduce((total, day) => {
      if (!day.selected) return total;
      
      // Loop through each time interval for the day
      day.intervals.forEach(interval => {
        const start = dayjs(interval.startTime, 'HH:mm');
        const end = dayjs(interval.endTime, 'HH:mm');
        const diffInMinutes = end.diff(start, 'minute');
        const diffInHours = diffInMinutes / 60;
        total += diffInHours;
      });
  
      return total;
    }, 0);
  };

  const getRoleColor = (roleOrClassification) => {
    switch(roleOrClassification) {
      case 'Lineman':
        return <Tag color="lime">Lineman</Tag>;
      case 'Line Foreman':
        return <Tag color="magenta">Line Foreman</Tag>;
      case 'Line Apprentice':
        return <Tag color="red">Line Apprentice</Tag>;
      case 'Line Equipment Operator':
        return <Tag color="volcano">Line Equipment Operator</Tag>;
      case 'Line Groundman':
        return <Tag color="orange">Line Groundman</Tag>;
      case 'Line Cable Splicer':
        return <Tag color="gold">Line Cable Splicer</Tag>;
      case 'Working Foreman':
        return <Tag color="lime">Working Foreman</Tag>;
      case 'Service Crew Electrician':
        return <Tag color="green">Service Crew Electrician</Tag>;
      case 'Field Safety':
        return <Tag color="cyan">Field Safety</Tag>;
      case 'Field Support':
        return <Tag color="blue">Field Support</Tag>;
      case 'Flagger':
        return <Tag color="geekblue">Flagger</Tag>;
      case 'Line General Foreman':
        return <Tag color="magenta">Line General Foreman</Tag>;
      case 'Tree General Foreman':
        return <Tag color="red">Tree General Foreman</Tag>;
      case 'Vehicle Mechanic':
        return <Tag color="volcano">Vehicle Mechanic</Tag>;
      case 'Damage Assessor':
        return <Tag color="orange">Damage Assessor</Tag>;
      case 'Driver':
        return <Tag color="gold">Driver</Tag>;
      case 'Wires Down Guard':
        return <Tag color="lime">Wires Down Guard</Tag>;
      case 'Tree Climber':
        return <Tag color="green">Tree Climber</Tag>;
      case 'Tree Foreman':
        return <Tag color="cyan">Tree Foreman</Tag>;
      case 'Tree Groundman':
        return <Tag color="blue">Tree Groundman</Tag>;
      default:
        return <Tag color="geekblue">{roleOrClassification}</Tag>;
    }
  };
  const getCrewTypeColor = (crewType) => {
    switch(crewType) {
      case 'Contractor Line':
        return <Tag color="#E57373">Contractor Line</Tag>;
      case 'Contractor Service':
        return <Tag color="#81C784">Contractor Service</Tag>;
      case 'Damage Appraiser':
        return <Tag color="#08d456">Damage Appraiser</Tag>;
      case 'Wire Down':
        return <Tag color="#8930fc">Wire Down</Tag>;
      case 'Sub-Contractors':
        return <Tag color="#BA68C8">Sub-Contractors</Tag>;
      case 'Transmission':
        return <Tag color="#BA68C8">Transmission</Tag>;
      case 'Contractor Tree':
        return <Tag color="#BA68C8">Contractor Tree</Tag>;
      case 'Contractor Support':
        return <Tag color="#BA68C8">Contractor Support</Tag>;
      default:
        return <Tag color="geekblue">{crewType}</Tag>;
    }
  };
  

  return (
    <Grid item xs={12}>
      <FormControl fullWidth>
        <InputLabel>Crew Selection</InputLabel>
        <Select
          value={selectedTeam}
          onChange={(e) => {
            setSelectedTeam(e.target.value);
            fetchTeamRoster(e.target.value);
          }}
        >
          {teamNames.map((teamName, index) => (
            <MenuItem key={index} value={teamName}>{teamName}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Member Type</TableCell>
              <TableCell>Crew Type</TableCell>
              <TableCell>Personal Vehicle</TableCell>
              <TableCell>Total Hours</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teamRoster.roster.map((member, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Checkbox 
                    checked={teamRoster.roster.some(r => r.firstName === member.firstName && r.lastName === member.lastName)} 
                    onChange={(e) => handleRosterSelection(e, member)}
                    color="secondary"
                  />
                </TableCell>
                <TableCell>{member.firstName}</TableCell>
                <TableCell>{member.lastName}</TableCell>
                <TableCell>{getCrewTypeColor(member.crewType)}</TableCell>
                <TableCell>{getRoleColor(member.role || member.classification)}</TableCell>
                <TableCell>
                  <Checkbox 
                    checked={member.hasPersonalVehicle || false} 
                    onChange={(e) => handleVehicleSelection(e, member)}
                    color="secondary"
                  />
                </TableCell>
                <TableCell>{calculateTotalHoursForMember()}</TableCell> {/* Render total hours */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default TeamSelection;

