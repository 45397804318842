import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Button,
  Typography
} from '@mui/material';
import CreateStormEvent from './CreateStormEvent';  // Import the new child component
import CreateTeams from './CreateTeams';  // Import the new child component
import { addData } from '../firebase';
import { useNavigate } from 'react-router-dom';

const StormEvents = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [stormEventData, setStormEventData] = useState({});
  const [teams, setTeams] = useState([]);
  const navigate = useNavigate();

  // State variables for CreateStormEvent form
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [location, setLocation] = useState("");
  const [utilityName, setUtilityName] = useState("");
  const [stormWorkOrder, setStormWorkOrder] = useState("");
  const [stormName, setStormName] = useState("");

  useEffect(() => {
    console.log('Component Mounted / Updated');
  }, []);

  const steps = ['Create Storm Event', 'Build Crews'];

  const handleNext = () => {
    if (activeStep === 0) {
      // Check if all the fields are filled in
      if (!eventName || !eventDate || !location || !utilityName || !stormWorkOrder) {
        alert('Please fill out the Storm Event form.');
        return;
      }
      // Update the stormEventData state
      setStormEventData({ eventName, eventDate, location, utilityName, stormWorkOrder });
      setStormName(eventName);
 // You can set stormName like this or any other way you prefer
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    if (Object.keys(stormEventData).length === 0 || teams.length === 0) {
      alert('Please complete all steps.');
      return;
    }

    const completeData = {
      ...stormEventData,
      teams,
      status: 'active'
    };

    const stormEventId = await addData('stormEvents', completeData);

    if (stormEventId) {
      alert('Storm event created successfully!');
      navigate('/bullpen', { state: { teams } });
    }
  };

  return (
    <>
      <Container>
      <Box textAlign="center" mt={4} mb={4}>
        <Typography variant="h4" style={{ marginTop: 20, marginBottom: 10, fontFamily: 'Monospace', fontWeight: 'bold', color: '#1976d2' }}>
          Create Storm Event
        </Typography>
      </Box>
        <Box mt={5}>
        <Stepper activeStep={activeStep} alternativeLabel sx={{ bgcolor: 'secondary' }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel sx={{ color: 'secondary', '&.Mui-active': { color: 'primary' } }}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
          <Box border={1} borderColor="secondary" borderRadius={4} p={3} m={2}>
            <Grid container spacing={2} justify="center">
              {activeStep === 0 && (
                <CreateStormEvent
                  eventName={eventName} setEventName={setEventName}
                  eventDate={eventDate} setEventDate={setEventDate}
                  location={location} setLocation={setLocation}
                  utilityName={utilityName} setUtilityName={setUtilityName}
                  stormWorkOrder={stormWorkOrder} setStormWorkOrder={setStormWorkOrder}
                />
              )}
              {activeStep === 1 && (
                <CreateTeams 
                setTeams={setTeams} 
                teams={teams} 
                eventDate={eventDate} 
                stormName={stormName} 
                stormWorkOrder={stormWorkOrder} 
                utilityName={utilityName} 
                location={location} 
              />
              
              )}
              {activeStep !== 0 && (
                <Grid item xs={12} sm={6} md={4}>
                  <Box m={2} display="flex" justifyContent="center">
                    <Button variant="contained" color="secondary" onClick={handleBack} fullWidth size="large">
                      Back
                    </Button>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={4}>
                <Box m={2} display="flex" justifyContent="center">
                  <Button variant="outlined" color="secondary" onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext} fullWidth size="large">
                    {activeStep === steps.length - 1 ? 'Create Storm Event' : 'Next'}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default StormEvents;