import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, addData } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { Button, TextField, Grid, Typography, Container, MenuItem } from '@mui/material';


const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState('');
  const [crewType, setCrewType] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Create the user account with email and password
      const { user } = await createUserWithEmailAndPassword(auth, email, password);

      // Add user data to Firestore
      await addData('users', {
        uid: user.uid,
        email: user.email,
        firstName,
        lastName,
        phone,
        role,
        crewType,
      });

      navigate('/');
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
     
      <Typography component="h1" variant="h5" color="secondary">
        Sign Up New Employee
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <TextField
  select
  variant="outlined"
  margin="normal"
  required
  fullWidth
  label="Classification"
  value={role}
  onChange={(e) => setRole(e.target.value)}
>
  <MenuItem value="General Foreman">General Foreman</MenuItem>
  <MenuItem value="Foreman">Foreman</MenuItem>
  <MenuItem value="Journeyman">Journeyman</MenuItem>
  <MenuItem value="Lineman">Lineman</MenuItem>
  <MenuItem value="Equipment Operator">Equipment Operator</MenuItem>
  <MenuItem value="Service">Service</MenuItem>
  <MenuItem value="Wire Guard">Wire Guard</MenuItem>
  <MenuItem value="Support">Support</MenuItem>
  <MenuItem value="Safety">Safety</MenuItem>
  
</TextField>

        <TextField
          select
          variant="outlined"
          margin       ="normal"
          required
          fullWidth
          label="Crew Type"
          value={crewType}
          onChange={(e) => setCrewType(e.target.value)}
        >
          <MenuItem value="Line Crews">Line Crews</MenuItem>
          <MenuItem value="Sub-Contractors">Sub-Contractors</MenuItem>
          <MenuItem value="Service Crews">Service Crews</MenuItem>
          <MenuItem value="Damage Assessors">Damage Assessors</MenuItem>
          <MenuItem value="Wire Guards">Wire Guards</MenuItem>
          
        </TextField>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
        >
          Sign Up
        </Button>
        <Grid container justifyContent="center">
          <Grid item>
            <Button onClick={() => navigate('/login')} color="secondary" variant="text">
              Already have an account? Login
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
    );
  };
  
  export default SignUp;
