import React, { useEffect, useState, useRef } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  TextField,
  IconButton
} from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp'; // Export icon
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase';
import PrintableReportCard from './PrintableReportCard';

const DailyReportCard = () => {
  const [dailyReports, setDailyReports] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const printRef = useRef([]);

  useEffect(() => {
    // Fetch daily reports from firestore and update state
    const fetchDailyReports = async () => {
      const dailyReportCollection = collection(firestore, 'dailyReports');
      const dailyReportData = await getDocs(dailyReportCollection);
      const dailyReportsList = dailyReportData.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setDailyReports(dailyReportsList); // Update the dailyReports state with fetched data
    };

    fetchDailyReports(); // Call the function to fetch daily reports
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const sortedReports = [...dailyReports].sort((a, b) => new Date(b.date) - new Date(a.date));
  const lastTenReports = sortedReports.slice(0, 20);

  const filteredReports = dailyReports.filter((report) =>
    Object.values(report).some((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

// Function to export card content as PDF
const exportPDF = async (documentId) => {
  // Find the index of the report in the original array using the document ID
  const index = dailyReports.findIndex(report => report.id === documentId);
  
  // index to access the correct element in printRef
  const element = printRef.current[index];

  if (!element) {
    console.error("Element not found for PDF export.");
    return;
  }

  const canvas = await html2canvas(element, {
    scale: 5, // Adjust scale as needed for resolution
    useCORS: true
  });

  const imgData = canvas.toDataURL('image/png');
  const pdf = new jsPDF({
    orientation: 'p', // 'p' for portrait, 'l' for landscape
    unit: 'pt',
    format: 'a4' // Standard A4 size
  });

  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();
  const canvasWidth = canvas.width;
  const canvasHeight = canvas.height;

  // Check and correct dimensions
  const scale = Math.min(pdfWidth / canvasWidth, pdfHeight / canvasHeight);
  const x = (pdfWidth - canvasWidth * scale) / 2;
  const y = (pdfHeight - canvasHeight * scale) / 2;

  // Debugging logs
  console.log('PDF Dimensions:', pdfWidth, pdfHeight);
  console.log('Canvas Dimensions:', canvasWidth, canvasHeight);
  console.log('Image Position and Scale:', x, y, scale);

  // Validate dimensions before adding the image
  if (x >= 0 && y >= 0 && scale > 0) {
    pdf.addImage(imgData, 'PNG', x, y, canvasWidth * scale, canvasHeight * scale);
    pdf.save('download.pdf');
  } else {
    console.error('Invalid dimensions for PDF export');
  }
};

  return (
    <div>
      <TextField
        label="Search Daily Reports"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearchChange}
        style={{ marginBottom: '20px', width: '100%' }}
      />
      <Grid container spacing={3}>
        {(searchTerm ? filteredReports : lastTenReports).map((report, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card style={{ height: '500px', overflow: 'auto', marginBottom: '20px' }} id={`card-${index}`}>
              <CardHeader
                action={
                  <IconButton aria-label="export" onClick={() => exportPDF(report.id)}>
                  <GetAppIcon />
                  </IconButton>
                }
                title={
                  <Typography variant="h6" style={{ lineHeight: '1.5' }}>
                    {report.date} - {report.selectedStormEvent}
                  </Typography>
                }
              />
       <CardContent>
        <Typography variant="body2" style={{ fontWeight: 'bold' }}>Bird Dogs:</Typography>
        <Typography variant="body2">{report.birdDogs}</Typography>
        <Typography variant="body2" style={{ fontWeight: 'bold' }}>Crew Working:</Typography>
        <Typography variant="body2">{report.crewWorking}</Typography>
        <Typography variant="body2" style={{ fontWeight: 'bold' }}>Foreman Name:</Typography>
        <Typography variant="body2">{report.foremanName}</Typography>
        <Typography variant="body2" style={{ fontWeight: 'bold' }}>Classification:</Typography>
        <Typography variant="body2">{report.classification}</Typography>
        <Typography variant="body2" style={{ fontWeight: 'bold' }}>Email:</Typography>
        <Typography variant="body2">{report.email}</Typography>
        <Typography variant="body2" style={{ fontWeight: 'bold' }}>Phone:</Typography>
        <Typography variant="body2">{report.phone}</Typography>
        <Typography variant="body2" style={{ fontWeight: 'bold' }}>Lodging Provided By:</Typography>
        <Typography variant="body2">{report.lodgingProvidedBy}</Typography>
        <Typography variant="body2" style={{ fontWeight: 'bold' }}>Hotel Name:</Typography>
        <Typography variant="body2">{report.hotelName}</Typography>
        <Typography variant="body2" style={{ fontWeight: 'bold' }}>Hotel City:</Typography>
        <Typography variant="body2">{report.hotelCity}</Typography>
        <Typography variant="body2" style={{ fontWeight: 'bold' }}>Hotel Address:</Typography>
        <Typography variant="body2">{report.hotelAddress}</Typography>
        <Typography variant="body2" style={{ fontWeight: 'bold' }}>Number of Buckets:</Typography>
        <Typography variant="body2">{report.numBuckets}</Typography>
        <Typography variant="body2" style={{ fontWeight: 'bold' }}>Number of Diggers:</Typography>
        <Typography variant="body2">{report.numDiggers}</Typography>
        <Typography variant="body2" style={{ fontWeight: 'bold' }}>Vehicle Issues:</Typography>
        <Typography variant="body2">{report.vehicleIssues}</Typography>
{/* Display the signature image */}
        <Typography variant="body2" style={{ fontWeight: 'bold' }}>Signature:</Typography>
          <img 
           src={`${report.signature}`} 
           alt="Signature"
           style={{ width: '100%', height: 'auto' }}
                />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <div style={{ position: 'absolute', left: '-10000px', top: '0px' }}>
  {dailyReports.map((report, index) => (
    <PrintableReportCard key={index} report={report} ref={el => printRef.current[index] = el} />
  ))}
    </div>
    </div>
  );
};

export default DailyReportCard;



