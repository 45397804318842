
import React from 'react';
import {
  Box,
  Container,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

const CreateStormEvent = ({
  eventName, setEventName,
  eventDate, setEventDate,
  location, setLocation,
  utilityName, setUtilityName,
  stormWorkOrder, setStormWorkOrder
}) => {
  return (
    <Container>
      <Grid container spacing={2}>
        {/* Storm Event Name */}
        <Grid item xs={12} sm={6}>
          <Box m={2}>
            <TextField
              label="Storm Event Name"
              fullWidth
              variant="outlined"
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
              
            />
          </Box>
        </Grid>

        {/* Event Date */}
        <Grid item xs={12} sm={6}>
          <Box m={2}>
            <TextField
              label="Event Date"
              type="date"
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={eventDate}
              onChange={(e) => setEventDate(e.target.value)}
              
            />
          </Box>
        </Grid>

{/* Utility Name */}
<Grid item xs={12} sm={6}>
  <Box m={2}>
    <FormControl fullWidth variant="outlined">
      <InputLabel id="utility-name-label">Utility Name</InputLabel>
      <Select
        labelId="utility-name-label"
        id="utility-name-select"
        value={utilityName}
        onChange={(e) => setUtilityName(e.target.value)}
        label="Utility Name"
      >
        <MenuItem value={"Eversource - MA"}>Eversource - MA</MenuItem>
        <MenuItem value={"Eversource - NH"}>Eversource - NH</MenuItem>
        <MenuItem value={"Eversource - CT"}>Eversource - CT</MenuItem>
        <MenuItem value={"Eversource - NY"}>Eversource - NY</MenuItem>
      </Select>
    </FormControl>
  </Box>
</Grid>
   {/* Storm Work Order */}
        <Grid item xs={12} sm={6}>
          <Box m={2}>
            <TextField
              label="Storm Work Order #"
              fullWidth
              variant="outlined"
              value={stormWorkOrder}
              onChange={(e) => setStormWorkOrder(e.target.value)}
              
            />
          </Box>
        </Grid>

        {/* Location */}
        <Grid item xs={3}>
          <Box m={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="location-label">State</InputLabel>
              <Select
                labelId="location-label"
                id="location-select"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                label="Location"
                
              >
                <MenuItem value={"MA"}>MA</MenuItem>
                <MenuItem value={"NH"}>NH</MenuItem>
                <MenuItem value={"NY"}>NY</MenuItem>
                <MenuItem value={"CT"}>CT</MenuItem>
                
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CreateStormEvent;
