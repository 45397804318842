import React, { useState, useRef, useEffect } from 'react';
import {
  Box,IconButton,Button,Checkbox,Container,FormControl,FormControlLabel,Grid,InputLabel,MenuItem,Select,
  TextField,Typography,Dialog,DialogTitle,DialogContent,DialogContentText,DialogActions, 
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import SignatureCanvas from 'react-signature-canvas';
import './signatureCanvas.css';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { addData, getDocs, firestore, collection, } from '../firebase';
import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import TeamSelection from './TeamSelection';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const WeeklyTimesheet = () => {
  const [date] = useState(new Date());
  const [employeeName, setEmployeeName] = useState('');
  const [classification, setClassification] = useState('');
  const [employeeSignature, setEmployeeSignature] = useState(null);
  const employeeSigCanvas = useRef();
  const [teamNames, setTeamNames] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [teamRoster, setTeamRoster] = useState({ roster: [] });
  const [, setLoading] = useState(true);
  const [selectedRoster, setSelectedRoster] = useState([]);
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const [birddogName, setBirddogName] = useState('');
  const [stateWorked, setStateWorked] = useState('');
  const [utility, setUtility] = useState('');
  const [dateWorked, setDateWorked] = useState(new Date());
  const [dialogOpen1, setDialogOpen1] = useState(false); // For the first dialog
  const [dialogOpen2, setDialogOpen2] = useState(false); // For the second dialog
  const [activeEvents, setActiveEvents] = useState([]);
  
  const [selectedEvent, setSelectedEvent] = useState({ eventName: '', stormWorkOrder: '' });
  const [startLocation, setStartLocation] = useState({ city: '', state: '' });
  const [endLocation, setEndLocation] = useState({ city: '', state: '' });
  const [standByLocation, setStandByLocation] = useState({ city: '', state: '' });

  const [contractorName, setContractorName] = useState('MTV SOLUTIONS INC');
  const [contractorAddress, setContractorAddress] = useState({
    street: '782 Plymouth Street',
    city: 'Holbrook',
    state: 'MA',
    zip: '02343',
  });

  const fetchActiveEvents = async () => {
    try {
      const stormEventsSnapshot = await getDocs(collection(firestore, 'stormEvents'));
      const eventsData = []; // This will hold both the name, work order, and teams
  
      stormEventsSnapshot.forEach((doc) => {
        const stormEvent = doc.data();
        if (stormEvent.status === 'active') {
          eventsData.push({
            eventName: stormEvent.eventName,
            stormWorkOrder: stormEvent.stormWorkOrder,
            teams: stormEvent.teams || [] // Include the teams array, defaulting to an empty array if it doesn't exist
          });
        }
      });
  
      setActiveEvents(eventsData);
    } catch (error) {
      console.error("Error fetching active events: ", error);
    }
  };

  useEffect(() => {
    fetchActiveEvents();
  }, [])
  
  const [regularWorkHours, setRegularWorkHours] = useState(0);
  const [mobilizationHours, setMobilizationHours] = useState(0);
  const [demobilizationHours, setDemobilizationHours] = useState(0);
  const [standbyTimeHours, setStandbyTimeHours] = useState(0);

  const [days, setDays] = useState([
    { 
      id: 1, 
      day: 'Monday', 
      selected: false, 
      intervals: [{ startTime: '08:00', endTime: '16:00' }],
      hasAddedInterval: false,  // New field
    },
    { 
      id: 2, 
      day: 'Tuesday', 
      selected: false, 
      intervals: [{ startTime: '08:00', endTime: '16:00' }],
      hasAddedInterval: false,  // New field
    },
    { 
      id: 3, 
      day: 'Wednesday', 
      selected: false, 
      intervals: [{ startTime: '08:00', endTime: '16:00' }],
      hasAddedInterval: false,  // New field
    },
    { 
      id: 4, 
      day: 'Thursday', 
      selected: false, 
      intervals: [{ startTime: '08:00', endTime: '16:00' }],
      hasAddedInterval: false,  // New field
    },
    { 
      id: 5, 
      day: 'Friday', 
      selected: false, 
      intervals: [{ startTime: '08:00', endTime: '16:00' }],
      hasAddedInterval: false,  // New field
    },
    { 
      id: 6, 
      day: 'Saturday', 
      selected: false, 
      intervals: [{ startTime: '08:00', endTime: '16:00' }],
      hasAddedInterval: false,  // New field
    },
    { 
      id: 7, 
      day: 'Sunday', 
      selected: false, 
      intervals: [{ startTime: '08:00', endTime: '16:00' }],
      hasAddedInterval: false,  // New field
    },
  ]);
  const calculateTotalHours = () => {
    const totalHours = days.reduce((total, day) => {
      if (!day.selected) return total;
      day.intervals.forEach(interval => {
        const start = dayjs(interval.startTime, 'HH:mm');
        const end = dayjs(interval.endTime, 'HH:mm');
        const diffInMinutes = end.diff(start, 'minute');
        const diffInHours = diffInMinutes / 60;
        total.straightTime += diffInHours;
      });
      return total;
    }, { straightTime: 0 });
  
    // Debugging
    console.log("Total hours calculated:", totalHours);
  
    return totalHours;
  };
  
  // Helper function to add a new interval to a day
const addNewInterval = (dayId) => {
  const updatedDays = days.map((day) => {
    if (day.id === dayId) {
      if (day.hasAddedInterval) return day;  // If already added, return the day as is
      return {
        ...day,
        hasAddedInterval: true,  // Set hasAddedInterval to true
        intervals: [...day.intervals, { startTime: '16:00', endTime: '19:00' }]  // Add new interval
      };
    }
    return day;
  });
  setDays(updatedDays);
};
  // Helper function to handle time change for a specific interval
  const handleIntervalChange = (event, dayId, intervalIndex, field) => {
    const updatedDays = days.map((day) =>
      day.id === dayId ? {
        ...day,
        intervals: day.intervals.map((interval, index) =>
          index === intervalIndex ? { ...interval, [field]: event.target.value } : interval
        )
      } : day
    );
    setDays(updatedDays);
  };

  // Helper function to remove an interval from a day
const removeInterval = (dayId, intervalIndex) => {
  const updatedDays = days.map((day) => {
    if (day.id === dayId) {
      return {
        ...day,
        hasAddedInterval: false,  // Set hasAddedInterval to false
        intervals: day.intervals.filter((_, index) => index !== intervalIndex)  // Remove the interval
      };
    }
    return day;
  });
  setDays(updatedDays);
};

const calculateTotalHoursForSelectedDays = () => {
  let totalHours = 0;

  days.forEach(day => {
    if (day.selected) {
      day.intervals.forEach(interval => {
        const start = dayjs(interval.startTime, 'HH:mm');
        const end = dayjs(interval.endTime, 'HH:mm');
        const diffInMinutes = end.diff(start, 'minute');
        const diffInHours = diffInMinutes / 60;
        totalHours += diffInHours;
      });
    }
  });

  return totalHours;
};

const handleSubmit = async (event) => {
  event.preventDefault();

  // Calculate the total work hours for all selected days and intervals
  const totalWorkHours = calculateTotalHoursForSelectedDays();

  // Calculate the sum of regularWorkHours, mobilizationHours, demobilizationHours, and standbyTimeHours
  const manualSum = parseFloat(regularWorkHours) + parseFloat(mobilizationHours) + parseFloat(demobilizationHours) + parseFloat(standbyTimeHours);

  // Check if manualSum exceeds totalWorkHours and open the dialog
  if (manualSum > totalWorkHours) {
    setDialogOpen1(true);
    return;  // Exit the function to prevent further processing
  }

// Update the selected roster with the calculated totalHours and other types of hours
const updatedRoster = teamRoster.roster.map((member) => {
  return {
    ...member,
    totalHours: totalWorkHours,
    regularWorkHours: parseFloat(regularWorkHours),
    mobilizationHours: parseFloat(mobilizationHours),
    demobilizationHours: parseFloat(demobilizationHours),
    standbyTimeHours: parseFloat(standbyTimeHours),
  };
});

  // Construct the timesheet data to be saved
  const timesheetData = {
    employeeName,
    classification,
    date: date.toISOString(),
    stateWorked,
    dateWorked: dateWorked.toISOString(),
    eventName: selectedEvent.eventName,
    stormWorkOrder: selectedEvent.stormWorkOrder,
    contractorName,
    contractorAddress,
    days,  // Include the 'days' array with day and intervals
    employeeSignature,
    birddogName,
    roster: updatedRoster,   // Include the updated roster with totalHours and individual hours categories
    startLocation,
    endLocation,
    standByLocation,
    utility,
  };

  try {
    // Save the timesheet data to Firestore
    const docId = await addData('weeklyTimesheets', timesheetData);
    setAlertMessage(`Form submitted and saved with doc ID: ${docId}`);
    setSeverity('success');
    setOpen(true);
  } catch (error) {
    setAlertMessage('Error saving form data: ' + error.message);
    setSeverity('error');
    setOpen(true);
  }
};

  const fetchTeamNames = async () => {
    try {
      const stormEventsSnapshot = await getDocs(collection(firestore, 'stormEvents'));
      const teamNames = [];
  
      stormEventsSnapshot.forEach((doc) => {
        const stormEvent = doc.data();
  
        // Check if the stormEvent is 'active'
        if (stormEvent.status === 'active') {
          stormEvent.teams.forEach((teams) => {
            if (!teamNames.includes(teams.teamName)) {
              teamNames.push(teams.teamName);
            }
          });
        }
      });
  
      setTeamNames(teamNames);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching team names: ", error);
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchTeamNames();
  }, []);
  
  const fetchTeamRoster = async (teamName) => {
    const stormEventsSnapshot = await getDocs(collection(firestore, 'stormEvents'));
    
    stormEventsSnapshot.forEach((doc) => {
      const stormEvent = doc.data();
      stormEvent.teams.forEach((teams) => {
        if (teams.teamName === teamName) {
          setTeamRoster(teams);
        }
      });
    });
  };
  
  const handleRegularHoursChange = (event) => {
    // Ensure the value is not negative
    const value = Math.max(0, event.target.value);
    setRegularWorkHours(value);
  };
  
  const handleMobilizationHoursChange = (event) => {
    // Ensure the value is not negative
    const value = Math.max(0, event.target.value);
    setMobilizationHours(value);
  };
  
  const handleDemobilizationHoursChange = (event) => {
    // Ensure the value is not negative
    const value = Math.max(0, event.target.value);
    setDemobilizationHours(value);
  };
  
  const handleStandbyTimeHoursChange = (event) => {
    // Ensure the value is not negative
    const value = Math.max(0, event.target.value);
    setStandbyTimeHours(value);
  };
  const handleDaySelection = (event, dayId) => {
    const updatedDays = days.map((day) =>
      day.id === dayId ? { ...day, selected: event.target.checked } : day
        );
        setDays(updatedDays);
        };

        const handleRosterSelection = (event, member) => {
          if (event.target.checked) {
            setSelectedRoster(prevRoster => [...prevRoster, member]);
          } else {
            setSelectedRoster(prevRoster => prevRoster.filter(m => m.firstName !== member.firstName || m.lastName !== member.lastName));
          }
        };
        const handleClose = (event, reason) => {
          if (reason === 'clickaway') {
            return;
          }
          setOpen(false);
        };
        const handleStateWorkedChange = (event) => {
          setStateWorked(event.target.value);
        };
        const handleUtilityChange = (event) => {
          setUtility(event.target.value);
        };
        const handleDateWorkedChange = (newDate) => {
          setDateWorked(newDate);
        };

        const handleStormSelection = (event) => {
          const teamName = event.target.value;
          setSelectedTeam(teamName); // Update the selected team state
        
          // Find the event that matches the selected stormWorkOrder
          const eventSelected = activeEvents.find(eventItem => eventItem.teams.some(team => team.teamName === teamName));
          if (eventSelected) {
            setSelectedEvent({
              eventName: eventSelected.eventName,
              stormWorkOrder: eventSelected.stormWorkOrder
            });
        
            // Trigger fetchTeamRoster for the selected storm's team
            fetchTeamRoster(teamName);
        
            // Extract and set the foreman's name
            const selectedTeam = eventSelected.teams.find(team => team.teamName === teamName);
            if (selectedTeam && selectedTeam.roster && selectedTeam.roster.length > 0) {
              const generalForeman = selectedTeam.roster[0]; // Assuming the first member is the general foreman
              const foremanName = `${generalForeman.firstName} ${generalForeman.lastName}`;
              setEmployeeName(foremanName); // Set the employeeName state with the foreman's name
            } else {
              setEmployeeName('No Foreman'); // Set to 'No Foreman' if no foreman is found
            }
          } else {
            // Reset if no corresponding event is found
            setSelectedEvent({ eventName: '', stormWorkOrder: '' });
            setEmployeeName(''); // Reset the employeeName as well
          }
        };
        

        const handleContractorNameChange = (event) => {
          setContractorName(event.target.value);
        };
        const handleContractorAddressChange = (event, field) => {
          setContractorAddress({
            ...contractorAddress,
            [field]: event.target.value,
          });
        };
           
        const handleBirddogNameChange = (event) => {
          setBirddogName(event.target.value);
        }

        const handleVehicleSelection = (e, member) => {
          const hasVehicle = e.target.checked;
          // Update the teamRoster state with the new hasPersonalVehicle value for the specific member
          setTeamRoster(prevRoster => ({
            ...prevRoster,
            roster: prevRoster.roster.map(m => {
              if (m.firstName === member.firstName && m.lastName === member.lastName) {
                return { ...m, hasPersonalVehicle: hasVehicle }; // Update the hasPersonalVehicle field for the matched member
              }
              return m; // Return other members unchanged
            })
          }));
        };

        return (

        <Box
           minHeight="100vh"
           display="flex"
           flexDirection="column"
           justifyContent="space-between"
         >
        
        <Box flexGrow={1}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Container maxWidth="md">
        <Box textAlign="center" mt={8} mb={4}>

<IconButton 
  edge="start" 
  color="secondary" 
  aria-label="back" 
  onClick={() => window.history.back()} 
  style={{ position: 'absolute', top: 99, left: 15, padding: 5 }} // Adjust the 'top' value as needed
>
  <ArrowBackIcon />
</IconButton>

<AccessAlarmIcon
  sx={{
    fontSize: '2rem',
    color: 'secondary.main',
  }}
/>
<Typography
  variant="h4"
  component="h1"
  textAlign="center"
  style={{ marginTop: 20, marginBottom: 10, fontFamily: 'Monospace', fontWeight: 'bold', color: '#1976d2' }}
  gutterBottom
>
  Timesheet
</Typography>
<Grid item xs={12}>
<FormControl fullWidth>
  <InputLabel>Select Storm Event/ Crew</InputLabel>
  <Select
    value={selectedTeam}
    onChange={handleStormSelection}
  >
    {activeEvents.flatMap(event => event.teams.map(team => {
      // Assume the first object in the roster array is the general foreman
      const generalForeman = team.roster && team.roster[0];
      const foremanName = generalForeman ? `${generalForeman.firstName} ${generalForeman.lastName}` : 'No Foreman';

      return (
        <MenuItem key={team.teamName} value={team.teamName}>
          {`${team.teamName} (${event.eventName} - ${foremanName})`}
        </MenuItem>
      );
    }))}
  </Select>
</FormControl>
</Grid>
</Box>
<form onSubmit={handleSubmit} noValidate>
<Grid container spacing={2} alignItems="center">

  <Grid item xs={3}>
  <FormControl fullWidth>
    <InputLabel>State Worked</InputLabel>
    <Select
      value={stateWorked}
      onChange={handleStateWorkedChange}
    >
      <MenuItem value={'MA'}>MA</MenuItem>
      <MenuItem value={'CT'}>CT</MenuItem>
      <MenuItem value={'NH'}>NH</MenuItem>
      {/* <MenuItem value={'RI'}>RI</MenuItem>
      <MenuItem value={'NY'}>NY</MenuItem>
      <MenuItem value={'ME'}>ME</MenuItem> */}
    </Select>
  </FormControl>
</Grid>
<Grid item xs={6}>
  <TextField
    label="Date"
    type="date"
    value={dayjs(dateWorked).format('YYYY-MM-DD')}
    onChange={(event) => handleDateWorkedChange(dayjs(event.target.value))}
    fullWidth
    
  />
</Grid>

<Grid item xs={3}>
  <FormControl fullWidth>
    <InputLabel>Utility</InputLabel>
    <Select
      value={utility}
      onChange={handleUtilityChange}
    >
      <MenuItem value={'Eversource MA East'}>Eversource MA East</MenuItem>
      <MenuItem value={'Eversource MA West'}>Eversource MA West</MenuItem>
      <MenuItem value={'Eversource NH'}>Eversource NH</MenuItem>
      <MenuItem value={'Eversource CT'}>Eversource CT</MenuItem>
      
    </Select>
  </FormControl>
</Grid>
<Grid item xs={12}>
  <Box 
    sx={{
      border: '1px solid #ccc', 
      borderRadius: '5px', 
      p: 2, 
      my: 2,
    }}
  >
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label="Contractor's Name"
          value={contractorName}
          onChange={handleContractorNameChange}
          disabled={true}
          fullWidth
          
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Street"
          value={contractorAddress.street}
          onChange={(e) => handleContractorAddressChange(e, 'street')}
          disabled={true}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="City"
          value={contractorAddress.city}
          onChange={(e) => handleContractorAddressChange(e, 'city')}
          disabled={true}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="State"
          value={contractorAddress.state}
          onChange={(e) => handleContractorAddressChange(e, 'state')}
          disabled={true}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Zip"
          value={contractorAddress.zip}
          onChange={(e) => handleContractorAddressChange(e, 'zip')}
          disabled={true}
          fullWidth
        />
      </Grid>
    </Grid>
  </Box>
</Grid>
<Grid item xs={6}>
<TextField
label="General Foreman Name"
value={employeeName}
onChange={(e) => setEmployeeName(e.target.value)}
fullWidth 
/>
        </Grid>
        <Grid item xs={6}>
        <TextField
        label="Bird Dog Name"
        value={birddogName}
        onChange={handleBirddogNameChange}
        fullWidth
        
      />
        </Grid>
        <Grid item xs={12}>
        <FormControl fullWidth>
        <InputLabel>Crew Type</InputLabel>
        <Select
        value={classification}
        onChange={(e) => setClassification(e.target.value)}
        >
        <MenuItem value="Wire Guards">Wire Guards</MenuItem>
        <MenuItem value="Line Crew">Line Crew</MenuItem>
        <MenuItem value="Service Crew">Service Crew</MenuItem>
        <MenuItem value="Assessors">Assessors</MenuItem>
        <MenuItem value="Safety">Safety</MenuItem>
        <MenuItem value="Field Support">Field Support</MenuItem>
        </Select>
        </FormControl>
        </Grid>
        <Grid item xs={12}>
  <TeamSelection
    selectedTeam={selectedTeam}
    setSelectedTeam={setSelectedTeam}
    teamNames={teamNames}
    teamRoster={teamRoster}
    selectedRoster={selectedRoster} // Pass the state as a prop
    setSelectedRoster={setSelectedRoster} // Pass the setter function as a prop
    handleRosterSelection={handleRosterSelection}
    handleVehicleSelection={handleVehicleSelection}
    fetchTeamRoster={fetchTeamRoster}
    days={days}
    
  />
</Grid>


<Box 
  sx={{
    border: '1px solid #6aa2fc', 
    borderRadius: '5px',
    p: 2, 
    my: 2,
    width:"100%",
  }}
>
  <Grid container spacing={2} marginTop={2}>
    {days.map((day) => (
      <Grid container key={day.id} alignItems="center" spacing={2}>
        <Grid item xs={2}>
          <FormControlLabel
            control={
              <Checkbox 
                checked={day.selected}
                onChange={(e) => handleDaySelection(e, day.id)}
                color="secondary" // Set the color of the checkbox to secondary
                style={{ marginLeft: '10px' }} // Add margin to the left of the checkbox
              />
            }
          />
        </Grid>
        <Grid item xs={2}>
          <Typography>{day.day}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={1}>
            {day.intervals.map((interval, intervalIndex) => (
              <Grid container key={intervalIndex} spacing={1} alignItems="center" sx={{ marginTop: 1, marginBottom: 1 }}>
                <Grid item xs={5}>
                  <TextField 
                    label="Start Time"
                    type="time"
                    value={interval.startTime}
                    onChange={(e) => handleIntervalChange(e, day.id, intervalIndex, 'startTime')}  
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label="End Time"
                    type="time"
                    value={interval.endTime}
                    onChange={(e) => handleIntervalChange(e, day.id, intervalIndex, 'endTime')}
                  />
                </Grid>
                {intervalIndex === 0 && (
                  <Grid item xs={1}>
                    <AddCircleIcon
                      onClick={() => addNewInterval(day.id)}
                      sx={{ cursor: 'pointer' }}
                    />
                  </Grid>
                )}
                {intervalIndex === 1 && (
                  <Grid item xs={1}>
                    <RemoveCircleOutlineIcon
                      onClick={() => removeInterval(day.id, intervalIndex)}
                      sx={{ cursor: 'pointer' }}
                    />
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    ))}
  </Grid>
</Box>
<Box 
  sx={{
    border: '1px solid #ccc', 
    borderRadius: '5px', 
    p: 2, 
    my: 2,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }}
>
  <Typography variant="h6">
    Total Hours for All Selected Days: {calculateTotalHours().straightTime} Hours worked
  </Typography>
</Box>

<Box 
  sx={{
    border: '1px solid #ccc', 
    borderRadius: '1px', 
    p: 1, 
    my: 1,
    width:"100%",
  }}
>
  <Grid container spacing={2}>
    <Grid item xs={3}>
      <TextField
        label="Regular Work"
        type="number"
        value={regularWorkHours}
        onChange={handleRegularHoursChange}
        sx={{
          mb: 2,
          mt: 2,
          borderRadius: '5px',
          bgcolor: 'background.default'
        }}
      />
    </Grid>

    <Grid item xs={3}>
      <TextField
        label="Mobilization"
        type="number"
        value={mobilizationHours}
        onChange={handleMobilizationHoursChange}
        sx={{
          mb: 2,
          mt: 2,
          borderRadius: '5px',
          bgcolor: 'background.default'
        }}
      />
    </Grid>

    <Grid item xs={3}>
      <TextField
        label="Demobilization"
        type="number"
        value={demobilizationHours}
        onChange={handleDemobilizationHoursChange}
        sx={{
          mb: 2,
          mt: 2,
          borderRadius: '5px',
          bgcolor: 'background.default'
        }}
      />
    </Grid>

    <Grid item xs={3}>
      <TextField
        label="Standby Time"
        type="number"
        value={standbyTimeHours}
        onChange={handleStandbyTimeHoursChange}
        sx={{
          mb: 2,
          mt: 2,
          borderRadius: '5px',
          bgcolor: 'background.default'
        }}
      />
    </Grid>
  </Grid>
  <Grid container spacing={2}>
  {/* Start Location Section */}
  <Grid item xs={12}>
    <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
      Start Location:
    </Typography>
  </Grid>
  <Grid item xs={6}>
    <TextField
      label="City"
      value={startLocation.city}
      onChange={(e) => setStartLocation({ ...startLocation, city: e.target.value })}
      fullWidth
    />
  </Grid>
  <Grid item xs={6}>
    <TextField
      label="State"
      value={startLocation.state}
      onChange={(e) => setStartLocation({ ...startLocation, state: e.target.value })}
      fullWidth
    />
  </Grid>

  {/* End Location Section */}
  <Grid item xs={12}>
    <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
      End Location:
    </Typography>
  </Grid>
  <Grid item xs={6}>
    <TextField
      label="City"
      value={endLocation.city}
      onChange={(e) => setEndLocation({ ...endLocation, city: e.target.value })}
      fullWidth
    />
  </Grid>
  <Grid item xs={6}>
    <TextField
      label="State"
      value={endLocation.state}
      onChange={(e) => setEndLocation({ ...endLocation, state: e.target.value })}
      fullWidth
    />
  </Grid>

  {/* StandBy Location Section */}
  <Grid item xs={12}>
    <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
      StandBy Location:
    </Typography>
  </Grid>
  <Grid item xs={6}>
    <TextField
      label="City"
      value={standByLocation.city}
      onChange={(e) => setStandByLocation({ ...standByLocation, city: e.target.value })}
      fullWidth
    />
  </Grid>
  <Grid item xs={6}>
    <TextField
      label="State"
      value={standByLocation.state}
      onChange={(e) => setStandByLocation({ ...standByLocation, state: e.target.value })}
      fullWidth
    />
  </Grid>
</Grid>


</Box>
{/* Employee Signature */}
<Grid item xs={12} sx={{ mb: 2 }}>
  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
    <Typography variant="subtitle1" sx={{ mb: 1 }}>Employee Signature:</Typography>
    <Box 
      sx={{ 
        border: '1px solid black', 
        borderRadius: '5px', 
        width: 500, 
        height: 100 
      }}
    >
      <SignatureCanvas
        ref={employeeSigCanvas}
        onEnd={() => setEmployeeSignature(employeeSigCanvas.current.toDataURL())}
        canvasProps={{ width: 500, height: 100 }}
      />
    </Box>
  </Box>
</Grid>

<Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
  <Button
    onClick={() => {
      employeeSigCanvas.current.clear();
      setEmployeeSignature(null);
    }}
    variant="contained"
    color="error"
    sx={{ mr: 2 }}
  >
    Clear Signature
  </Button>
  <Button
    type="submit"
    variant="contained"
    color="secondary"
    size="large"
  >
    Submit
  </Button>
</Grid>
</Grid>
</form>
</Container>
</LocalizationProvider>
<Snackbar open={open} autoHideDuration={6000} onClose={handleClose} sx={{ width: '100%' }}>
  <Alert onClose={handleClose} severity={severity}>
    {alertMessage}
  </Alert>
</Snackbar>
</Box>

<Dialog
  open={dialogOpen1}
  onClose={() => setDialogOpen1(false)}
>
  <DialogTitle>Error</DialogTitle>
  <DialogContent>
    <DialogContentText>
      The sum of regular work hours, mobilization hours, demobilization hours, and standby time hours exceeds the total work hours.
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setDialogOpen1(false)} color="primary">
      OK
    </Button>
  </DialogActions>
</Dialog>
<Dialog
  open={dialogOpen2}
  onClose={() => setDialogOpen2(false)}
>
  <DialogTitle>Error</DialogTitle>
  <DialogContent>
    <DialogContentText>
      Please check that all fields are filled out correctly.
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setDialogOpen2(false)} color="primary">
      OK
    </Button>
  </DialogActions>
</Dialog>
</Box>
);
};
export default WeeklyTimesheet;