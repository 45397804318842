import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Papa from 'papaparse';
import { collection, doc, setDoc, query, getDocs, where } from 'firebase/firestore'; // Make sure to use the correct Firestore functions
import { firestore } from '../firebase'; // Adjust the path as per your project structure
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';

const FileUploadButton = () => {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        
        Papa.parse(file, {
            complete: handleCSVData,
            header: true
        });
    };

    const handleCSVData = async (result) => {
        const data = result.data;
        const membersCollectionRef = collection(firestore, 'members');
    
        for (const item of data) {
            try {
                // Query Firestore for existing documents with the same firstName and lastName
                const duplicateCheckQuery = query(
                    membersCollectionRef, 
                    where("firstName", "==", item.firstName), 
                    where("lastName", "==", item.lastName)
                );
                const querySnapshot = await getDocs(duplicateCheckQuery);
    
                if (querySnapshot.empty) {
                    // Proceed with creating a new document if no duplicates are found
                    const newDocumentRef = doc(membersCollectionRef);
    
                    const memberData = {
                        id: newDocumentRef.id, 
                        address: item.address || '',
                        assignedVehicle: item.assignedVehicle || '',
                        classification: item.classification || '',
                        role: item.classification || '',
                        crewType: item.crewType || '',
                        email: item.email || '',
                        firstName: item.firstName || '',
                        lastName: item.lastName || '',
                        phone: item.phone || '',
                        licenseID: item.licenseID || '',
                        licenseType: item.licenseType || '',
                        personalVehicle: item.personalVehicle === 'true', 
                        userAccessRole: item.userAccessRole || '',
                        gender: item.gender || '',
                        lodgingNeeded: item.lodgingNeeded === 'Yes', 
                        lodgingType: item.lodgingType || ''
                    };
    
                    await setDoc(newDocumentRef, memberData);
                } else {
                    // Handle the duplicate case (e.g., skip or log a message)
                    console.log(`Duplicate found for ${item.firstName} ${item.lastName}`);
                    continue;
                }
            } catch (error) {
                console.error("Error in processing document: ", error);
                setSnackbarMessage("Error occurred during upload!");
                setSnackbarSeverity("error");
                setOpenSnackbar(true);
                return;
            }
        }
        setSnackbarMessage("Upload successful!");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <div>
            <input
                style={{ display: 'none' }}
                id="hiddenFileInput"
                type="file"
                onChange={handleFileUpload}
            />
            <label htmlFor="hiddenFileInput">
                <Tooltip title="Use this to upload new members or subcontractors">
                    <Button color="secondary" variant="contained" component="span" startIcon={<FileUploadIcon />}>
                        Batch Upload
                    </Button>
                </Tooltip>
            </label>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <MuiAlert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </div>
    );
};

export default FileUploadButton;
