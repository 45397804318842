
import { useState, useEffect, useMemo, createContext, useCallback } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { blue, grey, blueGrey, } from '@mui/material/colors';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Timesheet from './pages/Timesheet';
import DailyTailboardSafety from './pages/DailyTailboardSafety';
import DailySignIn from './pages/DailySignIn';
import RosterWrangler from './pages/RosterWrangler';
import AssignEquipment from './pages/AssignEquipment';
import Bullpen from './pages/Bullpen';
import EquipmentManagement from './pages/EquipmentManagement';
import Login from './pages/Login';
import Logout from './pages/Logout';
import SignUp from './pages/SignUp';
import Dashboard from './components/Dashboard';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import Profile from './pages/Profile';
import EditProfile from './pages/EditProfile';
import PaperFile from './pages/paperFile';
import StormEvent from './pages/StormEvents';
import WeeklyTimesheet from './pages/WeeklyTimesheet';
import ExpenseReports from './pages/ExpenseReports';
import ActiveStorm from './components/ActiveStorm';
import AddEquipment from './pages/addEquipment';
import EventDetails from './pages/eventDetails';
import DailySignList from './pages/DailySignList';
import StormEventList from './pages/StormEventList';
import TimesheetManager from './pages/TimesheetManager';
import AdminApproval from './pages/AdminApproval';
import AddEmployee from './pages/AddEmployee';
import DailyReport from './pages/DailyReport';
import Header from './components/Header';
import AdminPanel from './pages/AdminPanel';
import SafetyForm from './pages/SafetyForm';
import { Analytics } from '@vercel/analytics/react';
import StormEventTeams from './components/StormEventTeams';
import { UserProvider } from './UserContext';
import AccessRoles from './AccessRoles';


const ColorModeContext = createContext({ toggleColorMode: () => {} });

function App() {
  
  const [user, setUser] = useState(null);
  const [darkMode, setDarkMode] = useState(false);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? 'dark' : 'light',
          ...(darkMode
            ? {
                primary: { main: grey[900] },
                secondary: { main: blue[700] },
                divider: grey[300],
                background: {
                  default: grey[900],
                  paper: grey[900],
                },
                text: {
                  primary: blueGrey[100],
                  secondary: grey[500],
                },
              }
            : {
                primary: { main: '#fafafa' }, // Changed primary text color to #fafafa as per instructions
                secondary: { main: blue[500] },
                divider: blue[600],
                text: {
                  primary: blue[400],
                  secondary: blue[500],
                },
              }),
        },
        components: {
          MuiButton: {
            styleOverrides: {
              outlined: {
                ...(darkMode && {
                  '&:hover': {
                    // Apply the neon glow effect
                    animation: 'neonButton .1s ease-in-out infinite alternate',
                  },
                }),
              },
            },
          },
        },
      }),
    [darkMode]
  );
  

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const toggleDarkMode = useCallback(() => {
    setDarkMode((prevDarkMode) => !prevDarkMode);
  }, []);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: toggleDarkMode,
    }),
    [toggleDarkMode],
  );
  const analyticsMode = process.env.NODE_ENV === 'development' ? 'development' : 'production';

  return (
    <UserProvider>
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
        {user && <Header darkMode={darkMode} toggleDarkMode={toggleDarkMode} />} 
        
      <Routes>
        {user ? (
          <>
            <Route exact path="/" element={<Home />} />
            <Route path="/daily-report" element={<DailyReport />} />
            <Route path="/timesheet" element={<Timesheet />} />
            <Route path="/dailytailboardsafety" element={<DailyTailboardSafety />} />
            <Route path="/dailysignin" element={<DailySignIn />} />
            <Route path="/rosterwrangler" element={<RosterWrangler />} />
            <Route path="/addequipment" element={<AddEquipment />} />
            <Route path="/assignequipment" element={<AssignEquipment />} />
            <Route path="/bullpen" element={<Bullpen />} />
            <Route path="/equipmentmanagement" element={<EquipmentManagement />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/edit-profile" element={<EditProfile />} />
            <Route path="/paperfile" element={<PaperFile />} />
            <Route path="/weeklytimesheet" element={<WeeklyTimesheet />} />
            <Route path="/storm-event" element={<StormEvent />} />
            <Route path="/active-storm" element={<ActiveStorm />} />
            <Route path="/event-details/:id" element={<EventDetails />} />
            <Route path="/expense-reports" element={<ExpenseReports />} />
            <Route path="/daily-sign-list" element={<DailySignList />} />
            <Route path="/timesheet-manager" element={<TimesheetManager />} />
            <Route path="/storm-event-list" element={<StormEventList />} />
            <Route path="/add-employee" element={<AddEmployee />} />
            <Route 
              path="/admin-panel" 
              element={
                <AccessRoles roles={['Storm Manager', 'Admin']}>
                  <AdminPanel />
                </AccessRoles>
              } 
            />
            <Route path="/safety-form" element={<SafetyForm />} />
            <Route path="/storm-event-teams" element={<StormEventTeams />} />
            <Route path="/admin-approval/:workOrder" element={<AdminApproval />} />
            <Route path="*" element={<Home />} />
          </>
        ) : (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="*" element={<Login />} />
          </>
        )}
      </Routes>
      <Analytics mode={analyticsMode} />
    </Router>

      </ThemeProvider>
    </ColorModeContext.Provider>
    </UserProvider>
  );
}

export default App;
