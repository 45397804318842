import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, TextField, Box } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import { firestore, collection, getDocs } from '../firebase';
import MemberHours from './MemberHours';
import StormWorkOrder from './StormWorkOrder';

// Utility function to group an array of objects by a specific key
const groupBy = (array, key) => {
  return array.reduce((result, currentItem) => {
    (result[currentItem[key]] = result[currentItem[key]] || []).push(currentItem);
    return result;
  }, {});
};

const TimesheetManager = () => {
  const [memberHours, setMemberHours] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const weeklyTimesheetsRef = collection(firestore, 'weeklyTimesheets');
      const weeklyTimesheetsSnapshot = await getDocs(weeklyTimesheetsRef);

      const membersData = [];
      weeklyTimesheetsSnapshot.forEach((doc) => {
        const timesheet = doc.data();
        if (Array.isArray(timesheet.roster)) {
          timesheet.roster.forEach((member) => {
            membersData.push({
              ...member,
              birddogName: timesheet.birddogName,
              classification: timesheet.classification,
              date: timesheet.date,
              days: timesheet.days,
              stormWorkOrder: timesheet.stormWorkOrder,
              stateWorked: timesheet.stateWorked,
              contractorName: timesheet.contractorName,
              employeeName: timesheet.employeeName,
              eventName: timesheet.eventName,
            });
          });
        } else {
          console.warn(`Document with ID ${doc.id} does not have a valid roster property.`);
        }
      });

      const currentDate = new Date();
      const THIRTY_DAYS = 30 * 24 * 60 * 60 * 1000;

      const recentMembersData = membersData
        .filter(member => currentDate - new Date(member.date) <= THIRTY_DAYS)
        .sort((a, b) => new Date(b.date) - new Date(a.date));

      setMemberHours(recentMembersData);
    };

    fetchData();
  }, []);

  const filteredMemberHours = memberHours.filter(memberHour =>
    Object.values(memberHour).some(value =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const groupedMemberHours = groupBy(filteredMemberHours, 'stormWorkOrder');

  return (
    <>
      <Container maxWidth="lg" sx={{ paddingTop: 3 }}>
        <Typography variant="h6" style={{ marginTop: 20, marginBottom: 10, fontFamily: 'Monospace', fontWeight: 'bold', color: '#1976d2' }}>
          Timesheet Manager
        </Typography>
        <TextField
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search timesheet data..."
          sx={{ width: '55%', marginTop: 1, marginBottom: 3, backgroundColor: 'secondary', paddingLeft: 1 }}
        />
        <StormWorkOrder stormWorkOrders={groupedMemberHours} />
        <Grid container spacing={2} padding={2}>
          {filteredMemberHours.map((memberHour, index) => (
            <Grid item xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} key={index}> {/* Updated key prop */}
              <MemberHours memberHour={memberHour} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default TimesheetManager;
