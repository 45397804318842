// UserContext.js
import React, { createContext, useState, useEffect } from 'react';
import { auth } from './firebase';

// Create a context with a default value of null
export const UserContext = createContext(null);

// Define a provider component
export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user); // Set the current user based on auth state
    });

    // Cleanup the subscription on component unmount
    return () => unsubscribe();
  }, []);

  // Provide the currentUser state and setter function to the context
  return (
    <UserContext.Provider value={{ currentUser }}>
      {children}
    </UserContext.Provider>
  );
};
