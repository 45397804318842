import React, { useState } from 'react';
import { Box, Button, TextField, Grid, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { storage } from '../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { addData } from '../firebase';

const AddEquipment = () => {
  const [formData, setFormData] = useState({
    Make: '',
    Model: '',
    Year: '',
    Owner: '',
    Type: '',
    IDNumber: '',
    Plate: '',
    VIN: '',
    PurchaseDate: '',
    RegRenewal: '',
    Assigned: '',
    Location: '',
    imageUrl: '',
  });
  const [file, setFile] = useState(null); // State to hold the uploaded file

  // Handle change for input fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle file selection
  const handleFileChange = (e) => {
    setFile(e.target.files[0]); // Set the selected file
  };

  // Upload image and update equipment data
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      console.log('No file selected');
      return;
    }
    const storageRef = ref(storage, `equipment-images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Handle progress
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        // Handle unsuccessful uploads
        console.log(error);
      },
      () => {
        // Handle successful uploads on complete
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          console.log('File available at', downloadURL);
          // Set imageUrl in formData and submit
          const updatedFormData = { ...formData, imageUrl: downloadURL };
          const equipmentId = await addData('equipment', updatedFormData);
          if (equipmentId) {
            console.log('New equipment added with ID: ', equipmentId);
            // Reset formData and file
            setFormData({
              Make: '',
              Model: '',
              Year: '',
              Owner: '',
              Type: '',
              IDNumber: '',
              Plate: '',
              VIN: '',
              PurchaseDate: '',
              RegRenewal: '',
              Assigned: '',
              Location: '',
              imageUrl: '',
            });
            setFile(null);
          }
        });
      }
    );
  };

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h4" component="h2" gutterBottom>
          Add New Equipment
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box border={1} borderColor="grey.500" borderRadius={2} p={2} boxShadow={1} maxWidth="350px">
            <Box marginBottom={2}>
              <TextField label="Make" name="Make" value={formData.Make} onChange={handleChange} />
            </Box>
            <Box marginBottom={2}>
              <TextField label="Model" name="Model" value={formData.Model} onChange={handleChange} />
            </Box>
            <Box marginBottom={2}>
              <TextField label="Year" name="Year" value={formData.Year} onChange={handleChange} />
            </Box>
            <Box marginBottom={2}>
              <TextField label="Owner" name="Owner" value={formData.Owner} onChange={handleChange} />
            </Box>
            <Box marginBottom={2}>
              <FormControl fullWidth>
                <InputLabel>Type</InputLabel>
                <Select label="Type" name="Type" value={formData.Type} onChange={handleChange}
                >
                <MenuItem value="Eversource Provided Bucket">Eversource Provided Bucket</MenuItem>
                  <MenuItem value="Bucket Truck">Bucket Truck</MenuItem>
                  <MenuItem value="Digger/Derrick">Digger/Derrick</MenuItem>
                  <MenuItem value="Pick Up">Pick Up</MenuItem>
                  <MenuItem value="Transmission Bucket">Transmission Bucket</MenuItem>
                  <MenuItem value="Car/Van/SUV">Car/Van/SUV</MenuItem>
                  <MenuItem value="Dump Truck">Dump Truck</MenuItem>
                  <MenuItem value="Mechanic Truck">Mechanic Truck</MenuItem>
                  <MenuItem value="Off Road Digger">Off Road Digger</MenuItem>
                  <MenuItem value="Off Road Other">Off Road Other</MenuItem>
                  <MenuItem value="Other Equipment">Other Equipment</MenuItem>
                  <MenuItem value="Trailer-Pole">Trailer-Pole</MenuItem>
                  <MenuItem value="Backhoe">Backhoe</MenuItem>
                  <MenuItem value="Off Road Bucket">Off Road Bucket</MenuItem>
                  <MenuItem value="Dump Truck w/ Backhoe">Dump Truck w/ Backhoe</MenuItem>
                  <MenuItem value="MS Van">MS Van</MenuItem>
                  <MenuItem value="I&R Truck">I&R Truck</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box marginBottom={2}>
              <TextField label="ID Number" name="IDNumber" value={formData.IDNumber} onChange={handleChange} />
            </Box>
            <Box marginBottom={2}>
              <TextField label="Plate Number" name="Plate" value={formData.Plate} onChange={handleChange} />
            </Box>
            <Box marginBottom={2}>
              <TextField label="VIN Number" name="VIN" value={formData.VIN} onChange={handleChange} />
            </Box>
            <Box marginBottom={2}>
              <TextField label="Purchase Date" name="PurchaseDate" value={formData.PurchaseDate} onChange={handleChange} />
            </Box>
            <Box marginBottom={2}>
              <TextField label="Reg Renewal" name="RegRenewal" value={formData.RegRenewal} onChange={handleChange} />
            </Box>
            <Box marginBottom={2}>
              <TextField label="Assigned" name="Assigned" value={formData.Assigned} onChange={handleChange} />
            </Box>
            <Box marginBottom={2}>
              <TextField label="Location" name="Location" value={formData.Location} onChange={handleChange} />
            </Box>
            {/* <Box marginBottom={2}>
              <TextField label="Image URL" name="imageUrl" value={formData.imageUrl} onChange={handleChange} />
            </Box> */}
            <Box marginBottom={2}>
              <TextField type="file" onChange={handleFileChange} />
            </Box>
            <Box marginBottom={2}>
              <Button type="submit" variant="contained">
                Add Equipment
              </Button>
            </Box>
          </Box>
        </form>
      </Grid>
    </Grid>
  );
};

export default AddEquipment;

