import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

// Register the scales
CategoryScale && LinearScale && ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const StormChart = () => {
  // Data from your analysis
  const years = ['2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022'];
  const totalStorms = [14, 9, 12, 16, 18, 16, 18, 30, 20, 16];
  const tropicalStorms = [12, 3, 8, 9, 8, 8, 12, 16, 13, 7];
  const hurricanes = [2, 6, 4, 7, 10, 8, 6, 14, 7, 9];

  // Chart data and options
  const data = {
    labels: years,
    datasets: [
      {
        label: 'Total Storms',
        data: totalStorms,
        backgroundColor: '#1b76c4',  // Retained color
      },
      {
        label: 'Tropical Storms',
        data: tropicalStorms,
        backgroundColor: '#cf5163',  // Retained color
      },
      {
        label: 'Hurricanes',
        data: hurricanes,
        backgroundColor: '#7d42cf',  // Retained color
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',  // Added legend at the top
      },
      title: {
        display: true,
        text: 'Storm Data - Atlantic East Coast US',  // Added title
      },
      tooltip: {
        enabled: true,  // Enabled tooltips
      },
    },
    scales: {
      x: { beginAtZero: true },
      y: { beginAtZero: true },
    },
  };

  return <Bar data={data} options={options} />;
};

export default StormChart;
