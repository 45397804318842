import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  TextField,
  IconButton,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

import {
  auth,
  firestore as db,
  storage,
  ref,
  uploadBytes,
  getDownloadURL,
  getDocs,
  collection,
  query,
  where,
  doc,
  updateDoc
} from '../firebase';



const EditProfile = () => {
  const [user, setUser] = useState(null);
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [role, setrole] = useState('');
  const [profilePic, setProfilePic] = useState(null);
  const [crewType, setCrewType] = useState('');
  const [uploading, setUploading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async authUser => {
      if (authUser) {
        const userCollection = collection(db, 'users');
        const q = query(userCollection, where('uid', '==', authUser.uid));
        const userDocs = await getDocs(q);
        if (!userDocs.empty) {
          const userData = userDocs.docs[0].data();
          setUser(userData);
          setDisplayName(userData.displayName || '');
          setEmail(userData.email || '');
          setPhoneNumber(userData.phoneNumber || '');
          setrole(userData.role || '');
          setCrewType(userData.crewType || '');
        }
      } else {
        setUser(null);
      }
    });

    return unsubscribe;
  }, []);

  const handleProfilePicChange = (e) => {
    if (e.target.files[0]) {
      setProfilePic(e.target.files[0]);
    }
  };

  const uploadProfilePic = async () => {
    if (profilePic) {
      setUploading(true);
      const storageRef = ref(storage, `profilepic/${user.uid}`);
      await uploadBytes(storageRef, profilePic);
      const profilePicUrl = await getDownloadURL(storageRef);
      setUploading(false);
      return profilePicUrl;
    }
    return null;
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const profilePicUrl = await uploadProfilePic();
  
    const updates = {
      displayName,
      phoneNumber,
      role,
    };
  
    if (profilePicUrl) {
      updates.profilePicUrl = profilePicUrl;
    }
  
    if (email !== user.email) {
      await user.updateEmail(email);
      updates.email = email;
    }
  
    // Query the 'users' collection to find the document with the matching uid
    const userCollection = collection(db, 'users');
    const q = query(userCollection, where('uid', '==', user.uid));
    const userDocs = await getDocs(q);
    
    if (!userDocs.empty) {
      // Get a reference to the document with the matching uid
      const userDocRef = doc(db, 'users', userDocs.docs[0].id);
      // Update the document
      await updateDoc(userDocRef, updates);
    }
    
    navigate(-1); // Go back to the previous screen
  };
  

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <>
      
      <IconButton onClick={() => navigate(-1)} style={{ marginLeft: '16px', marginTop: '16px', marginRight: '16px', color: 'primary' }}>
        <ArrowBackIcon />
      </IconButton>
      <Card style={{ marginTop: '16px', marginLeft: '100px' }}>
        <CardContent sx={{ padding: '5px !important' }}>
          <form onSubmit={onSubmit}>
              
              <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                <Grid item xs={2}>
                  <Typography variant="subtitle1">Email:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                <Grid item xs={2}>
                  <Typography variant="subtitle1">Phone Number:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                <Grid item xs={2}>
                  <Typography variant="subtitle1">Work Classification:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    value={role}
                    onChange={(e) => setrole(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: 3 }}>
              <Grid item xs={2}>
                <Typography variant="subtitle1">Crew Type:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                  fullWidth
                  value={crewType}
                  onChange={(e) => setCrewType(e.target.value)}
                  />
                </Grid>
                </Grid>
              <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                <Grid item xs={2}>
                  <Typography variant="subtitle1">Profile Picture:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <input
                    accept="image/*"
                    type="file"
                    onChange={handleProfilePicChange}
                  />
                </Grid>
              </Grid>
              <Box textAlign="center">
                <Button
                  type="submit"
                  variant="outlined"
                  color="secondary"
                  disabled={uploading}
                  sx={{ marginBottom: 3 }}
                >
                  {uploading ? 'Uploading...' : 'Save Changes'}
                </Button>
              </Box>
            </form>
          </CardContent>
        </Card>
    </>
  );
};

export default EditProfile;