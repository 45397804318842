import React, { useRef, useState, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import {
  IconButton, Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel,
  MenuItem, Radio, RadioGroup, Select, TextField, Typography, Container
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  CheckCircle, Error, NightShelterOutlined,
  HandymanOutlined
} from '@mui/icons-material';
import { getDocs, query, where,addDoc, collection, getFirestore } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { Snackbar } from '@mui/material';


const DailyReport = () => {
  const [activeStormEvents, setActiveStormEvents] = useState([]);
  
  const [lodgingProvider, setLodgingProvider] = useState('');
  const [vehicleIssues, setVehicleIssues] = useState('');
  const [birdDogConfirmation, setBirdDogConfirmation] = useState('');
  const [crewWorking, setCrewWorking] = useState('');
  const [classification, setClassification] = useState('');
  const [employeeSignature, setEmployeeSignature] = useState('');
  const [date, setDate] = useState('');
  const [foremanName, setForemanName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [birdDogs, setBirdDogs] = useState('');
  const [numBuckets, setNumBuckets] = useState('');
  const [numDiggers, setNumDiggers] = useState('');
  const [hotelName, setHotelName] = useState('');
  const [hotelAddress, setHotelAddress] = useState('');
  const [hotelCity, setHotelCity] = useState('');
  const [selectedStormEvent, setSelectedStormEvent] = useState('');
  const [file, setFile] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const employeeSigCanvas = useRef(null);
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Prepare the data to be saved
    const dailyReportData = {
      date,
      foremanName,
      classification,
      email,
      phone,
      crewWorking,
      birdDogs,
      numBuckets,
      numDiggers,
      hotelName,
      hotelAddress,
      hotelCity,
      selectedStormEvent,
      lodgingProvidedBy: lodgingProvider,
      vehicleIssues,
      signature: employeeSignature
    };
    const db = getFirestore();
    try {
      const docRef = await addDoc(collection(db, "dailyReports"), dailyReportData);
      console.log("Document written with ID: ", docRef.id);
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  // Function to close Snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };
  useEffect(() => {
    let isMounted = true; // To keep track of the component's mounted status
    const fetchData = async () => {
      const db = getFirestore();
      const q = query(
        collection(db, "stormEvents"),
        where("status", "==", "active")
      );
      const querySnapshot = await getDocs(q);
      const events = [];
      querySnapshot.forEach((doc) => {
        const eventData = doc.data();
        if (eventData.teams) {
          eventData.teams.forEach((team) => {
            events.push({
              eventName: eventData.eventName,
              teamName: team.teamName,
              AWClocation: team.AWClocation,
              location: eventData.location,
              stormWorkOrder: eventData.stormWorkOrder
            });
          });
        }
      });
  
      if (isMounted) {
        setActiveStormEvents(events);
      }
    };
  
    fetchData();
  
    return () => {
      isMounted = false; // Cleanup: set isMounted to false when the component unmounts
    };
  }, []);
  const clearEmployeeSignature = () => {
    if (employeeSigCanvas.current) {
      employeeSigCanvas.current.clear();
    }
  };

  const handleLodgingProviderChange = (event) => {
    setLodgingProvider(event.target.value);
  };
  
  const handleVehicleIssuesChange = (event) => {
    setVehicleIssues(event.target.value);
  };

  const handleBirdDogConfirmationChange = (event) => {
    setBirdDogConfirmation(event.target.value);
  };
  
  const handleCrewWorkingChange = (event) => {
    setCrewWorking(event.target.value);
  };

  const handleClassificationChange = (event) => {
    setClassification(event.target.value);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const uploadFile = async () => {
    if (file) {
      const storage = getStorage();
      const storageRef = ref(storage, `dailyReports/${file.name}`);
      try {
        await uploadBytes(storageRef, file);
        console.log("File uploaded successfully");
      } catch (error) {
        console.error("Error uploading file: ", error);
      }
    }
  };
  
  return (
    <>
      
      <IconButton 
  edge="start" 
  color="secondary" 
  aria-label="back" 
  onClick={() => window.history.back()} 
  style={{ position: 'absolute', top: 99, left: 15, padding: 5 }} // Adjust the 'top' value as needed
>
  <ArrowBackIcon />
</IconButton>
<Container maxWidth="lg" style={{ height: '90vh' }}>

        <Box padding={3} marginTop={3}>
        <Typography variant="h4" style={{ marginTop: 20, marginBottom: 10, fontFamily: 'Monospace', fontWeight: 'bold', color: '#1976d2', textAlign: 'center' }}>
  Daily Report
</Typography>

<Box 
  padding={2} 
  marginY={2} 
  border={2} 
  borderColor="secondary" 
  borderRadius={2}
  margin={2}
>
  <Typography variant="h6" color="secondary" style={{ marginBottom: '15px' }}>
    Upload Paper Daily Report
  </Typography>
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <input type="file" onChange={handleFileChange} />
      <Button variant="contained" color="primary" onClick={uploadFile} style={{ marginLeft: '10px' }}>
        Upload File
      </Button>
    </Grid>
  </Grid>
</Box>

<form onSubmit={handleSubmit}>
  <Grid container spacing={2}>
  <Grid item xs={12} sm={6} md={4}>
  <FormControl style={{ width: '100%' }}>
  <InputLabel>Active Storm Event</InputLabel>
  <Select
  value={selectedStormEvent}
  onChange={(e) => setSelectedStormEvent(e.target.value)}
>
  {activeStormEvents.map((event, index) => (
    <MenuItem 
      key={index} 
      value={`${event.teamName} ${event.eventName} ${event.AWClocation} ${event.location} ${event.stormWorkOrder}`}
    >
      <span>
        <strong>Team:</strong> {event.teamName}, 
        <strong> Event:</strong> {event.eventName}, 
        <strong> AWC Location:</strong> {event.AWClocation}, 
        <strong> State:</strong> {event.location}, 
        <strong> Work Order:</strong> {event.stormWorkOrder}
      </span>
    </MenuItem>
  ))}
</Select>

</FormControl>
</Grid>
   <Grid item xs={12} sm={6} md={4}>
    <TextField 
  type="date"
  color="secondary" 
   
  value={date} 
  onChange={(e) => setDate(e.target.value)} 
  style={{ width: '100%' }} 
/>
  </Grid>
   <Grid item xs={12} sm={6} md={4}>
   <TextField 
  label="Foreman Name" 
   
  value={foremanName} 
  onChange={(e) => setForemanName(e.target.value)} 
  style={{ width: '100%' }} 
/>
  </Grid>
   <Grid item xs={12} sm={6} md={4}>
    <FormControl style={{ width: '100%' }}>
    <InputLabel>Classification</InputLabel>
     <Select
      value={classification}
      onChange={handleClassificationChange}
      
       >
      <MenuItem value="General Foreman">General Foreman</MenuItem>
      <MenuItem value="Foreman">Foreman</MenuItem>
      <MenuItem value="Safety">Safety</MenuItem>
      <MenuItem value="Journeyman">Journeyman</MenuItem>
      <MenuItem value="Lineman">Lineman</MenuItem>
      <MenuItem value="Equipment Operator">Equipment Operator</MenuItem>
      <MenuItem value="Service Crew">Service Crew</MenuItem>
      <MenuItem value="Wire Guard">Wire Guard</MenuItem>
      </Select>
      </FormControl>
      </Grid>
 <Grid item xs={12} sm={6} md={4}>
 <TextField 
  label="Email" 
  type="email" 
   
  value={email} 
  onChange={(e) => setEmail(e.target.value)} 
  style={{ width: '100%' }} 
/>
  </Grid>
<Grid item xs={12} sm={6} md={4}>
<TextField 
  label="Phone Number" 
   
  value={phone} 
  onChange={(e) => setPhone(e.target.value)} 
  style={{ width: '100%' }} 
/>
</Grid>
<Grid item xs={12} sm={6} md={4}>
  <Typography variant="h6">
   <HandymanOutlined style={{ verticalAlign: "middle", marginRight: "2px" }} />
     Are crew working?
    </Typography>
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
     <RadioGroup
        row
        value={crewWorking}
        onChange={handleCrewWorkingChange}
      >
        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="no" control={<Radio />} label="No" />
    </RadioGroup>
</Grid>
  <Grid item xs={12} sm={6} md={4}>
  <TextField 
  label="Who are your Bird Dogs?" 
   
  value={birdDogs} 
  onChange={(e) => setBirdDogs(e.target.value)} 
  style={{ width: '100%' }} 
/>
</Grid>
<Grid item xs={12} sm={6} md={4}>
  <TextField 
    label="Number of bucket trucks?" 
     
    type="number"
    inputProps={{ min: "0" }}
    value={numBuckets} 
    onChange={(e) => setNumBuckets(e.target.value)} 
    style={{ width: '100%' }} 
  />
</Grid>
<Grid item xs={12} sm={6} md={4}>
  <TextField 
    label="Number of diggers?" 
     
    type="number"
    inputProps={{ min: "0" }}
    value={numDiggers} 
    onChange={(e) => setNumDiggers(e.target.value)} 
    style={{ width: '100%' }} 
  />
</Grid>
  <Box padding={1} marginTop={2} style={{ width: '100%', border: '3px solid #348feb', borderRadius: '5px' }}>
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">
            <NightShelterOutlined style={{ verticalAlign: "middle", marginRight: "8px" }} />
              Lodging Information:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField 
            label="Hotel Name" 
             
            value={hotelName} 
            onChange={(e) => setHotelName(e.target.value)} 
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField 
            label="Address" 
            value={hotelAddress} 
            onChange={(e) => setHotelAddress(e.target.value)} 
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField 
            label="City" 
            value={hotelCity} 
            onChange={(e) => setHotelCity(e.target.value)} 
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel>Lodging is provided by</InputLabel>
            <Select
              value={lodgingProvider}
              onChange={handleLodgingProviderChange}
            >
              <MenuItem value="MTV">MTV</MenuItem>
              <MenuItem value="Utility">Utility</MenuItem>
              <MenuItem value="Sub-Contractor">Sub-Contractor</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Container>
  </Box>
  
<Container maxWidth="lg" color="secondary" style={{ border: '3px solid #348feb', borderRadius: '5px', padding: '20px', marginBottom: '20px', marginTop: '20px' }}>
  <Grid container spacing={1}>
    <Grid item xs={12} sm={6} md={4}>
      <Typography variant="subtitle2">
        Vehicle Issues?
      </Typography>
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <RadioGroup
        row
        value={vehicleIssues}
        onChange={handleVehicleIssuesChange}
      >
        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="no" control={<Radio />} label="No" />
      </RadioGroup>
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      {vehicleIssues === 'yes' && (
        <TextField label="If Yes, Describe" fullWidth />
      )}
    </Grid>
    <Grid item xs={12} sm={6} md={4} style={{ marginTop: '20px', marginBottom: '20px' }}>
      <Typography variant="subtitle2">
        
        I spoke to my Bird Dog and confirm there are NO issues
      </Typography>
    </Grid>
    <Grid item xs={12} sm={6} md={4} style={{ marginTop: '20px', marginBottom: '20px' }}>
      <RadioGroup
        row
        value={birdDogConfirmation}
        onChange={handleBirdDogConfirmationChange}
      >
        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="no" control={<Radio />} label="No" />
      </RadioGroup>
    </Grid>
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <FormControlLabel
          control={
            <Checkbox
              icon={<Error />}
              checkedIcon={<CheckCircle />}
            />
          }
          label="Material Issues?"
        />
        <TextField label="Describe" fullWidth />
      </Grid>
      <Grid item xs={4}>
        <FormControlLabel
          control={
            <Checkbox
              icon={<Error />}
              checkedIcon={<CheckCircle />}
            />
          }
          label="Safety Issues?"
        />
        <TextField label="Describe" fullWidth />
      </Grid>
      <Grid item xs={4}>
        <FormControlLabel
          control={
            <Checkbox
              icon={<Error />}
              checkedIcon={<CheckCircle />}
            />
          }
          label="Accidents?"
        />
        <TextField label="Describe" fullWidth />
      </Grid>
    </Grid>
  </Grid>
</Container>



{/* Signature */}
<Grid container spacing={2}>
  {/* Signature Canvas */}
  <Grid item xs={12} style={{ alignSelf: 'center' }}>
    <Typography variant="subtitle1">
      Signature:
    </Typography>
    <SignatureCanvas
      ref={employeeSigCanvas}
      onEnd={() => setEmployeeSignature(employeeSigCanvas.current.toDataURL())}
      canvasProps={{
        width: window.innerWidth > 600 ? 500 : window.innerWidth - 100,
        height: 150,
        className: 'sigCanvas',
      }}
    />
  </Grid>

  {/* Buttons */}
<Grid item xs={12} style={{ alignSelf: 'center' }}>
  <Box display="flex" justifyContent="flex-start" alignItems="center" style={{ width: '100%' }}>
    <Button
      onClick={clearEmployeeSignature}
      variant="contained"
      color="error"
      style={{ marginRight: '20px' }}  // Add some space between the buttons
    >
      Clear Signature
    </Button>
    <Button type="submit" variant="outlined" color="secondary">
      Submit
    </Button>
  </Box>
</Grid>
</Grid>
</Grid>
</form>
<Snackbar
      open={snackbarOpen} // Controlled by the state variable
      autoHideDuration={6000} // Snackbar will be visible for 6 seconds
      onClose={handleSnackbarClose} // Function to handle the closing
      message="Form submitted successfully!" // Message to display
    />
</Box>
</Container>
</>
);
};

export default DailyReport;
