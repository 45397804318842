/*
 .----------------.  .----------------.  .----------------. 
| .--------------. || .--------------. || .--------------. |
| | ____    ____ | || |  _________   | || | ____   ____  | |
| ||_   \  /   _|| || | |  _   _  |  | || ||_  _| |_  _| | |
| |  |   \/   |  | || | |_/ | | \_|  | || |  \ \   / /   | |
| |  | |\  /| |  | || |     | |      | || |   \ \ / /    | |
| | _| |_\/_| |_ | || |    _| |_     | || |    \ ' /     | |
| ||_____||_____|| || |   |_____|    | || |     \_/      | |
| |              | || |              | || |              | |
| '--------------' || '--------------' || '--------------' |
 '----------------'  '----------------'  '----------------' 

                        
   _____       __      __  _                 
  / ___/____  / /_  __/ /_(_)___  ____  _____
  \__ \/ __ \/ / / / / __/ / __ \/ __ \/ ___/
 ___/ / /_/ / / /_/ / /_/ / /_/ / / / (__  ) 
/____/\____/_/\__,_/\__/_/\____/_/ /_/____/  

Created by: Nicholas White
*/
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import { Button, Container } from '@mui/material';

const Logout = () => {
const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <Container>
      <Button
        onClick={handleLogout}
        variant="contained"
        color="primary"
      >
        Logout
      </Button>
    </Container>
  );
};

export default Logout;
