import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  Box,
  TextField,
} from '@mui/material';
import { firestore, query, where, doc, updateDoc, collection, getDocs } from '../firebase';
import { useParams } from 'react-router-dom';
import GetAppIcon from '@mui/icons-material/GetApp';
import axios from 'axios';
import dayjs from 'dayjs';
import Snackbar from '@mui/material/Snackbar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {Tag} from 'antd';

const AdminApproval = () => {
  const [timesheets, setTimesheets] = useState([]);
  const [totalBillableHours, setTotalBillableHours] = useState(0); // State for total billable hours
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingValue, setEditingValue] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { workOrder } = useParams();
  const [buttonsPressed, setButtonsPressed] = useState({
    eversourceDailyTimesheet: false,
    mobilization: false,
    demobilization: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      const weeklyTimesheetsRef = collection(firestore, 'weeklyTimesheets');
      const weeklyTimesheetsSnapshot = await getDocs(weeklyTimesheetsRef);
      const timesheetsData = [];
      let totalHours = 0; // Initialize total billable hours
      weeklyTimesheetsSnapshot.forEach((docSnapshot) => {
        const timesheet = docSnapshot.data();
        timesheet.id = docSnapshot.id;
        if (timesheet.stormWorkOrder === workOrder) {
          timesheetsData.push(timesheet);
          timesheet.roster.forEach((member) => { // Iterate through each member
            const totalHoursForEvent = parseFloat(member.totalHours) || 0;
          totalHours += totalHoursForEvent; // Add member's total hours to the total billable hours
          });
        }
      });

      setTotalBillableHours(totalHours); // Set the total billable hours state
      setTimesheets(timesheetsData);
    };

    fetchData();
  }, [workOrder]);

  const handleEditRow = (stormWorkOrder, memberIndex) => {
    // Find the timesheet by stormWorkOrder from the state array
    const timesheet = timesheets.find(ts => ts.stormWorkOrder === stormWorkOrder);
  
    if (!timesheet) {
      console.log("Timesheet not found for Storm Work Order:", stormWorkOrder);
      return;
    }
  
    if (memberIndex >= 0 && memberIndex < timesheet.roster.length) {
      const member = timesheet.roster[memberIndex];
      // Set the state for editing
      setEditingIndex({ stormWorkOrder, memberIndex });
      setEditingValue({
        totalHours: parseFloat(member.totalHours) || 0,
        mobilizationHours: parseFloat(member.mobilizationHours) || 0,
        demobilizationHours: parseFloat(member.demobilizationHours) || 0,
        standbyTimeHours: parseFloat(member.standbyTimeHours) || 0,
        regularWorkHours: parseFloat(member.regularWorkHours) || 0,
      });
    } else {
      console.log(`Member index out of bounds: ${memberIndex}`);
    }
  };
  


  const handleSaveChanges = async () => {
    if (editingIndex === null || !editingValue) return;

    const { stormWorkOrder, memberIndex } = editingIndex;
    const querySnapshot = await getDocs(query(collection(firestore, 'weeklyTimesheets'), where('stormWorkOrder', '==', stormWorkOrder)));

    if (!querySnapshot.empty) {
      const documentSnapshot = querySnapshot.docs[0];
      const documentId = documentSnapshot.id;
      const timesheetToUpdate = documentSnapshot.data();

      // Convert edited values to numbers before updating Firestore
      const updatedMember = {
        ...timesheetToUpdate.roster[memberIndex],
        totalHours: parseFloat(editingValue.totalHours) || 0,
        mobilizationHours: parseFloat(editingValue.mobilizationHours) || 0,
        demobilizationHours: parseFloat(editingValue.demobilizationHours) || 0,
        standbyTimeHours: parseFloat(editingValue.standbyTimeHours) || 0,
        regularWorkHours: parseFloat(editingValue.regularWorkHours) || 0,
      };

      const updatedRoster = [
        ...timesheetToUpdate.roster.slice(0, memberIndex),
        updatedMember,
        ...timesheetToUpdate.roster.slice(memberIndex + 1),
      ];

      const timesheetDocRef = doc(firestore, 'weeklyTimesheets', documentId);
      try {
        await updateDoc(timesheetDocRef, { roster: updatedRoster });

        // Recalculate total billable hours and update local state
        let newTotalHours = 0;
        timesheets.forEach((ts) => {
          ts.roster.forEach((member) => {
            newTotalHours += parseFloat(member.totalHours) || 0;
          });
        });

        setTotalBillableHours(newTotalHours);
        setTimesheets(
          timesheets.map(ts =>
            ts.stormWorkOrder === stormWorkOrder
              ? { ...ts, roster: updatedRoster }
              : ts
          )
        );
        setEditingIndex(null);
        setEditingValue('');

      } catch (error) {
        console.error("Error updating document:", error);
      }
    } else {
      console.log('No document found with stormWorkOrder:', stormWorkOrder);
    }
  };


  console.log('Mobilization Params:', {workOrder});
  console.log('Request URL:', 'https://us-central1-mtv-smart-app.cloudfunctions.net/mobilizationFunction', { params: {workOrder} });


  const handleMobilization = async () => {
    // Update the button pressed state immediately
    setButtonsPressed(prevState => ({ ...prevState, mobilization: true }));
  
    try {
      const response = await axios.get('https://us-central1-mtv-smart-app.cloudfunctions.net/mobilizationFunction', {
        params: { workOrder },
      });
      console.log('Mobilization successful:', response.data);
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error triggering Mobilization:', error);
      // Optionally reset the button state in case of an error
      setButtonsPressed(prevState => ({ ...prevState, mobilization: false }));
    }
  };
  
  const handleDemobilization = async () => {
    // Update the button pressed state immediately
    setButtonsPressed(prevState => ({ ...prevState, demobilization: true }));
  
    try {
      const response = await axios.get('https://us-central1-mtv-smart-app.cloudfunctions.net/demobilizationFunction', {
        params: { workOrder },
      });
      console.log('Demobilization successful:', response.data);
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error triggering Demobilization:', error);
      // Optionally reset the button state in case of an error
      setButtonsPressed(prevState => ({ ...prevState, demobilization: false }));
    }
  };
  
  const handleExportToGoogleSheet = async () => {
    // Update the button pressed state immediately
    setButtonsPressed(prevState => ({ ...prevState, eversourceDailyTimesheet: true }));
  
    try {
      const response = await axios.get('https://us-central1-mtv-smart-app.cloudfunctions.net/exportToGoogleSheet', {
        params: { workOrder },
      });
      console.log('Function triggered successfully:', response.data);
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error triggering function:', error);
      // Optionally reset the button state in case of an error
      setButtonsPressed(prevState => ({ ...prevState, eversourceDailyTimesheet: false }));
    }
  };

  const handleRosters = async () => {
    // Update the button pressed state immediately
    setButtonsPressed(prevState => ({ ...prevState, eversourceDailyTimesheet: true }));
  
    try {
      const response = await axios.get('https://us-central1-mtv-smart-app.cloudfunctions.net/exportToGoogleSheet', {
        params: { workOrder },
      });
      console.log('Function triggered successfully:', response.data);
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error triggering function:', error);
      // Optionally reset the button state in case of an error
      setButtonsPressed(prevState => ({ ...prevState, eversourceDailyTimesheet: false }));
    }
  };
  
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const exportCSV = () => {
    const header = [
      'First Name', 'Last Name', 'Email', 'Phone', 'ID', 'UID', 'Has Personal Vehicle',
      'Role', 'Crew Type', 'State Worked', 'Storm Work Order', 'Total Hours', 'Mobilization Hours',
      'Demobilization Hours', 'StandBy Time Hours', 'Regular Work Hours', 'Date', 'Days',
    ];
    const csvRows = [header.join(',')];

    timesheets.forEach((timesheet) => {
      timesheet.roster.forEach((member) => {
        const selectedDays = timesheet.days.filter(day => day.selected).length;
        const row = [
          member.firstName,
          member.lastName,
          member.phone,
          member.id,
          member.uid,
          member.haspersonalVehicle ? 'Yes' : 'No',
          member.role,
          member.crewType,
          member.stateWorked,
          timesheet.stormWorkOrder,
          member.totalHours,
          member.mobilizationHours,
          member.demobilizationHours,
          member.standbyTimeHours,
          member.regularWorkHours,
          timesheet.date,
          selectedDays,
        ];
        csvRows.push(row.join(','));
      });
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const currentDate = new Date().toISOString().slice(0, 10);
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `Timesheet_${currentDate}.csv`;
    a.click();
    URL.revokeObjectURL(url);
  };

  const getRoleColor = (roleOrClassification) => {
    switch(roleOrClassification) {
      case 'Lineman':
        return <Tag color="lime">Lineman</Tag>;
      case 'Line Foreman':
        return <Tag color="magenta">Line Foreman</Tag>;
      case 'Line Apprentice':
        return <Tag color="red">Line Apprentice</Tag>;
      case 'Line Equipment Operator':
        return <Tag color="volcano">Line Equipment Operator</Tag>;
      case 'Line Groundman':
        return <Tag color="orange">Line Groundman</Tag>;
      case 'Line Cable Splicer':
        return <Tag color="gold">Line Cable Splicer</Tag>;
      case 'Working Foreman':
        return <Tag color="lime">Working Foreman</Tag>;
      case 'Service Crew Electrician':
        return <Tag color="green">Service Crew Electrician</Tag>;
      case 'Field Safety':
        return <Tag color="cyan">Field Safety</Tag>;
      case 'Field Support':
        return <Tag color="blue">Field Support</Tag>;
      case 'Flagger':
        return <Tag color="geekblue">Flagger</Tag>;
      case 'Line General Foreman':
        return <Tag color="magenta">Line General Foreman</Tag>;
      case 'Tree General Foreman':
        return <Tag color="red">Tree General Foreman</Tag>;
      case 'Vehicle Mechanic':
        return <Tag color="volcano">Vehicle Mechanic</Tag>;
      case 'Damage Assessor':
        return <Tag color="orange">Damage Assessor</Tag>;
      case 'Driver':
        return <Tag color="gold">Driver</Tag>;
      case 'Wires Down Guard':
        return <Tag color="lime">Wires Down Guard</Tag>;
      case 'Tree Climber':
        return <Tag color="green">Tree Climber</Tag>;
      case 'Tree Foreman':
        return <Tag color="cyan">Tree Foreman</Tag>;
      case 'Tree Groundman':
        return <Tag color="blue">Tree Groundman</Tag>;
      default:
        return <Tag color="geekblue">{roleOrClassification}</Tag>;
    }
  };
  const getCrewTypeColor = (crewType) => {
    switch(crewType) {
      case 'Contractor Line':
        return <Tag color="#E57373">Contractor Line</Tag>;
      case 'Contractor Service':
        return <Tag color="#81C784">Contractor Service</Tag>;
      case 'Damage Appraiser':
        return <Tag color="#08d456">Damage Appraiser</Tag>;
      case 'Wire Down':
        return <Tag color="#8930fc">Wire Down</Tag>;
      case 'Sub-Contractors':
        return <Tag color="#BA68C8">Sub-Contractors</Tag>;
      case 'Transmission':
        return <Tag color="#BA68C8">Transmission</Tag>;
      case 'Contractor Tree':
        return <Tag color="#BA68C8">Contractor Tree</Tag>;
      case 'Contractor Support':
        return <Tag color="#BA68C8">Contractor Support</Tag>;
      default:
        return <Tag color="geekblue">{crewType}</Tag>;
    }
  };

  return (
  <div>
  
    <Typography variant="h5" color="secondary" paddingTop={5} paddingBottom={3} paddingLeft={1}>Timesheet Review and Final Approval</Typography>
    <Box mb={2} mt={2} p={2} borderRadius={5} border="0px solid #487bfa">
    <Typography variant="body2" color="secondary" paddingTop={2} paddingBottom={3} paddingLeft={1} fontStyle="italic">Only crews that have submitted a timesheet are shown here.</Typography>
        <Button variant="outlined" color="secondary" style={{ marginRight: 5, margin: 5}} onClick={exportCSV} startIcon={<GetAppIcon />}>Export Spreadsheet</Button>
        <Button
          variant="outlined"
          color="secondary"
          style={{ marginLeft: 5, margin: 5 }}
          onClick={handleExportToGoogleSheet}
          disabled={buttonsPressed.eversourceDailyTimesheet}
          startIcon={buttonsPressed.eversourceDailyTimesheet ? <CheckCircleIcon style={{ color: 'green' }} /> : null}
        >
          Eversource Daily Timesheet
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          style={{ marginLeft: 5, margin: 5, marginRight: 5 }}
          onClick={handleMobilization}
          disabled={buttonsPressed.mobilization}
          startIcon={buttonsPressed.mobilization ? <CheckCircleIcon style={{ color: 'green' }} /> : null}
        >
          Mobilization
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          style={{ marginLeft: 5, margin: 5, marginRight: 5 }}
          onClick={handleDemobilization}
          disabled={buttonsPressed.demobilization}
          startIcon={buttonsPressed.demobilization ? <CheckCircleIcon style={{ color: 'green' }} /> : null}
        >
          Demobilization
        </Button>
      </Box>
    {timesheets.map((timesheet, timesheetIndex) => (
    <Box key={timesheetIndex} border={0} borderColor="#487bfa" borderRadius={2} mb={2} padding={1}>
      <Box border={2} borderColor="#487bfa" borderRadius={2} mb={2} style={{ overflowX: "auto" }} maxWidth="100%">
      {/* General Information Table */}
      <Typography variant="body2" color="secondary" fontWeight="bold" paddingTop={1} paddingBottom={1} paddingLeft={1}>General Info:</Typography>
      <TableContainer>
      <Table>
      <TableBody>
      <TableRow>
  <TableCell><Typography variant="body2" style={{ fontWeight: 'bold' }}>Storm Event Work Order</Typography></TableCell>
  <TableCell>{timesheet.stormWorkOrder}</TableCell>
</TableRow>
<TableRow>
  <TableCell><Typography variant="body2" style={{ fontWeight: 'bold' }}>Classification</Typography></TableCell>
  <TableCell>{timesheet.classification}</TableCell>
</TableRow>
<TableRow>
  <TableCell><Typography variant="body2" style={{ fontWeight: 'bold' }}>Date</Typography></TableCell>
  <TableCell>{dayjs(timesheet.date).format('MM/DD/YYYY')}</TableCell>
</TableRow>
<TableRow>
  <TableCell><Typography variant="body2" style={{ fontWeight: 'bold' }}># Days Worked</Typography></TableCell>
  <TableCell>{timesheet.days.filter(day => day.selected === true).length}</TableCell>
</TableRow>
<TableRow>
  <TableCell><Typography variant="body2" style={{ fontWeight: 'bold' }}>BirdDog Name</Typography></TableCell>
  <TableCell>{timesheet.birddogName}</TableCell>
</TableRow>
<TableRow>
  <TableCell><Typography variant="body2" style={{ fontWeight: 'bold' }}>State Worked</Typography></TableCell>
  <TableCell>{timesheet.stateWorked}</TableCell>
</TableRow>
<TableRow>
<TableCell><Typography variant="body2" style={{ fontWeight: 'bold' }}>Total Billable Hours</Typography></TableCell>
   <TableCell>{totalBillableHours}</TableCell>
    </TableRow>
    </TableBody>
    </Table>
    </TableContainer>
</Box>
  <Box border={2} borderColor="#487bfa" borderRadius={1} style={{ overflowX: "auto" }} maxWidth="100%">
  <TableContainer>
  <Table>
    <TableHead>
      <TableRow>
        <TableCell><Typography variant="body2" style={{ fontWeight: 'bold' }}>First Name</Typography></TableCell>
        <TableCell><Typography variant="body2" style={{ fontWeight: 'bold' }}>Last Name</Typography></TableCell>
        <TableCell><Typography variant="body2" style={{ fontWeight: 'bold' }}>Phone</Typography></TableCell>
        <TableCell><Typography variant="body2" style={{ fontWeight: 'bold' }}>Personal Vehicle</Typography></TableCell>
        <TableCell><Typography variant="body2" style={{ fontWeight: 'bold' }}>Member Type</Typography></TableCell>
        <TableCell><Typography variant="body2" style={{ fontWeight: 'bold' }}>Crew Type</Typography></TableCell>
        <TableCell><Typography variant="body2" style={{ fontWeight: 'bold' }}>TotalHours</Typography></TableCell>
        <TableCell><Typography variant="body2" style={{ fontWeight: 'bold' }}>Mobilization</Typography></TableCell>
        <TableCell><Typography variant="body2" style={{ fontWeight: 'bold' }}>Demobilization</Typography></TableCell>
        <TableCell><Typography variant="body2" style={{ fontWeight: 'bold' }}>StandByTime</Typography></TableCell>
        <TableCell><Typography variant="body2" style={{ fontWeight: 'bold' }}>RegularTime</Typography></TableCell>
        <TableCell><Typography variant="body2" style={{ fontWeight: 'bold' }}>Action</Typography></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {timesheet.roster.map((member, memberIndex) => (
        <TableRow key={memberIndex}>
          <TableCell>{member.firstName}</TableCell>
          <TableCell>{member.lastName}</TableCell>
          <TableCell>{member.phone}</TableCell>
          <TableCell>{member.haspersonalVehicle ? 'Yes' : 'No'}</TableCell>
          <TableCell>{getRoleColor(member.role ? member.role : member.classification)}</TableCell>
          <TableCell>{getCrewTypeColor(member.crewType)}</TableCell>
{editingIndex && editingIndex.memberIndex === memberIndex && editingIndex.stormWorkOrder === timesheet.stormWorkOrder ? (
  <>
    <TableCell>
      <TextField
        value={editingValue.totalHours}
        onChange={(e) => setEditingValue({ ...editingValue, totalHours: e.target.value })}
        type="number"
        size="medium"
        fullWidth
        InputProps={{
          inputProps: { 
            style: { 
              appearance: 'textfield' 
            } 
          }
        }}
      />
    </TableCell>
    <TableCell>
      <TextField
        value={editingValue.mobilizationHours}
        onChange={(e) => setEditingValue({ ...editingValue, mobilizationHours: e.target.value })}
        type="number"
        size="medium"
        fullWidth
        InputProps={{
          inputProps: { 
            style: { 
              appearance: 'textfield' 
            } 
          }
        }}
      />
    </TableCell>
    <TableCell>
      <TextField
        value={editingValue.demobilizationHours}
        onChange={(e) => setEditingValue({ ...editingValue, demobilizationHours: e.target.value })}
        type="number"
        size="medium"
        fullWidth
        InputProps={{
          inputProps: { 
            style: { 
              appearance: 'textfield' 
            } 
          }
        }}
      />
    </TableCell>
    <TableCell>
      <TextField
        value={editingValue.standbyTimeHours}
        onChange={(e) => setEditingValue({ ...editingValue, standbyTimeHours: e.target.value })}
        type="number"
        size="medium"
        fullWidth
        // Remove spinner buttons
        InputProps={{
          inputProps: { 
            style: { 
              appearance: 'textfield' 
            } 
          }
        }}
      />
    </TableCell>
    <TableCell>
      <TextField
        value={editingValue.regularWorkHours}
        onChange={(e) => setEditingValue({ ...editingValue, regularWorkHours: e.target.value })}
        type="number"
        size="medium"
        fullWidth
        InputProps={{
          inputProps: { 
            style: { 
              appearance: 'textfield' 
            } 
          }
        }}
      />
    </TableCell>
            </>
          ) : (
            <>
              <TableCell>{member.totalHours}</TableCell>
              <TableCell>{member.mobilizationHours}</TableCell>
              <TableCell>{member.demobilizationHours}</TableCell>
              <TableCell>{member.standbyTimeHours}</TableCell>
              <TableCell>{member.regularWorkHours}</TableCell>
            </>
          )}
          <TableCell>
  {editingIndex && editingIndex.memberIndex === memberIndex && editingIndex.stormWorkOrder === timesheet.stormWorkOrder ? (
    <Button color="secondary" variant="outlined" onClick={handleSaveChanges}>Save</Button>
  ) : (
    <Button
      color="secondary"
      variant="outlined"
      onClick={() => handleEditRow(timesheet.stormWorkOrder, memberIndex)}
    >
      Edit
    </Button>
  )}
        </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>
   </Box>
  </Box>
    ))}

<Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Action submitted and saved to Google Drive"
      />
  </div>
  );
  }
  export default AdminApproval;
  