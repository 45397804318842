import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  Avatar,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip,
  
} from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import LoginIcon from "@mui/icons-material/Login";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import BuildIcon from "@mui/icons-material/Build";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import { auth, storage, getDownloadURL, ref } from "../firebase";
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTheme } from '@mui/material/styles';
import AccessRoles from '../AccessRoles';


const Header = ({ darkMode, toggleDarkMode }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [photoURL, setPhotoURL] = useState('https://firebasestorage.googleapis.com/v0/b/mtv-smart-app.appspot.com/o/Images%2Fnickwhite0x_placeholder_image_of_an_electrical_lineman_with_a_h_2a49a5aa-2d93-4dc1-8994-a05292a6dd0c.png?alt=media&token=b632b6db-6729-4e25-a2d8-96701b11823b');

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setLoggedIn(true);
  
        // Fetch the profile image URL from Firebase Storage
        const imagePath = `profilepic/${user.uid}`;
        const storageRef = ref(storage, imagePath);
        getDownloadURL(storageRef).then(imageURL => {
          setPhotoURL(imageURL);
        }).catch(error => {
          // If there's an error (e.g., file not found), use a placeholder image or handle appropriately
          setPhotoURL('https://firebasestorage.googleapis.com/v0/b/mtv-smart-app.appspot.com/o/Images%2Fnickwhite0x_placeholder_image_of_an_electrical_lineman_with_a_h_2a49a5aa-2d93-4dc1-8994-a05292a6dd0c.png?alt=media&token=b632b6db-6729-4e25-a2d8-96701b11823b');
        });
      } else {
        setLoggedIn(false);
      }
    });
  }, []);
  
  const handleLogout = () => {
    auth.signOut();
  };

  const toggleDrawer = (open) => (event) => {
    setDrawerOpen(open);
  };

  const theme = useTheme();

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {/* Refactored ListItem to use Link directly instead of nesting ButtonBase inside ListItem */}
        <ListItem 
          disablePadding 
          key="storm-event" 
          sx={{ padding: '10px 0', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)' } }}
          component={Link} 
          to="/storm-event"
          
        >
          <ListItemIcon sx={{ padding: '0 10px' }}>
            <FlashOnIcon style={{ color: theme.palette.secondary.main }} />
          </ListItemIcon>
          <ListItemText secondary="Create Storm Events" />
        </ListItem>
        <ListItem 
          disablePadding 
          key="Bullpen" 
          sx={{ padding: '10px 0', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)' } }}
          component={Link} 
          to="/Bullpen"
          
        >
          <ListItemIcon sx={{ padding: '0 10px' }}>
            <GroupWorkIcon style={{ color: theme.palette.secondary.main }} />
          </ListItemIcon>
          <ListItemText secondary="Bullpen" />
        </ListItem>
        <ListItem 
          disablePadding 
          key="EquipmentManagement" 
          sx={{ padding: '10px 0', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)' } }}
          component={Link} 
          to="/EquipmentManagement"
          
        >
          <ListItemIcon sx={{ padding: '0 10px' }}>
            <BuildIcon style={{ color: theme.palette.secondary.main }} />
          </ListItemIcon>
          <ListItemText secondary="Equipment" />
        </ListItem>
        <ListItem 
          disablePadding 
          key="Dashboard" 
          sx={{ padding: '10px 0', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)' } }}
          component={Link} 
          to="/Dashboard"
          
        >
          <ListItemIcon sx={{ padding: '0 10px' }}>
            <DashboardIcon style={{ color: theme.palette.secondary.main }} />
          </ListItemIcon>
          <ListItemText secondary="StormBoard" />
        </ListItem>
        <ListItem 
          disablePadding 
          key="adminPanel" 
          sx={{ padding: '10px 0', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)' } }}
          component={Link} 
          to="/admin-panel"
          
        >
          <ListItemIcon sx={{ padding: '0 10px' }}>
            <AttachFileIcon style={{ color: theme.palette.secondary.main }} />
          </ListItemIcon>
          <ListItemText secondary="Admin Panel" />
        </ListItem>
        
      </List>
    </Box>
  );
  
  return (
    <AppBar position="sticky" sx={{ background: "darkmode", height: "89px" }}>
      <Toolbar>
        <Box display="flex" justifyContent="space-between" width="100%">
          <div>
          <AccessRoles roles={['Storm Manager', 'Admin']}>
      <Tooltip title="Management Menu">
        <IconButton
          edge="start"
          onClick={toggleDrawer(true)}
          sx={{
            color: "secondary.main", 
            fontSize: "small", 
            '&:hover': { 
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                transform: 'scale(1.1)'
            },
            '&:active': { backgroundColor: 'rgba(173, 216, 230, 0.3)' } 
          }}
        >
          <MenuIcon />
        </IconButton>
      </Tooltip>
    </AccessRoles>
            <Drawer anchor='left' open={drawerOpen} onClose={toggleDrawer(false)}>
              {list()}
            </Drawer>
          </div>
          <Box sx={{ position: "absolute", left: "50%", transform: "translateX(-50%)" }}>
            <Link
              to="/"
              style={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={logo}
                alt="Logo"
                className="logo"
                style={{ width: "100px" }}
              />
            </Link>
          </Box>
          <div className="auth">
            {loggedIn ? (
              <Box display="flex" alignItems="center">
                <Tooltip title={darkMode ? "Switch to light mode" : "Switch to dark mode"}>
                  <IconButton
                    onClick={() => toggleDarkMode(!darkMode)}
                    color="secondary"
                    sx={{ color: "secondary.main", fontSize: "small", 
                 '&:hover': { 
                     backgroundColor: 'rgba(255, 255, 255, 0.2)',
                     transform: 'scale(1.1)'
                 },
                 '&:active': { backgroundColor: 'rgba(173, 216, 230, 0.3)' } 
                 }}
                  >
                    {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Logout">
                  <IconButton
                   onClick={handleLogout} 
                   sx={{ color: "secondary.main", fontSize: "small", 
                   '&:hover': { 
                       backgroundColor: 'rgba(255, 255, 255, 0.2)',
                       transform: 'scale(1.1)'
                   },
                   '&:active': { backgroundColor: 'rgba(173, 216, 230, 0.3)' } 
                   }}
                   >
                    <LogoutIcon />
                    <Typography variant="caption"></Typography>
                  </IconButton>
                </Tooltip>
                
                <Tooltip title="Profile">
                  <IconButton component={Link} to="/Profile" sx={{ color: "secondary.main", fontSize: "small", 
                 '&:hover': { 
                     backgroundColor: 'rgba(255, 255, 255, 0.2)',
                     transform: 'scale(1.1)'
                 },
                 '&:active': { backgroundColor: 'rgba(173, 216, 230, 0.3)' } 
                 }}>
                    <Avatar src={photoURL} />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              <Button color="inherit" startIcon={<LoginIcon />} sx={{ color: "secondary.main" }}>
                Login
              </Button>
            )}
          </div>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

