import React, { useState, useEffect } from 'react';
import { DndContext, useDraggable, useDroppable, DragOverlay } from '@dnd-kit/core';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { useTheme } from '@mui/material/styles';


const DraggableMember = ({ member }) => {
  const theme = useTheme();
  const { attributes, listeners, setNodeRef } = useDraggable({ id: member.id });

  // Function to format the equipment object into a string
  const formatEquipment = (equipment) => {
    return Object.keys(equipment).map(key => {
      const item = equipment[key];
      return `${item.IDNumber || ''}, ${item.Make || ''}, ${item.Model || ''}, ${item.Plate || ''}`;
    }).join('; ');
  };

  // Handling the equipment object
  const equipmentString = member.equipment && Object.keys(member.equipment).length > 0
    ? formatEquipment(member.equipment)
    : 'No equipment';

  // Style for the draggable component
  const style = {
    padding: '10px',
    margin: '5px 0',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px',
    boxShadow: theme.shadows[1],
    cursor: 'grab',
    width: '350px',
    boxSizing: 'border-box',
    transition: 'box-shadow 0.3s, background-color 0.3s',
    color: theme.palette.text.primary,
    '&:hover': {
      boxShadow: theme.shadows[4],
      backgroundColor: theme.palette.action.hover,
    },
  };

  return (
    <div ref={setNodeRef} {...listeners} {...attributes} style={style}>
      <span style={{ fontWeight: 'bold' }}>
        {member.firstName} {member.lastName}
      </span> - {member.role}
      <div style={{ marginTop: '5px', fontStyle: 'italic' }}>
        {equipmentString}
      </div>
    </div>
  );
};

const TeamColumn = ({ team, utilityName, eventName, eventDate }) => {
    const theme = useTheme();
    const { setNodeRef, isOver } = useDroppable({ id: team.teamName });
  
    // Style changes when a draggable item is over the droppable area
    const style = {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '10px',
      margin: '10px',
      marginTop: '20px',
      minHeight: '500px', // Adjusted minHeight for better responsiveness
      backgroundColor: isOver ? theme.palette.action.selected : theme.palette.background.paper,
      borderRadius: '8px',
      boxShadow: theme.shadows[2],
      transition: 'background-color 0.3s',
      color: theme.palette.text.primary,
      overflow: 'auto' // To handle long lists of members
    };
  
    return (
      <div ref={setNodeRef} style={{ ...style, border: `1px solid ${theme.palette.secondary.main}` }}>
        <h3 style={{ margin: '0 0 10px 0', textAlign: 'center' }}>{team.teamName}</h3>
        <div style={{ textAlign: 'left', marginBottom: '20px', border: '1px solid lightgrey', borderRadius: '4px', padding: '10px' }}>
          <strong>Utility:</strong> {utilityName}<br />
          <strong>Event:</strong> {eventName}<br />
          <strong>Date:</strong> {new Date(eventDate).toLocaleDateString()}
        </div>
        {team.roster && team.roster.length > 0 ? (
          team.roster.map(member => (
            <DraggableMember key={member.id} member={member} />
          ))
        ) : (
          <p style={{ color: theme.palette.text.disabled }}>No members in this team</p>
        )}
      </div>
    );
  };
  
const StormEventTeams = () => {
  const [stormEvents, setStormEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [draggedItem, setDraggedItem] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(firestore, 'stormEvents'));
        const events = querySnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(event => event.status === 'active');  // Filter for active events
        setStormEvents(events);
      } catch (error) {
        console.error("Error fetching data from Firestore", error);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const handleDragEnd = async (event) => {
    const { active, over } = event;
    if (!over || over.id === active.id) {
      return; // Do nothing if dropped on the same team or outside a droppable area
    }
  
    const draggedMemberId = active.id;
    const newTeamName = over.id;
  
    // Create a deep copy of the current state to avoid direct mutation
    let updatedEvents = JSON.parse(JSON.stringify(stormEvents));
  
    let memberMoved = false;
    let memberData = null;
    let originalEventId = null;
  
    // Remove the member from their current team and save their data
    updatedEvents.forEach(event => {
      event.teams.forEach(team => {
        const memberIndex = team.roster.findIndex(member => member.id === draggedMemberId);
        if (memberIndex > -1) {
          memberMoved = true;
          memberData = team.roster.splice(memberIndex, 1)[0];
          originalEventId = event.id;
        }
      });
    });
  
    // Add the member to the new team
    if (memberMoved && memberData) {
      updatedEvents.forEach(event => {
        const teamIndex = event.teams.findIndex(team => team.teamName === newTeamName);
        if (teamIndex > -1) {
          event.teams[teamIndex].roster.push(memberData);
        }
      });
  
      setStormEvents(updatedEvents);
  
      // Update Firestore for the original and new events
      try {
        const originalEventRef = doc(firestore, 'stormEvents', originalEventId);
        const newEventRef = doc(firestore, 'stormEvents', updatedEvents.find(event => event.teams.some(team => team.teamName === newTeamName)).id);
        await updateDoc(originalEventRef, { teams: updatedEvents.find(event => event.id === originalEventId).teams });
        await updateDoc(newEventRef, { teams: updatedEvents.find(event => event.teams.some(team => team.teamName === newTeamName)).teams });
      } catch (error) {
        console.error("Error updating Firestore", error);
      }
    }
  };
  
  
  if (loading) {
    return <div>Loading...</div>;
  }

  
    return (
        <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: '70px' }}>
        {stormEvents.map(event => {
          console.log('Rendering Event Teams:', event.teams); // Debugging line
          return event.teams.map(team => (
            <TeamColumn 
            key={team.teamName}
            team={team}
            utilityName={event.utilityName}
            eventName={event.eventName}
            eventDate={event.eventDate}
          />
          ));
        })}
      </div>
      <DragOverlay>
        {draggedItem ? <DraggableMember member={draggedItem} /> : null}
      </DragOverlay>
    </DndContext>
  );
  function handleDragStart(event) {
    const { active } = event;
    const item = stormEvents.flatMap(event => event.teams.flatMap(team => team.roster))
                             .find(member => member.id === active.id);
    setDraggedItem(item);
  }

};

export default StormEventTeams;



