import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { addData } from '../firebase';
import { FormControl, InputLabel, Select, MenuItem, Box, Button, TextField, Typography, Container, Switch, FormControlLabel } from '@mui/material';


const AddEmployee = () => {
  const [address, setAddress] = useState('');
  const [assignedVehicle, setAssignedVehicle] = useState('');
  const [classification, setClassification] = useState('');
  const [crewType, setCrewType] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [licenseID, setLicenseID] = useState('');
  const [licenseType, setLicenseType] = useState('');
  const [personalVehicle, setPersonalVehicle] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Add member data to Firestore
      await addData('members', {
        address,
        assignedVehicle,
        classification,
        crewType,
        email,
        firstName,
        lastName,
        phone,
        licenseID,
        licenseType,
        personalVehicle
      });
      // Here you can add navigation or alert for successful addition.
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <Container component="main" maxWidth="lg">
      

      <Box style={{ position: 'absolute', top: '80px', left: '10px' }} m={2}> {/* m={2} adds margin */}
  <Button 
    variant="contained" 
    color="secondary"
    startIcon={<ArrowBackIcon />}
    onClick={() => navigate(-1)}
  >
    Back
  </Button>
</Box>

<Box mt={4}> {/* mt={4} adds top margin */}
  <Typography 
    component="h6"
    color="secondary" 
    variant="h6" 
    style={{ padding: '20px', color: 'secondary' }}
  >
    Add Member
  </Typography>
</Box>

<Typography 
    component="body1" 
    variant="body1" 
    style={{ 
        color: '#757575', // Light grey color
        marginTop: '10px'  // Adds some space between the title and paragraph
    }}
>
    Create a new member in the system that is a subcontractor or employee.
    
    Adding a member here will allow them to be assigned to a crew, vehicle, 
    storm event and timesheet record. 
    <br /><br />
    Please note that the member will not be able to login to the system 
    on their own until they have been added as a user from the login/signup page.
</Typography>

      <form onSubmit={handleSubmit}>
<TextField
  variant="outlined"
  margin="normal"
  required
  fullWidth
  style={{ maxWidth: '30%', padding: '3px' }}
  label="First Name"
  value={firstName}
  onChange={(e) => setFirstName(e.target.value)}
/>

<TextField
  variant="outlined"
  margin="normal"
  required
  fullWidth
  style={{ maxWidth: '30%', padding: '3px' }}
  label="Last Name"
  value={lastName}
  onChange={(e) => setLastName(e.target.value)}
/>
<TextField
  variant="outlined"
  margin="normal"
  required
  fullWidth
  style={{ maxWidth: '70%', padding: '3px' }}
  label="Phone"
  value={phone}
  onChange={(e) => setPhone(e.target.value)}
/>
<TextField
  variant="outlined"
  margin="normal"
  required
  fullWidth
  style={{ maxWidth: '70%', padding: '3px' }}
  label="Email"
  value={email}
  onChange={(e) => setEmail(e.target.value)}
/>

<TextField
  variant="outlined"
  margin="normal"
  fullWidth
  style={{ maxWidth: '70%', padding: '3px',   }}
  label="Address"
  value={address}
  onChange={(e) => setAddress(e.target.value)}
/>

<TextField
  variant="outlined"
  margin="normal"
  fullWidth
  style={{ maxWidth: '70%', padding: '3px' }}
  label="Assigned Vehicle"
  value={assignedVehicle}
  onChange={(e) => setAssignedVehicle(e.target.value)}
/>

<FormControl 
  variant="outlined" 
  style={{ maxWidth: '51%', marginTop: '8px', marginBottom: '16px' }} 
  required 
  fullWidth
>
  <InputLabel id="classification-label">Classification</InputLabel>
  <Select
    labelId="classification-label"
    value={classification}
    onChange={(e) => setClassification(e.target.value)}
    label="Classification"
  >
    <MenuItem value="General Foreman">General Foreman</MenuItem>
    <MenuItem value="Foreman">Foreman</MenuItem>
    <MenuItem value="Journeyman">Journeyman</MenuItem>
    <MenuItem value="Lineman">Lineman</MenuItem>
    <MenuItem value="Equipment Operator">Equipment Operator</MenuItem>
    <MenuItem value="Service">Service</MenuItem>
    <MenuItem value="Wire Guard">Wire Guard</MenuItem>
    <MenuItem value="Support">Support</MenuItem>
  </Select>
</FormControl>


<FormControl 
  variant="outlined" 
  style={{ maxWidth: '51%', marginTop: '8px', marginBottom: '16px' }} 
  required 
  fullWidth
>
  <InputLabel id="crew-type-label">Crew Type</InputLabel>
  <Select
    labelId="crew-type-label"
    value={crewType}
    onChange={(e) => setCrewType(e.target.value)}
    label="Crew Type"
  >
    <MenuItem value="Line Crew">Line Crew</MenuItem>
    <MenuItem value="Service Crew">Service Crew</MenuItem>
    <MenuItem value="Damage Assessors">Damage Assessors</MenuItem>
    <MenuItem value="Wire Guards">Wire Guards</MenuItem>
    <MenuItem value="Sub-Contractors">Sub-Contractors</MenuItem>
  </Select>
</FormControl>

<TextField
  variant="outlined"
  margin="normal"
  fullWidth
  style={{ maxWidth: '50%', padding: '3px' }}
  label="License ID"
  value={licenseID}
  onChange={(e) => setLicenseID(e.target.value)}
/>

<TextField
  variant="outlined"
  margin="normal"
  fullWidth
  style={{ maxWidth: '50%', padding: '3px' }}
  label="License Type"
  value={licenseType}
  onChange={(e) => setLicenseType(e.target.value)}
/>

<FormControlLabel
  control={
    <Switch
      checked={personalVehicle}
      onChange={(e) => setPersonalVehicle(e.target.checked)}
      name="personalVehicle"
      color="primary"
    />
  }
  label="Has Personal Vehicle"
  style={{ 
    display: 'flex', 
    alignItems: 'center', 
    marginBottom: '50px', 
    maxWidth: '70%', 
    justifyContent: 'center'
  }}
/>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          style={{ marginBottom: '50px', 
          maxWidth: '60%', 
          justifyContent: 'center', }}
        >
          Add
        </Button>
        {/* If you need any other grid containers or actions like in the SignUp component, add them here. */}
      </form>
    </Container>
  );
};

export default AddEmployee;