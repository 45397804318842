import React from 'react';
import { Card, CardContent, Typography, Button, CardActions, CardMedia } from '@mui/material';
import { styled } from '@mui/system';

const EquipmentCard = ({ equipment }) => {
  

  // Define the styled components and use the theme object
  const StyledCard = styled(Card)(({ theme }) => ({
    width: 233,
    marginBottom: 2,
    transition: "transform 0.15s ease-in-out",
    '&:hover': {
      transform: "scale3d(1.01, 1.01, 1)",
    },
    backgroundColor: theme.palette.background.paper,  // Inherit background color
  }));

  const StyledCardMedia = styled(CardMedia)({
    height: 200,
    width: 300,
    objectFit: 'cover',
  });

  const StyledCardActions = styled(CardActions)({
    display: 'flex',
    justifyContent: 'center',
  });

  const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.palette.secondary.main,  // Inherit secondary color for text
    borderColor: theme.palette.secondary.main,  // Inherit secondary color for border
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark'
        ? theme.palette.primary.dark  // Dark background for hover in dark mode
        : theme.palette.primary.light,  // Light background for hover in light mode
      boxShadow: theme.palette.mode === 'dark'
        ? '0 0 3px #0093ff, 0 0 4px #00f, 0 0 5px #0093ff, 0 0 6px #ca00eb, 0 0 9px #b795f7'
        : '0 0 3px #2ab7d4, 0 0 4px #65b1fc, 0 0 5px #34c6e3, 0 0 6px #4ea5fc, 0 0 7px #2f95fa',
    },
  }));
  
  

  const defaultImageUrl = 'https://firebasestorage.googleapis.com/v0/b/mtv-smart-app.appspot.com/o/expenseReports%2FNick_White_2023-05-01T14%3A32%3A45.478Z_MTV-solutions-logo.png?alt=media&token=f4b217ed-7533-44e7-98fd-730f369c2118';

  return (
    <StyledCard>
      <StyledCardMedia
        component="img"
        image={equipment.imageUrl || defaultImageUrl}
        alt="Equipment Image"
      />
      <CardContent>
        <Typography variant="h5" color="text.primary">{equipment.Make}</Typography>
        
        <Typography variant="h9" color="text.primary"><strong>{equipment.Model}</strong></Typography>
        
        
        
        <Typography variant="subtitle1" color="text.secondary">
          <strong>Year:</strong> {equipment.Year}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          <strong>Type:</strong> {equipment.Type}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          <strong>Location:</strong> {equipment.Location}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          <strong>Assigned:</strong> {equipment.Assigned}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          <strong>ID Number:</strong> {equipment.IDNumber}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          <strong>Plate:</strong> {equipment.Plate}
        </Typography>
      </CardContent>
      <StyledCardActions>

      {/* <StyledButton size="small" variant="outlined"> */}
      {/* View More */}
      {/* </StyledButton> */}

      </StyledCardActions>
    </StyledCard>
  );
};


export default EquipmentCard;
