import React, { useState, useEffect } from 'react';
import {
  getFirestore,
  collection,
  query,
  onSnapshot,
  where,
  doc,
  updateDoc,
  getDoc,
 
} from 'firebase/firestore';
import { getDocs } from 'firebase/firestore';

import {
  Button,
  Grid,
  Menu,
  MenuItem,
  TextField,
  TableContainer,
  Paper, Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  ListItem,
  ListItemText,
  ListItemButton,
  DialogActions,
  IconButton,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Container,
  Box,
  Tooltip,
} from '@mui/material';
import { grey } from '@mui/material/colors';

import List from '@mui/material/List';
import { Tag } from 'antd';
import EditIcon from '@mui/icons-material/Edit';
import PhoneIcon from '@mui/icons-material/Phone';
import FileUploadButton from '../components/FileUploadButton';
import { useTheme } from '@mui/material/styles';
import AssignEquipment from './AssignEquipment';
import OnDeck from './onDeck';
import AccessRoles from '../AccessRoles';

const Bullpen = () => {
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [activeStormEvents, setActiveStormEvents] = useState([]);
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [onDeckSelected, setOnDeckSelected] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [showAssignEquipment, setShowAssignEquipment] = useState(true);


  const roles = [
    { name: 'Working Foreman', abbreviation: 'Working Foreman' },
    { name: 'Line Foreman', abbreviation: 'Line Foreman' },
    { name: 'Field Safety', abbreviation: 'Field Safety' },
    { name: 'Field Support', abbreviation: 'Field Support' },
    { name: 'Flagger', abbreviation: 'Flagger' },
    { name: 'Lineman', abbreviation: 'Lineman' },
    { name: 'Equipment Operator', abbreviation: 'Equipment Operator' },
    { name: 'Service Crew', abbreviation: 'Service Crew' },
    { name: 'Wires Down Guard', abbreviation: 'Wires Down Guard' },
    { name: 'Line Apprentice', abbreviation: 'Line Apprentice' }, // Added 'Line Apprentice' role
    { name: 'Line Equipment Operator', abbreviation: 'Line Equipment Operator' }, // Added 'Line Equipment Operator' role
    { name: 'Line Groundman', abbreviation: 'Line Groundman' }, // Added 'Line Groundman' role
    { name: 'Line Cable Splicer', abbreviation: 'Line Cable Splicer' }, // Added 'Line Cable Splicer' role
    { name: 'Service Crew Electrician', abbreviation: 'Service Crew Electrician' }, // Added 'Service Crew Electrician' role
    { name: 'Line General Foreman', abbreviation: 'Line General Foreman' }, // Added 'Line General Foreman' role
    { name: 'Tree General Foreman', abbreviation: 'Tree General Foreman' }, // Added 'Tree General Foreman' role
    { name: 'Vehicle Mechanic', abbreviation: 'Vehicle Mechanic' }, // Added 'Vehicle Mechanic' role
    { name: 'Damage Assessor', abbreviation: 'Damage Assessor' }, // Added 'Damage Assessor' role
    { name: 'Tree Climber', abbreviation: 'Tree Climber' }, // Added 'Tree Climber' role
    { name: 'Tree Foreman', abbreviation: 'Tree Foreman' }, // Added 'Tree Foreman' role
    { name: 'Tree Groundman', abbreviation: 'Tree Groundman' }, // Added 'Tree Groundman' role
    { name: 'Driver', abbreviation: 'Driver' } // Added 'Driver' role
  ];
  

  useEffect(() => {
    const fetchActiveStormEvents = async () => {
      const db = getFirestore();
      const activeStormEventsQuery = query(collection(db, 'stormEvents'), where('status', '==', 'active'));

      onSnapshot(activeStormEventsQuery, (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setActiveStormEvents(data);
      });
    };

    fetchActiveStormEvents();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();

      // Define the queries for both users and members collections
      let usersQuery = collection(db, 'users');
      let membersQuery = collection(db, 'members');
      if (selectedRole) {
        usersQuery = query(usersQuery, where('role', '==', selectedRole));
        membersQuery = query(membersQuery, where('classification', '==', selectedRole));
      }

      // Fetch data from both collections
      const [usersSnapshot, membersSnapshot] = await Promise.all([
        getDocs(usersQuery),
        getDocs(membersQuery)
      ]);

      // Extract data from both snapshots and merge them
      const usersData = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const membersData = membersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const mergedData = [...usersData, ...membersData];

      setSearchResults(mergedData);
    };
    fetchData();
  }, [selectedRole]);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (searchTerm.trim() === '') {
        return;
    }

    const db = getFirestore();
    const usersRef = collection(db, 'users');
    const membersRef = collection(db, 'members');

    // Build the range for the search term
    const startAtValue = searchTerm;
    const endAtValue = `${searchTerm}\uf8ff`;

    // Queries for 'users' collection
    const userFirstNameQuery = query(usersRef, where('firstName', '>=', startAtValue), where('firstName', '<=', endAtValue));
    const userLastNameQuery = query(usersRef, where('lastName', '>=', startAtValue), where('lastName', '<=', endAtValue));
    const userEmailQuery = query(usersRef, where('email', '>=', startAtValue), where('email', '<=', endAtValue));

    // Queries for 'members' collection
    const memberFirstNameQuery = query(membersRef, where('firstName', '>=', startAtValue), where('firstName', '<=', endAtValue));
    const memberLastNameQuery = query(membersRef, where('lastName', '>=', startAtValue), where('lastName', '<=', endAtValue));
    const memberEmailQuery = query(membersRef, where('email', '>=', startAtValue), where('email', '<=', endAtValue));

    // Execute all queries
    const [
        userFirstNameSnapshot, userLastNameSnapshot, userEmailSnapshot,
        memberFirstNameSnapshot, memberLastNameSnapshot, memberEmailSnapshot
    ] = await Promise.all([
        getDocs(userFirstNameQuery), getDocs(userLastNameQuery), getDocs(userEmailQuery),
        getDocs(memberFirstNameQuery), getDocs(memberLastNameQuery), getDocs(memberEmailQuery)
    ]);

    // Extract data from the snapshots
    const extractData = (snapshot) => snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    const userFirstNameData = extractData(userFirstNameSnapshot);
    const userLastNameData = extractData(userLastNameSnapshot);
    const userEmailData = extractData(userEmailSnapshot);
    const memberFirstNameData = extractData(memberFirstNameSnapshot);
    const memberLastNameData = extractData(memberLastNameSnapshot);
    const memberEmailData = extractData(memberEmailSnapshot);

    // Merge all the results
    setSearchResults([
        ...userFirstNameData, ...userLastNameData, ...userEmailData,
        ...memberFirstNameData, ...memberLastNameData, ...memberEmailData
    ]);
};

  const handleRoleClick = (role) => () => {
    setSelectedRole(role);
  };

  const handleTeamClick = (teams) => {
    setSelectedTeam(teams);
  };

  const handleClearClick = () => {
    setSelectedRole(null); // or setSelectedRole('') based on your initial state definition
  };
  
  const handleAssignDialogClose = () => {
    setAssignDialogOpen(false);
    setSelectedUser(null);
    setSelectedTeam(null);
  };

  const handleEdit = (user) => {
    setEditUser({ ...user }); // Create a copy of user to avoid mutating state directly
    setEditDialogOpen(true);
  };
  
  const handleChange = (field) => (event) => {
    setEditUser((prevEditUser) => {
      
      const updatedUser = { ...prevEditUser, [field]: event.target.value };
  
      // If the field is 'role', also update 'classification'
      if (field === 'role') {
        updatedUser.classification = event.target.value;
      }
  
      // If the field is 'classification', also update 'role'
      if (field === 'classification') {
        updatedUser.role = event.target.value;
      }
  
      return updatedUser;
    });
  };
  
  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setEditUser(null);
  };
  
  const handleSave = async () => {
    if (editUser) {
      const db = getFirestore();
  
      // Check if document exists in 'users' collection
      const userRef = doc(db, 'users', editUser.id);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        await updateDoc(userRef, editUser);
      }
  
      // Check if document exists in 'members' collection
      const memberRef = doc(db, 'members', editUser.id);
      const memberSnap = await getDoc(memberRef);
      if (memberSnap.exists()) {
        await updateDoc(memberRef, editUser);
      }
  
      alert('User details updated successfully.');
      setEditDialogOpen(false);
      setEditUser(null);
    }
  };

  const getRoleColor = (roleOrClassification) => {
    switch(roleOrClassification) {
      case 'Lineman':
        return <Tag color="lime">Lineman</Tag>;
      case 'Line Foreman':
        return <Tag color="magenta">Line Foreman</Tag>;
      case 'Line Apprentice':
        return <Tag color="red">Line Apprentice</Tag>;
      case 'Line Equipment Operator':
        return <Tag color="volcano">Line Equipment Operator</Tag>;
      case 'Line Groundman':
        return <Tag color="orange">Line Groundman</Tag>;
      case 'Line Cable Splicer':
        return <Tag color="gold">Line Cable Splicer</Tag>;
      case 'Working Foreman':
        return <Tag color="lime">Working Foreman</Tag>;
      case 'Service Crew Electrician':
        return <Tag color="green">Service Crew Electrician</Tag>;
      case 'Field Safety':
        return <Tag color="cyan">Field Safety</Tag>;
      case 'Field Support':
        return <Tag color="blue">Field Support</Tag>;
      case 'Flagger':
        return <Tag color="geekblue">Flagger</Tag>;
      case 'Line General Foreman':
        return <Tag color="magenta">Line General Foreman</Tag>;
      case 'Tree General Foreman':
        return <Tag color="red">Tree General Foreman</Tag>;
      case 'Vehicle Mechanic':
        return <Tag color="volcano">Vehicle Mechanic</Tag>;
      case 'Damage Assessor':
        return <Tag color="orange">Damage Assessor</Tag>;
      case 'Driver':
        return <Tag color="gold">Driver</Tag>;
      case 'Wires Down Guard':
        return <Tag color="lime">Wires Down Guard</Tag>;
      case 'Tree Climber':
        return <Tag color="green">Tree Climber</Tag>;
      case 'Tree Foreman':
        return <Tag color="cyan">Tree Foreman</Tag>;
      case 'Tree Groundman':
        return <Tag color="blue">Tree Groundman</Tag>;
      default:
        return <Tag color="geekblue">{roleOrClassification}</Tag>;
    }
  };
  const getCrewTypeColor = (crewType) => {
    switch(crewType) {
      case 'Contractor Line':
        return <Tag color="#E57373">Contractor Line</Tag>;
      case 'Contractor Service':
        return <Tag color="#81C784">Contractor Service</Tag>;
      case 'Damage Appraiser':
        return <Tag color="#08d456">Damage Appraiser</Tag>;
      case 'Wire Down':
        return <Tag color="#8930fc">Wire Down</Tag>;
      case 'Sub-Contractors':
        return <Tag color="#BA68C8">Sub-Contractors</Tag>;
      case 'Transmission':
        return <Tag color="#BA68C8">Transmission</Tag>;
      case 'Contractor Tree':
        return <Tag color="#BA68C8">Contractor Tree</Tag>;
      case 'Contractor Support':
        return <Tag color="#BA68C8">Contractor Support</Tag>;
      default:
        return <Tag color="geekblue">{crewType}</Tag>;
    }
  };
  
const crewTypeData = {
  "Contractor Line": [
    "Lineman",
    "Line Foreman",
    "Line Apprentice",
    "Line Equipment Operator",
    "Line Groundman",
    "Line Cable Splicer",
    "Working Foreman",
  ],
  "Contractor Service": [
    "Line Foreman",
    "Lineman",
    "Service Crew Electrician",
  ],
  "Contractor Support": [
    "Field Safety",
    "Field Support",
    "Flagger",
    "Line Foreman",
    "Line General Foreman",
    "Lineman",
    "Tree General Foreman",
    "Vehicle Mechanic",
  ],
  "Damage Appraiser": [
    "Damage Assessor",
    "Driver",
  ],
  "Transmission": [
    "Lineman",
    "Line Foreman",
    "Line Apprentice",
    "Line Equipment Operator",
    "Line Groundman",
    "Line Cable Splicer",
    "Working Foreman",
  ],
  "Wire Down": [
    "Wires Down Guard",
  ],
  "Contractor Tree": [
    "Tree Climber",
    "Tree Foreman",
    "Tree Groundman",
  ],
};

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelected = searchResults.map((record) => record);
      setOnDeckSelected(newSelected);
      setIsAllSelected(true);
    } else {
      setOnDeckSelected([]);
      setIsAllSelected(false);
    }
  };

  const handleRowSelection = (event, record) => {
    if (event.target.checked) {
      setOnDeckSelected((prevSelectedUsers) => [...prevSelectedUsers, record]);
    } else {
      setOnDeckSelected((prevSelectedUsers) => prevSelectedUsers.filter((user) => user.id !== record.id));
      setIsAllSelected(false);  // Update isAllSelected state if a row is deselected
    }
  };

  const crewIDOptions = ['S1', 'S2', 'S3', 'S4', 'S5', 'S6', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'];

  return (
    <div>
      <Box textAlign="center" mt={4} mb={4}>
        <Tooltip title="Central hub for Crew Management, Equipment Assignment, Roster Management">
          <Typography variant="h4" style={{ marginTop: 20, marginBottom: 10, fontFamily: 'Monospace', fontWeight: 'bold', color: '#1976d2' }}>
            Bullpen
          </Typography>
        </Tooltip>
      </Box>
      <Container>
    {showAssignEquipment && (
      <Container maxWidth="xl">
        <AssignEquipment />
      </Container>
    )}
  <Grid container direction="row" alignItems="center" spacing={3} style={{ padding: '3%' }}>
      {/* Add Employee and File Upload Buttons */}
      <AccessRoles roles={['Storm Manager', 'Admin']}>
        <Grid item>
      
      <FileUploadButton
       
        style={{ 
        width: '16%', 
        padding: '8px 15px', 
        marginLeft: '10px'
        }}
        />            
      </Grid>
      </AccessRoles>
{/* Dropdown and Clear Filter Buttons */}
     <Grid item>
     <Button
      id="roles-button"
      aria-controls={Boolean(anchorEl) ? 'roles-menu' : undefined}
      aria-haspopup="true"
      onClick={(event) => setAnchorEl(event.currentTarget)}
      style={{ marginRight: '10px' }}
      color="secondary"
      variant="outlined"
    >
      Filter Role
      </Button>
      <Menu
      id="roles-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      >
        {roles.map((role) => (
        <MenuItem key={role.name} onClick={() => {
        handleRoleClick(role.name)();
        setAnchorEl(null);
        }}>
        {role.abbreviation}
        </MenuItem>
        ))}
        </Menu>
        <Button variant="contained" style={{ backgroundColor: "#10bee6", color: "white" }} onClick={handleClearClick}>
        Clear Filter
        </Button>
        </Grid>
{/* Search Bar */}
        <Grid item xs>
          <Box display="flex" alignItems="center">
          <form onSubmit={handleSearch} style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          <TextField 
             label="Search User"
              variant="outlined"
              color="secondary"
              value={searchTerm}
              fullWidth
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ marginRight: '10px' }}
              />
          <Button type="submit" variant="outlined" color='secondary'>
            Search
            </Button>
       </form>
           </Box>
          </Grid>
          </Grid>
        <Box sx={{ paddingBottom: '20px' }}>
          <OnDeck selectedRows={onDeckSelected} />
        </Box>
<TableContainer component={Paper} sx={{ marginTop: '10px', padding: '10px', overflowX: 'auto' }}>
  <Table style={{ width: '100%' }}>          
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" style={{ fontWeight: "bold", position: "sticky", top: 0, backgroundColor: "white", borderRadius: "0px" }}>
          <Box display="flex" flexDirection="column" alignItems="left" sx={{ padding: '2px 0', border: "3px solid", borderRadius: "2px" }}>
            <Checkbox
              checked={isAllSelected}
              color="secondary"
              onChange={handleSelectAll}  // Handle select all rows
            />
          </Box>
        </TableCell>
        <TableCell style={{ fontWeight: "bold", position: "sticky", top: 0, borderRadius: 3, backgroundColor: theme.palette.secondary.main }}>
          <Typography color={grey[50]}>First Name</Typography>
        </TableCell>
        <TableCell style={{ fontWeight: "bold", position: "sticky", top: 0, borderRadius: 3, backgroundColor: theme.palette.secondary.main }}>
          <Typography color={grey[50]}>Last Name</Typography>
        </TableCell>
        
        <TableCell style={{ fontWeight: "bold", position: "sticky", top: 0, borderRadius: 3, backgroundColor: theme.palette.secondary.main }}>
          <Typography color={grey[50]}>Phone Number</Typography>
        </TableCell>
        <TableCell style={{ fontWeight: "bold", position: "sticky", top: 0, borderRadius: 3, backgroundColor: theme.palette.secondary.main }}>
          <Typography color={grey[50]}>Email</Typography>
        </TableCell>
        <TableCell style={{ fontWeight: "bold", position: "sticky", top: 0, borderRadius: 3, backgroundColor: theme.palette.secondary.main }}>
          <Typography color={grey[50]}>Member Type</Typography>
        </TableCell>
        <TableCell style={{ fontWeight: "bold", position: "sticky", top: 0, borderRadius: 3, backgroundColor: theme.palette.secondary.main }}>
          <Typography color={grey[50]}>Crew Type</Typography>
        </TableCell>
        <AccessRoles roles={['Storm Manager', 'Admin']}>
        <TableCell style={{ fontWeight: "bold", position: "sticky", top: 0, borderRadius: 3, backgroundColor: theme.palette.secondary.main }}>
          <Typography color={grey[50]}>Edit</Typography>
        </TableCell>
        </AccessRoles>
      </TableRow>
    </TableHead>
    <TableBody>
      {searchResults.map((record) => {
        const tag = record.role 
          ? getRoleColor(record.role) 
          : getRoleColor(record.classification);
        const crewTypeTag = getCrewTypeColor(record.crewType);
        const isSelected = onDeckSelected.some(user => user.id === record.id);
        return (
          <TableRow key={record.id}>
            <TableCell padding="checkbox">
              <Checkbox
                checked={isSelected}
                color="secondary"
                onChange={(event) => handleRowSelection(event, record)}  // Updated
              />
            </TableCell>
            <TableCell>{record.firstName}</TableCell>
            <TableCell>{record.lastName}</TableCell>
            <TableCell>
              <a href={`tel:${record.phone}`} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                <PhoneIcon fontSize="small" style={{ color: '#14d95c', marginRight: '5px' }} />
                {record.phone}
              </a>
            </TableCell>
            <TableCell>
              <a href={`mailto:${record.email}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                {record.email}
              </a>
            </TableCell>
            <TableCell>{tag}</TableCell>
            <TableCell>{crewTypeTag}</TableCell>
            <TableCell>
            <AccessRoles roles={['Storm Manager', 'Admin']}>
              <IconButton onClick={() => handleEdit(record)} style={{ marginLeft: '10px' }}>
                <EditIcon color="secondary" />
              </IconButton>
              </AccessRoles>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  </Table>
</TableContainer>
<Dialog open={assignDialogOpen} onClose={handleAssignDialogClose}>
  <DialogTitle>Assign {selectedUser && `${selectedUser.firstName} ${selectedUser.lastName}`} to a Team</DialogTitle>
  <DialogContent>
    <List>
      {activeStormEvents.map((event) => (
        <React.Fragment key={event.id}>
          <ListItem>
            <ListItemText primary={`Storm Event: ${event.eventName}`} />
          </ListItem>
          {event.teams && event.teams.map((teams) => {
            // Find the first member in the team who has the 'General Foreman' role in their userAccessRole
            const generalForeman = teams.roster?.find((member) => member.role === 'General Foreman') || {};
            return (
              <ListItemButton
                key={`${event.id}-${teams.id}`}
                selected={selectedTeam && selectedTeam.id === teams.id}
                onClick={() => handleTeamClick({ ...teams, stormEventId: event.id })}
              >
                <ListItemText primary={`${teams.teamName} - ${generalForeman.firstName || ''} ${generalForeman.lastName || ''} - ${teams.location}`} />
              </ListItemButton>
            );
          })}
        </React.Fragment>
      ))}
    </List>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleAssignDialogClose} color="secondary" variant="outlined">
      Cancel
    </Button>
  </DialogActions>
</Dialog>
<Dialog open={editDialogOpen} onClose={handleEditDialogClose} fullWidth maxWidth="md">
  <DialogTitle>Edit User Details</DialogTitle>
  <DialogContent>
    <TextField
      autoFocus
      margin="dense"
      id="firstName"
      label="First Name"
      type="text"
      fullWidth
      value={editUser && editUser.firstName}
      onChange={handleChange('firstName')}
    />
    <TextField
      margin="dense"
      id="lastName"
      label="Last Name"
      type="text"
      fullWidth
      value={editUser && editUser.lastName}
      onChange={handleChange('lastName')}
    />
    <FormControl fullWidth margin="dense">
  <InputLabel id="crew-id-label">Crew ID</InputLabel>
  <Select
    labelId="crew-id-label"
    id="crewId"
    value={editUser && editUser.crewID}
    onChange={handleChange('crewID')}
    label="Crew ID"
  >
    {crewIDOptions.map((crewID) => (
      <MenuItem key={crewID} value={crewID}>{crewID}</MenuItem>
    ))}
  </Select>
</FormControl>
    <FormControl fullWidth margin="dense">
      <InputLabel id="crew-type-label">Crew Type</InputLabel>
      <Select
        labelId="crew-type-label"
        id="crewType"
        value={editUser && editUser.crewType}
        onChange={handleChange('crewType')}
      >
        {Object.keys(crewTypeData).map((type) => (
          <MenuItem key={type} value={type}>{type}</MenuItem>
        ))}
      </Select>
    </FormControl>
    {/* Member Type Dropdown */}
    <FormControl fullWidth margin="dense" disabled={!editUser || !editUser.crewType}>
  <InputLabel id="role-label">Member Type</InputLabel>
  <Select
    labelId="role-label"
    id="role"
    value={editUser && editUser.role}
    onChange={handleChange('role')}
  >
    {editUser && editUser.crewType && crewTypeData[editUser.crewType] ? (
      crewTypeData[editUser.crewType].map((role) => (
        <MenuItem key={role} value={role}>{role}</MenuItem>
      ))
    ) : (
      <MenuItem value=""><em>None</em></MenuItem>
    )}
  </Select>
</FormControl>
    <FormControl fullWidth margin="dense">
      <InputLabel id="user-access-role-label">User Access Role</InputLabel>
      <Select
        labelId="user-access-role-label"
        id="userAccessRole"
        value={editUser && editUser.userAccessRole}
        onChange={handleChange('userAccessRole')}
        label="User Access Role"
      >
        <MenuItem value="Storm Manager">Storm Manager</MenuItem>
        <MenuItem value="Admin">Admin</MenuItem>
        <MenuItem value="General Foreman">General Foreman</MenuItem>
        <MenuItem value="Foreman">Foreman</MenuItem>
        <MenuItem value="Safety">Safety</MenuItem>
        <MenuItem value="General User">General User</MenuItem>
      </Select>
    </FormControl>
    
    <FormControl fullWidth margin="dense">
      <InputLabel id="gender-label">Gender</InputLabel>
      <Select
        labelId="gender-label"
        id="gender"
        value={editUser && editUser.gender}
        onChange={handleChange('gender')}
        label="Gender"
      >
        <MenuItem value="Male">Male</MenuItem>
        <MenuItem value="Female">Female</MenuItem>
      </Select>
    </FormControl>
    <FormControl fullWidth margin="dense">
      <InputLabel id="lodging-needed-label">Lodging Needed</InputLabel>
      <Select
        labelId="lodging-needed-label"
        id="lodgingNeeded"
        value={editUser && editUser.lodgingNeeded}
        onChange={handleChange('lodgingNeeded')}
        label="Lodging Needed"
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </Select>
    </FormControl>
    <FormControl fullWidth margin="dense">
      <InputLabel id="lodging-type-label">Lodging Type</InputLabel>
      <Select
        labelId="lodging-type-label"
        id="lodgingType"
        value={editUser && editUser.lodgingType}
        onChange={handleChange('lodgingType')}
        label="Lodging Type"
      >
        <MenuItem value="Single">Single</MenuItem>
        <MenuItem value="Double">Double</MenuItem>
      </Select>
    </FormControl>
    <FormControl fullWidth margin="dense">
      <InputLabel id="personal-vehicle-label">Personal Vehicle</InputLabel>
      <Select
        labelId="personal-vehicle-label"
        id="personalVehicle"
        value={editUser && editUser.personalVehicle}
        onChange={handleChange('personalVehicle')}
        label="Personal Vehicle"
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </Select>
    </FormControl>
    <TextField
      margin="dense"
      id="phone"
      label="Phone"
      type="text"
      fullWidth
      value={editUser && editUser.phone}
      onChange={handleChange('phone')}
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={handleEditDialogClose} color="secondary" variant="outlined">
      Cancel
    </Button>
    <Button onClick={handleSave} color="secondary" varient="outlined">
      Save
    </Button>
  </DialogActions>
</Dialog>
</Container>
    </div>
);
}
export default Bullpen;
