import React from 'react';
import { Typography, Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

const PrintableSafetyForm = React.forwardRef(({ form }, ref) => (
  <div ref={ref} style={{ padding: '20px', border: '1px solid black', backgroundColor: 'white', color: 'black', margin: '10px', width: '600px', fontSize: '12px' }}>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
      <img 
        src="https://firebasestorage.googleapis.com/v0/b/mtv-smart-app.appspot.com/o/expenseReports%2FNick_White_2023-05-01T14%3A32%3A45.478Z_MTV-solutions-logo.png?alt=media&token=f4b217ed-7533-44e7-98fd-730f369c2118" 
        alt="Company Logo"
        style={{ width: '100px', height: 'auto' }}
      />
    </div>
    <Typography variant="h6" style={{ marginBottom: '10px', textAlign: 'center' }}>Safety Report</Typography>

    <Typography variant="body2" component="p"><b>Date:</b> {form.date}</Typography>
    <Typography variant="body2" component="p"><b>Time:</b> {form.time}</Typography>
    <Typography variant="body2" component="p"><b>Performed By:</b> {form.performedBy}</Typography>
    <Typography variant="body2" component="p"><b>Foreman:</b> {form.foreman}</Typography>
    <Typography variant="body2" component="p"><b>General Foreman:</b> {form.generalForeman}</Typography>
    <Typography variant="body2" component="p"><b>Location:</b> {form.location}</Typography>
    <Typography variant="body2" component="p"><b>Contractor:</b> {form.contractor}</Typography>
    <Typography variant="body2" component="p"><b>Tailboards:</b> {form.tailboards}</Typography>
    <Typography variant="body2" component="p"><b>Task Operations:</b> {form.taskOperations}</Typography>
    <Typography variant="body2" component="p"><b>Utility:</b> {form.utility}</Typography>
    <Typography variant="body2" component="p"><b>Comments:</b> {form.comments}</Typography>

    <Box style={{ marginTop: '10px', border: '1px solid black', padding: '10px' }}>
      {Object.entries(form.safetyChecks).map(([key, value]) => (
        <Typography variant="body2" key={key} component="span" style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
          <b>{key}:</b> {value ? <CheckIcon style={{ color: 'green' }} /> : <ErrorIcon style={{ color: 'red' }} />}
        </Typography>
      ))}
    </Box>

    <div style={{ marginTop: '20px', textAlign: 'center' }}>
      <img 
        src={`${form.signature}`} 
        alt="Signature"
        style={{ maxWidth: '50%', height: 'auto', margin: '0 auto', imageRendering: 'crisp-edges' }}
      />
      {/* Generate a random signature ID and display it with the Foreman's name */}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
        <Typography variant="caption" style={{ marginRight: '5px' }}>Signature ID:</Typography>
        <Typography variant="body2">{Math.random().toString(36).substr(2, 9)}</Typography>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
        <Typography variant="caption" style={{ marginRight: '5px' }}>Safety Check Performed By:</Typography>
        <Typography variant="body2">{form.performedBy}</Typography>
      </div>
    </div>
  </div>
));

export default PrintableSafetyForm;
