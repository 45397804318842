import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { query, where, collection, getDocs, getFirestore } from "firebase/firestore";
import { Typography, Grid } from '@mui/material';
import dayjs from 'dayjs';

const TotalDiggerTrucks = () => {
  // Initialize state variables for current and previous day's total diggers
  const [currentTotalDiggers, setCurrentTotalDiggers] = useState(0);
  const [prevTotalDiggers, setPrevTotalDiggers] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();
      const currentDate = dayjs().format('YYYY-MM-DD');
      const prevDate = dayjs().subtract(1, 'day').format('YYYY-MM-DD');

      // Query for current date
      const currentQuery = query(
        collection(db, "dailyReports"),
        where("date", "==", currentDate)
      );

      // Query for previous date
      const prevQuery = query(
        collection(db, "dailyReports"),
        where("date", "==", prevDate)
      );

      // Fetch and set current date total
      let currentSum = 0;
      const currentSnapshot = await getDocs(currentQuery);
      currentSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.selectedStormEvent) {
          currentSum += parseInt(data.numDiggers, 10);
        }
      });
      setCurrentTotalDiggers(currentSum);

      // Fetch and set previous date total
      let prevSum = 0;
      const prevSnapshot = await getDocs(prevQuery);
      prevSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.selectedStormEvent) {
          prevSum += parseInt(data.numDiggers, 10);
        }
      });
      setPrevTotalDiggers(prevSum);
    };
    fetchData();
  }, []);

  // Define the data and options for the Bar chart
  const chartData = {
    labels: ['Previous Day', 'Current Day'],
    datasets: [
      {
        label: 'Digger Trucks',
        data: [prevTotalDiggers, currentTotalDiggers],
        backgroundColor: ['rgba(244, 67, 54, 0.5)', 'rgba(30, 136, 229, 0.5)'],
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: false // Hide the legend
      },
    },
    scales: {
      x: {
        beginAtZero: true
      },
      y: {
        beginAtZero: true
      }
    },
    maintainAspectRatio: false, // Allows custom size
  };

  // Render the component
  return (
    <div style={{ height: '250px', width: '100%' }}>
      <Grid container spacing={6} alignItems="center">
        <Grid item xs={12}>
          <Bar data={chartData} options={chartOptions} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" align="center" style={{ fontWeight: 'bold', padding: '1px' }}>
            Previous: {prevTotalDiggers} | Today: {currentTotalDiggers}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default TotalDiggerTrucks;