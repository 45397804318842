import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Tooltip
} from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { styled } from '@mui/system';
import DescriptionIcon from '@mui/icons-material/Description';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';


const StyledCard = styled(Card)(({ theme }) => ({
  width: 300,
  marginBottom: 2,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  margin: 1,
  padding: 1,
  marginLeft: 10,
  transition: "transform 0.10s ease-in-out",
  '&:hover': {
    transform: "scale3d(1.02, 1.02, 1)",
  },
  border: '1px solid rgba(0, 0, 0, 0.1)', // Adding a light border around the card
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  borderColor: theme.palette.secondary.main,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.default,
  border: '2px solid #3971cc', // Adding a colored border around the card content
  borderRadius: '3px', // Rounding off the edges of the border
  padding: theme.spacing(2),
  
}));

const StyledCardActions = styled(CardActions)({
  display: 'flex',
  justifyContent: 'space-between',
});

const StyledButton = styled(Button)({
  backgroundColor: '#0f69fa',
  color: 'white',
  '&:hover': {
    backgroundColor: '#283593',
  },
});

const CardsContainer = styled('div')({
  display: 'flex', // Apply flexbox layout
  flexWrap: 'wrap', // Allow cards to wrap to the next line
});

const StormWorkOrder = ({ stormWorkOrders, stormWorkOrder, eventName }) => {
  const [open, setOpen] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const theme = useTheme();

  const handleClick = (members) => {
    setSelectedMembers(members);
    setOpen(true);
  };
 

  const handleClose = () => {
    setOpen(false);
  };
  
  const exportCSV = (event, members) => {
    event.stopPropagation();

    const header = ['First Name', 'Last Name', 'Role', 'Total Hours', 'Date', 'Days', 'Personal Vehicle', 'State Worked'];
    const csvRows = [header.join(',')];

    let totalHoursSum = 0;

    members.forEach((member) => {
      // Only render selected days
      const selectedDays = member.days.filter(day => day.selected).map(day => day.id).join(' ');

      const row = [
        member.firstName,
        member.lastName,
        member.role,
        member.totalHours,
        member.date,
        selectedDays, // Render only selected days
        member.personalVehicle,
        member.stateWorked,
        
      ];
      csvRows.push(row.join(','));
      totalHoursSum += parseFloat(member.totalHours || '0');
    });

    csvRows.push('');
    csvRows.push(`Total,,${totalHoursSum},,,,`);
    csvRows.push('');
    csvRows.push('Mobilization');
    csvRows.push('De-Mobilization');
    csvRows.push('StandBy');

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const currentDate = new Date().toISOString().slice(0, 10);
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `Timesheet_${currentDate}.csv`;
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <CardsContainer>
    
    {Object.keys(stormWorkOrders).map((workOrder) => {
  const members = stormWorkOrders[workOrder];
  const specificEventName = members[0]?.eventName || 'Default Event Name';
  // Safely process the days array
  const selectedDays = members[0]?.days
  ? members[0].days
      .filter(day => day.selected)
      .map(day => day.day)
      .join(', ')
  : 'No days selected';

        console.log("First member data:", members[0]);

        const birddogName = members[0]?.birddogName; // Getting birddogName from the first member
        const date = members[0]?.date; // Getting date from the first member
        const stateWorked = members[0]?.stateWorked; // Getting state worked from the first member
        const contractorName = members[0]?.contractorName; // Getting contractor name from the first member
        const employeeName = members[0]?.employeeName; // Getting general foreman (employee name) from the first member
        const stormWorkOrderNumber = members[0]?.stormWorkOrder; 
        // Assume totalHours for the event is available for each member
        const totalHoursForEvent = members[0]?.totalHours || 0;
        const numberOfMembers = members.length; // Number of members working on the event

        // Calculate total billable hours
        const totalBillableHours = totalHoursForEvent * numberOfMembers;
        
return (
<StyledCard key={workOrder}>
<StyledCardContent>
<Typography variant="h6" component="div" style={{ color: theme.palette.text.primary }}>
          <DescriptionIcon color="secondary" /> {specificEventName}
        </Typography>
<Typography variant="body2" style={{ color: theme.palette.text.secondary }}>
<strong style={{ color: theme.palette.text.primary }}>Roster Head Count:</strong> {members.length}
<br />
<strong style={{ color: theme.palette.text.primary }}>Total Billable Hours:</strong> {totalBillableHours}
<br />
<strong style={{ color: theme.palette.text.primary }}>Birddog Name:</strong> {birddogName}
<br />
<strong style={{ color: theme.palette.text.primary }}>Date:</strong> {new Date(date).toLocaleDateString()}
<br />
<strong style={{ color: theme.palette.text.primary }}>State Worked:</strong> {stateWorked}
<br />
<strong style={{ color: theme.palette.text.primary }}>Contractor Name:</strong> {contractorName}
<br />
<strong style={{ color: theme.palette.text.primary }}>General Foreman:</strong> {employeeName}
<br />
<strong style={{ color: theme.palette.text.primary }}>Day:</strong> {selectedDays}
<br />
<strong style={{ color: theme.palette.text.primary }}>Work Order #:</strong> {stormWorkOrderNumber}
 {/* Displaying the count of selected days */}
              </Typography>
            </StyledCardContent>
            <StyledCardActions>
              <StyledButton size="small" onClick={() => handleClick(members)}>Quick View</StyledButton>
              <StyledButton size="small" onClick={(event) => exportCSV(event, members)}>
                <GetAppIcon /> Export CSV
              </StyledButton>
              <Tooltip title="Navigate to Timesheet final approval for edits and exports">
                <Link to={`/admin-approval/${workOrder}`} style={{ float: 'right', color: theme.palette.text.secondary }}> {/* Including workOrder in the link */}
                  <SettingsIcon color="secondary" />
                </Link>
              </Tooltip>
            </StyledCardActions>
          </StyledCard>
        );
      })}
  
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>
          Quick View
          <IconButton aria-label="close" onClick={handleClose} style={{ position: 'absolute', right: '8px', top: '8px' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Member Type</TableCell>
                  <TableCell>Crew Type</TableCell> {/* Added 'Crew Type' column */}
                  <TableCell>Crew ID</TableCell>
                  <TableCell>Gender</TableCell>
                  <TableCell>Lodging Needed</TableCell>
                  <TableCell>Lodging Type</TableCell>
                  <TableCell>PV</TableCell>
                  <TableCell align="right">Total Hours</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedMembers.map((member, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {member.firstName}
                    </TableCell>
                    <TableCell>{member.lastName}</TableCell>
                    <TableCell>{member.classification || member.role}</TableCell>
                    <TableCell>{member.crewType}</TableCell> {/* Added 'Crew Type' data */}
                    <TableCell>{member.crewID}</TableCell>
                    <TableCell>{member.gender}</TableCell>
                    <TableCell>{member.lodgingNeeded ? 'Yes' : 'No'}</TableCell>
                    <TableCell>{member.lodgingType}</TableCell>
                    <TableCell>{member.personalVehicle ? 'Yes' : 'No'}</TableCell>
                    <TableCell align="right">{member.totalHours}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    
    </CardsContainer>
  );
  }
  export default StormWorkOrder;