import React from 'react';
import { Typography } from '@mui/material';

const PrintableReportCard = React.forwardRef(({ report }, ref) => (
  <div ref={ref} style={{ padding: '20px', border: '1px solid black', backgroundColor: 'white', color: 'black', margin: '5px', width: '600px', fontSize: '12px' }}>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '5px' }}>
      <img 
        src="https://firebasestorage.googleapis.com/v0/b/mtv-smart-app.appspot.com/o/expenseReports%2FNick_White_2023-05-01T14%3A32%3A45.478Z_MTV-solutions-logo.png?alt=media&token=f4b217ed-7533-44e7-98fd-730f369c2118" 
        alt="Company Logo"
        style={{ width: '80px', height: 'auto' }}
      />
    </div>
    
    <Typography variant="h6" style={{ marginBottom: '10px' }}>Daily Report</Typography>

    <div style={{ marginBottom: '5px' }}>
      <strong>Event:</strong> {report.selectedStormEvent}
    </div>
    <div style={{ marginBottom: '5px' }}>
      <strong>Date:</strong> {report.date}
    </div>
    <div style={{ marginBottom: '5px' }}>
      <strong>GF:</strong> {report.foremanName}
    </div>
    
    <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '5px' }}>Bird Dogs:</Typography>
    <Typography variant="body2" style={{ marginBottom: '10px' }}>{report.birdDogs}</Typography>

    <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '5px' }}>Crew Working:</Typography>
    <Typography variant="body2" style={{ marginBottom: '10px' }}>{report.crewWorking}</Typography>

    <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '5px' }}>Classification:</Typography>
    <Typography variant="body2" style={{ marginBottom: '10px' }}>{report.classification}</Typography>

    <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '5px' }}>Email:</Typography>
    <Typography variant="body2" style={{ marginBottom: '10px' }}>{report.email}</Typography>

    <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '5px' }}>Phone:</Typography>
    <Typography variant="body2" style={{ marginBottom: '10px' }}>{report.phone}</Typography>

    <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '5px' }}>Lodging Provided By:</Typography>
    <Typography variant="body2" style={{ marginBottom: '10px' }}>{report.lodgingProvidedBy}</Typography>

    <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '5px' }}>Hotel Name:</Typography>
    <Typography variant="body2" style={{ marginBottom: '10px' }}>{report.hotelName}</Typography>

    <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '5px' }}>Hotel City:</Typography>
    <Typography variant="body2" style={{ marginBottom: '10px' }}>{report.hotelCity}</Typography>

    <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '5px' }}>Hotel Address:</Typography>
    <Typography variant="body2" style={{ marginBottom: '10px' }}>{report.hotelAddress}</Typography>

    <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '5px' }}>Number of Buckets:</Typography>
    <Typography variant="body2" style={{ marginBottom: '10px' }}>{report.numBuckets}</Typography>

    <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '5px' }}>Number of Diggers:</Typography>
    <Typography variant="body2" style={{ marginBottom: '10px' }}>{report.numDiggers}</Typography>

    <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '5px' }}>Vehicle Issues:</Typography>
    <Typography variant="body2" style={{ marginBottom: '10px' }}>{report.vehicleIssues}</Typography>

    <div style={{ marginTop: '20px', textAlign: 'center' }}>
      <img 
        src={`${report.signature}`} 
        alt="Signature"
        style={{ maxWidth: '50%', height: 'auto', margin: '0 auto', imageRendering: 'crisp-edges' }}
      />
      {/* Generate a random signature ID and display it with the Foreman's name */}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
        <Typography variant="caption" style={{ marginRight: '5px' }}>Signature ID:</Typography>
        <Typography variant="body2">{Math.random().toString(36).substr(2, 9)}</Typography>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
        <Typography variant="caption" style={{ marginRight: '5px' }}>Foreman:</Typography>
        <Typography variant="body2">{report.foremanName}</Typography>
      </div>
    </div>
  </div>
));

export default PrintableReportCard;
