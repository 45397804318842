import React from 'react';
import { Card, CardContent, Typography, Button, CardActions, CardMedia, useTheme } from '@mui/material';
import { styled } from '@mui/system';

const StyledCard = styled(Card)(({ theme }) => ({
  width: 250,
  marginBottom: 2,
  margin: 1,
  padding: 1,
  transition: "transform 0.10s ease-in-out",
  '&:hover': {
    transform: "scale3d(1.01, 1.01, 1)",
  },
  border: `3px solid ${theme.palette.divider}`, // Updated border color
}));

const StyledCardMedia = styled(CardMedia)({
  height: 100,
  width: 100,
  objectFit: 'cover',
});

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  
  borderRadius: '9px',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper, // Updated background color
}));

const StyledCardActions = styled(CardActions)({
  display: 'flex',
  justifyContent: 'center',
});

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main, // Updated background color
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark, // Updated hover color
  },
}));

const MemberHours = ({ memberHour }) => {
  const theme = useTheme();
  const defaultImageUrl = 'https://firebasestorage.googleapis.com/v0/b/mtv-smart-app.appspot.com/o/Images%2Fnickwhite0x_placeholder_image_of_an_electrical_lineman_with_a_h_2a49a5aa-2d93-4dc1-8994-a05292a6dd0c.png?alt=media&token=b632b6db-6729-4e25-a2d8-96701b11823b'; // your image URL here

  // Filter only the selected days
  const selectedDays = memberHour.days.filter(day => day.selected);

  return (
    <StyledCard>
      <StyledCardContent>
        <Typography variant="h5" style={{ color: theme.palette.text.primary }}>
          {memberHour.firstName} {memberHour.lastName}
        </Typography>
        <Typography variant="body2" style={{ color: theme.palette.text.secondary }}>
          <strong style={{ color: theme.palette.text.primary }}>Date:</strong> {new Date(memberHour.date).toLocaleDateString()}
        </Typography>

        {selectedDays.map((day) => (
  <div key={day.id}>
    <Typography variant="body2" style={{ color: theme.palette.text.secondary }}>
      <strong style={{ color: theme.palette.text.primary }}>{day.day}:</strong> 
      {day.intervals ? (
        day.intervals.map((interval, index) => (
          <span key={index}>
            {interval.startTime} - {interval.endTime}
            {index < day.intervals.length - 1 && ", "}
          </span>
        ))
      ) : (
        'No intervals defined'
      )}
    </Typography>
  </div>
))}


        <Typography variant="body2" style={{ color: theme.palette.text.secondary }}>
          <strong style={{ color: theme.palette.text.primary }}>Personal Vehicle:</strong> {memberHour.hasPersonalVehicle ? 'Yes' : 'No'}
        </Typography>
        <Typography variant="body2" style={{ color: theme.palette.text.secondary }}>
          <strong style={{ color: theme.palette.text.primary }}>Classification:</strong> {memberHour.classification}
        </Typography>
        <Typography variant="body2" style={{ color: theme.palette.text.secondary }}>
          <strong style={{ color: theme.palette.text.primary }}>State Worked:</strong> {memberHour.stateWorked}
        </Typography>
        <Typography variant="body2" style={{ color: theme.palette.text.secondary }}>
          <strong style={{ color: theme.palette.text.primary }}>Storm Event:</strong> {memberHour.stormWorkOrder}
        </Typography>
        <Typography variant="body2" style={{ color: theme.palette.text.secondary }}>
          <strong style={{ color: theme.palette.text.primary }}>Total Hours:</strong> {memberHour.totalHours}
        </Typography>
      </StyledCardContent>
      <StyledCardActions>
        {/* <StyledButton size="small" variant="contained">
          View More
        </StyledButton> */}
      </StyledCardActions>
    </StyledCard>
  );
};

export default MemberHours;