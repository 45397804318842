import React, { useState, useRef } from 'react';
import { Box, Button, TextField, Typography, Grid, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Container, IconButton } from '@mui/material';
import { getFirestore, addDoc, collection } from 'firebase/firestore';
import { storage } from '../firebase';
import { ref, uploadBytes } from 'firebase/storage';
import SignatureCanvas from 'react-signature-canvas';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const getCurrentTime = () => {
  const now = new Date();
  let hours = now.getHours().toString();
  let minutes = now.getMinutes().toString();

  hours = hours.length === 1 ? '0' + hours : hours;
  minutes = minutes.length === 1 ? '0' + minutes : minutes;

  return `${hours}:${minutes}`;
};

const SafetyForm = () => {
    const [formData, setFormData] = useState({
      performedBy: '',
      location: '',
      date: '',
      time: getCurrentTime(),
      utility: '',
      contractor: '',
      generalForeman: '',
      foreman: '',
      taskOperations: '',
      comments: '',
      tailboards: '',
      signature: '',
      safetyChecks: {
        hardhat: false,
        safetyGlasses: false,
        hiVizVest: false,
        workGloves: false,
        vrGloves: false,
        vrSleeves: false,
        hearingProtection: false,
        cones: false,
        flags: false,
      },
    });
    
    
    const sigPadRef = useRef(null);

    // Function to clear the signature canvas
    const clearSignature = () => {
      if (sigPadRef.current) {
        sigPadRef.current.clear();
      }
    };


    const handleFileUpload = (e) => {
      const file = e.target.files[0];
      if (!file) return;
    
      // Constructing the filename
      const dateStr = formData.date.replace(/[^a-zA-Z0-9]/g, '_'); // Replace non-alphanumeric characters
      const performedByStr = formData.performedBy.replace(/[^a-zA-Z0-9]/g, '_');
      const locationStr = formData.location.replace(/[^a-zA-Z0-9]/g, '_');
      const filename = `tailBoards_${dateStr}_${performedByStr}_${locationStr}_${file.name}`;
    
      // Ensure the file is saved in the '/tailBoards' directory
      const fileRef = ref(storage, `/tailBoards/${filename}`);
    
      uploadBytes(fileRef, file).then(() => {
        console.log("Uploaded a file:", filename);
      });
    };
    
    const handleChange = (e) => {
      const { name, value, checked, type } = e.target;
    
      // Handle changes for safetyChecks specifically
      if (name.startsWith("safetyChecks.")) {
        const safetyCheckKey = name.split(".")[1];
        setFormData(prevState => ({
          ...prevState,
          safetyChecks: {
            ...prevState.safetyChecks,
            [safetyCheckKey]: checked,
          },
        }));
      } else {
        // Handle changes for other form fields
        setFormData({
          ...formData,
          [name]: type === 'checkbox' ? checked : value,
        });
      }
    };
    
    const handleSubmit = async (e) => {
      e.preventDefault();
    
      // Extract the signature as a data URL
      const signatureImage = sigPadRef.current.getTrimmedCanvas().toDataURL('image/png');
    
      // Update formData with the signature
      const updatedFormData = {
        ...formData,
        signature: signatureImage
      };
    
      const db = getFirestore();
      await addDoc(collection(db, 'safetyForm'), updatedFormData);
    
      // Reset the form and clear the signature canvas
      setFormData({
        performedBy: '',
        location: '',
        date: '',
        time: '',
        utility: '',
        contractor: '',
        generalForeman: '',
        foreman: '',
        taskOperations: '',
        comments: '',
        tailboards: '',
        signature: '',
        safetyChecks: {
          hardhat: false,
          safetyGlasses: false,
          hiVizVest: false,
          workGloves: false,
          vrGloves: false,
          vrSleeves: false,
          hearingProtection: false,
          cones: false,
          flags: false,
        },
      });
      sigPadRef.current.clear();
    };
    

    return (
        
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <IconButton 
                edge="start" 
                color="secondary" 
                aria-label="back" 
                onClick={() => window.history.back()} 
                style={{ position: 'absolute', top: 15, left: 15, padding: 2 }}
              >
                <ArrowBackIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Box border={2} borderRadius={2} p={3} mx={3} my={2}>
          
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Typography variant="h5" style={{ marginTop: 20, marginBottom: 10, fontFamily: 'Monospace', fontWeight: 'bold', color: '#1976d2', textAlign: 'center' }}>Safety Audit Form</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField name="performedBy" label="Audit Performed By" variant="outlined" fullWidth onChange={handleChange} value={formData.performedBy} />
                </Grid>
                
                <Grid item xs={6}>
                  <TextField name="location" label="Location" variant="outlined" fullWidth onChange={handleChange} value={formData.location} />
                </Grid>
                <Grid item xs={4}>
                  <TextField name="date" label="Date" type="date" variant="outlined" fullWidth InputLabelProps={{ shrink: true }} onChange={handleChange} value={formData.date} />
                </Grid>
                <Grid item xs={4}>
                  <TextField name="time" label="Time" type="time" variant="outlined" fullWidth InputLabelProps={{ shrink: true }} onChange={handleChange} value={formData.time} />
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Utility</InputLabel>
                    <Select name="utility" value={formData.utility} onChange={handleChange} label="Utility">
                      <MenuItem value="Eversource-MA">Eversource-MA</MenuItem>
                      <MenuItem value="Eversource-NH">Eversource-NH</MenuItem>
                      <MenuItem value="Eversource-CT">Eversource-CT</MenuItem>
                      <MenuItem value="Eversource-NY">Eversource-NY</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField name="contractor" label="Contractor" variant="outlined" fullWidth onChange={handleChange} value={formData.contractor} />
                </Grid>
                <Grid item xs={3}>
                  <TextField name="generalForeman" label="General Foreman" variant="outlined" fullWidth onChange={handleChange} value={formData.generalForeman} />
                </Grid>
                <Grid item xs={3}>
                  <TextField name="foreman" label="Foreman" variant="outlined" fullWidth onChange={handleChange} value={formData.foreman} />
                </Grid>
                <Grid item xs={12}>
                  <TextField name="taskOperations" label="Task/Operations" variant="outlined" fullWidth multiline rows={4} onChange={handleChange} value={formData.taskOperations} />
                </Grid>
                <Grid item xs={12}>
                  <TextField name="comments" label="Comments" variant="outlined" fullWidth multiline rows={4} onChange={handleChange} value={formData.comments} />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Tailboards</InputLabel>
                    <Select name="tailboards" value={formData.tailboards} onChange={handleChange} label="Tailboards">
                      <MenuItem value="Excellent">Excellent</MenuItem>
                      <MenuItem value="Good">Good</MenuItem>
                      <MenuItem value="Poor">Poor</MenuItem>
                      <MenuItem value="Not Completed">Not Completed</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                <Button
  variant="contained"
  component="label"
  color="secondary"
>
  Upload Paper Tailboard
  <input
    type="file"
    hidden
    onChange={handleFileUpload}
  />
</Button>
</Grid>

<Box border={2} borderRadius={2} p={2} mt={2}>
<Typography variant="subtitle2" gutterBottom fontWeight="bold">
  Safety Equipment Checks
</Typography>

  <Grid container spacing={2}>
    {['hardhat', 'safetyGlasses', 'hiVizVest', 'workGloves', 'vrGloves', 'vrSleeves', 'hearingProtection', 'cones', 'flags'].map((item, idx) => (
      <Grid item xs={6} key={idx}>
        <FormControlLabel
          control={
            <Checkbox
              name={`safetyChecks.${item}`}
              checked={formData.safetyChecks[item]}
              onChange={handleChange}
              color="secondary" // Set color to secondary
            />
          }
          label={item.charAt(0).toUpperCase() + item.slice(1).replace(/([A-Z])/g, ' $1')}
        />
      </Grid>
    ))}
  </Grid>
  <Grid item xs={12}>
</Grid>
</Box>

{/* Signature Box */}
<Grid container spacing={2}>
        <Grid item xs={12}>
          <Box border={2} borderRadius={2} p={2} mt={2}>
            <Typography variant="subtitle1" gutterBottom>Signature</Typography>
            <SignatureCanvas
              ref={sigPadRef}
              penColor='black'
              canvasProps={{
                width: 600,
                height: 200,
                className: 'sigCanvas'
              }}
            />
            {/* Clear Signature Button below the canvas */}
            <Box mt={2} style={{ textAlign: 'left' }}> {/* Adjust margin and alignment here */}
              <Button onClick={clearSignature} color="secondary">
                Clear Signature
              </Button>
            </Box>
          </Box>
        </Grid>
        
        <Grid item xs={12}>
          <Button type="submit" variant="outlined" color="secondary" fullWidth>Submit</Button>
        </Grid>
      </Grid>
      </Grid>
      </Box>
    </Box>
  </Container>
  );
}

export default SafetyForm;