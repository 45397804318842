import React, { useState } from 'react';
import {
  Box,
  Container,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

const CreateTeams = ({ setTeams, teams, eventDate, stormName, stormWorkOrder, utilityName, location }) => {
  // State variables for team creation
  const [teamName, setTeamName] = useState("");
  const [teamLocation, setTeamLocation] = useState("");
  const [crewType, setCrewType] = useState("");
  const [AWClocation, setAWClocation] = useState("");
  const [manualTeamName, setManualTeamName] = useState("");

  // Function to handle adding a new team
 // Function to handle adding a new team
const handleAddTeam = () => {
  if (manualTeamName && teamLocation && crewType && AWClocation) { // Corrected condition
    const newTeam = {
      id: uuidv4(),
      teamName: manualTeamName,
      location: teamLocation,
      crewType,
      AWClocation, // Added AWC location
      roster: [],
    };
    setTeams(prevTeams => [...prevTeams, newTeam]);

    // Clear form fields
    setTeamLocation("");
    setCrewType("");
    setAWClocation("");
    setManualTeamName("");
  } else {
    alert('All fields are required.');
  }
};


  return (
    <Container>
    <Typography 
  variant="subtitle1" 
  gutterBottom 
  sx={{ 
    marginBottom: '50px', 
    fontWeight: 'bold', 
    textAlign: 'center' 
  }}
>
  Create up to 10 crews under this storm event. Cards will be displayed below every time you "Add Team". Members can be assigned to a crew in the Bullpen.
</Typography>

      
      <Grid container spacing={2}>

      <Grid item xs={6}>
        <Box m={1}>
          <TextField
            label="Team Name"
            fullWidth
            variant="outlined"
            value={manualTeamName}
            onChange={(e) => setManualTeamName(e.target.value)}
          />
        </Box>
      </Grid>
        {/* Team Location Field */}
        <Grid item xs={6}>
          <Box m={1}>
            <FormControl fullWidth>
              <InputLabel id="team-location-select-label">State</InputLabel>
              <Select
                labelId="team-location-select-label"
                id="team-location-select"
                value={teamLocation}
                onChange={(e) => setTeamLocation(e.target.value)}
                
              >
                <MenuItem value={"MA"}>MA</MenuItem>
                <MenuItem value={"NH"}>NH</MenuItem>
                <MenuItem value={"CT"}>CT</MenuItem>
                <MenuItem value={"NY"}>NY</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>

        {/* AWC Location Field */}
        <Grid item xs={6} sm={6}>
          <Box m={1}>
            <TextField
              label="AWC Location"
              fullWidth
              value={AWClocation}
              onChange={(e) => setAWClocation(e.target.value)}
              
            />
          </Box>
        </Grid>

        {/* Crew Type Field */}
        <Grid item xs={6}>
          <Box m={1}>
            <FormControl fullWidth>
              <InputLabel id="crew-type-select-label">Crew Type</InputLabel>
              <Select
                labelId="crew-type-select-label"
                id="crew-type-select"
                value={crewType}
                onChange={(e) => setCrewType(e.target.value)}
                
              >
                <MenuItem value={"Contractor Line"}>Contractor Line</MenuItem>
                <MenuItem value={"Contractor Service"}>Contractor Service</MenuItem>
                <MenuItem value={"Contractor Support"}>Contractor Support</MenuItem>
                <MenuItem value={"Damage Appraiser"}>Damage Appraiser</MenuItem>
                <MenuItem value={"Transmission"}>Transmission</MenuItem>
                <MenuItem value={"Wire Down"}>Wire Down</MenuItem>
                <MenuItem value={"Contractor Tree"}>Contractor Tree</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>

        {/* Add Team Button */}
        <Grid item xs={12}>
        <Box m={1} display="flex" justifyContent="center">
    <Button variant="outlined" color="secondary" onClick={handleAddTeam}>
      Add Crew
    </Button>
  </Box>
        </Grid>
      </Grid>

{/* Displaying created teams */}
<Box mt={4} border={2} borderColor="secondary" borderRadius={2} p={2} m={2}>
        <Typography 
          variant="subtitle1" 
          gutterBottom 
          sx={{ 
            marginBottom: '50px', 
            fontWeight: 'bold', 
            textAlign: 'center' 
          }}
        >
          Created Crews
        </Typography>
        
        <Grid container spacing={2}>
          {teams && teams.map((team, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="div">
                    {team.teamName}
                  </Typography>
                  <Typography variant="body2">
                    Event Date: {eventDate}
                  </Typography>
                  <Typography variant="body2">
                    Storm Name: {stormName}
                  </Typography>
                  <Typography variant="body2">
                    Storm Work Order: {stormWorkOrder}
                  </Typography>
                  <Typography variant="body2">
                    Utility Name: {utilityName}
                  </Typography>
                  <Typography variant="body2">
                    State: {location}
                  </Typography>
                  <Typography variant="body2">
                    Crew Type: {team.crewType}
                  </Typography>
                  <Typography variant="body2">
                    AWC Location: {team.AWClocation}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default CreateTeams;