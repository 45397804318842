import React, { useState, useEffect, useRef } from 'react';
import { Box, Card, CardContent, CardMedia, Grid, Typography, IconButton, CardHeader } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase';
import PrintableSafetyForm from './PrintableSafetyForm'; // Adjust the import path as needed
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const SafetyFormFetcher = () => {
  const [safetyForms, setSafetyForms] = useState([]);
  const printRef = useRef([]);

  useEffect(() => {
    const fetchSafetyForms = async () => {
      const safetyFormCollection = collection(firestore, 'safetyForm');
      const safetyFormData = await getDocs(safetyFormCollection);
      const safetyFormsList = safetyFormData.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSafetyForms(safetyFormsList);
    };

    fetchSafetyForms();
  }, []);

  const renderSafetyCheck = (check, label) => (
    <Typography variant="body2" component="p">
      {label}: {check ? 'Yes' : 'No'}
    </Typography>
  );

  // Function to export card content as PDF
const exportPDF = async (index) => {
  const element = printRef.current[index];

  if (!element) {
    console.error("Element not found for PDF export.");
    return;
  }

  const canvas = await html2canvas(element, {
    scale: 5, // Adjust scale as needed for resolution
    useCORS: true
  });

  const imgData = canvas.toDataURL('image/png');
  const pdf = new jsPDF({
    orientation: 'p', // 'p' for portrait, 'l' for landscape
    unit: 'pt',
    format: 'a4' // Standard A4 size
  });

  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();
  const canvasWidth = canvas.width;
  const canvasHeight = canvas.height;

  // Check and correct dimensions
  const scale = Math.min(pdfWidth / canvasWidth, pdfHeight / canvasHeight);
  const x = (pdfWidth - canvasWidth * scale) / 2;
  const y = (pdfHeight - canvasHeight * scale) / 2;

  // Debugging logs
  console.log('PDF Dimensions:', pdfWidth, pdfHeight);
  console.log('Canvas Dimensions:', canvasWidth, canvasHeight);
  console.log('Image Position and Scale:', x, y, scale);

  // Validate dimensions before adding the image
  if (x >= 0 && y >= 0 && scale > 0) {
    pdf.addImage(imgData, 'PNG', x, y, canvasWidth * scale, canvasHeight * scale);
    pdf.save('Safety Report.pdf');
  } else {
    console.error('Invalid dimensions for PDF export');
  }
};

  return (
    <Grid container spacing={2}>
      {safetyForms.map((form, index) => (
        <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
          <Card sx={{ height: '500px', overflow: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <CardHeader
                action={
                  <IconButton aria-label="export" onClick={() => exportPDF(index)}>
                <GetAppIcon />
                </IconButton>
                }
                title={
                  <Typography variant="h6" style={{ lineHeight: '1.5' }}>
                    {form.date} - {form.utility} - {form.performedBy}
                  </Typography>
                }
              />

            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                <b>Safety Form - {form.performedBy}</b>
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <b>Date:</b> {form.date}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <b>Time:</b> {form.time}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <b>Performed By:</b> {form.performedBy}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <b>Foreman:</b> {form.foreman}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <b>General Foreman:</b> {form.generalForeman}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <b>Location:</b> {form.location}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <b>Contractor:</b> {form.contractor}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <b>Tailboards:</b> {form.tailboards}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <b>Task Operations:</b> {form.taskOperations}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <b>Utility:</b> {form.utility}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <b>Comments:</b> {form.comments}
              </Typography>
              {/* ... other fields */}
              <Box>
                {Object.entries(form.safetyChecks).map(([key, value]) =>
                  <b>{renderSafetyCheck(value, key)}</b>
                )}
              </Box>
              </CardContent>
            {form.signature && (
              <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
                <CardMedia
                  component="img"
                  sx={{ width: '100%', height: 'auto', maxHeight: 200 }}
                  image={`${form.signature}`}
                  alt="Signature"
                />
              </Box>
            )}
          </Card>
        </Grid>
      ))}

      {/* Hidden Printable Safety Forms for PDF Export */}
      <div style={{ position: 'absolute', left: '-10000px', top: '0px' }}>
  {safetyForms.map((report, index) => (
    <PrintableSafetyForm key={index} form={report} ref={el => printRef.current[index] = el} />
  ))}
    </div>
    </Grid>
  );
};

export default SafetyFormFetcher;