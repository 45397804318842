import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Link, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { collection, getDocs, getFirestore } from 'firebase/firestore';

const db = getFirestore();

const DailySignListTable = () => {
  const [signListData, setSignListData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isLocationDialogOpen, setIsLocationDialogOpen] = useState(false);
   // Helper function to render the signature image
   const renderSignatureImage = (signatureBase64) => {
    // Assuming the base64 string is for a PNG image
    return `${signatureBase64}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, 'dailySignList'));
      const dataList = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const { checkIns, teams } = data;
  
        // Map checkIns to teams based on memberId and id
        const mappedData = checkIns.map(checkIn => {
          const teamMember = teams.find(member => member.id === checkIn.memberId);
          return { ...checkIn, ...teamMember };
        });
  
        dataList.push(...mappedData);
      });
  
      // Sort the dataList array by date in descending order
      dataList.sort((a, b) => b.date.localeCompare(a.date));
  
      setSignListData(dataList);
    };
  
    fetchData();
  }, []);
  

  // Function to handle map click
  const handleMapClick = (locationData) => {
    setSelectedLocation(locationData);
    setIsLocationDialogOpen(true);
  };

  // Function to close the location dialog
  const handleCloseLocationDialog = () => {
    setIsLocationDialogOpen(false);
  };

  // Helper function to generate the URL for a static map image
  const getStaticMapUrl = (latitude, longitude) => {
    const apiKey = 'AIzaSyDRs7rrnKS9mP_AAGcW45JlNLPtapzl-xA';
    const url = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=14&size=200x200&maptype=roadmap&markers=color:red%7Clabel:S%7C${latitude},${longitude}&key=${apiKey}`;
    return url;
  };



  return (
    <Box>
      
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell align="right">Time</TableCell> {/* New Time Header */}
              <TableCell align="right">First Name</TableCell>
              <TableCell align="right">Last Name</TableCell>
              <TableCell align="right">Role</TableCell>
              <TableCell align="right">Signature</TableCell>
              <TableCell align="right">Phone</TableCell>
              <TableCell align="right">Location</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {signListData.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">{row.date}</TableCell>
                <TableCell align="right">{row.timestamp}</TableCell> {/* New Time Data */}
                <TableCell align="right">{row.firstName}</TableCell>
                <TableCell align="right">{row.lastName}</TableCell>
                <TableCell align="right">{row.role || row.classification}</TableCell>
                <TableCell align="right">
                  <img 
                    src={renderSignatureImage(row.signature)} 
                    alt="Signature" 
                    style={{ maxWidth: '100px', maxHeight: '50px' }}
                  />
                </TableCell>
                <TableCell align="right">{row.phone}</TableCell>
                <TableCell align="right">
                  <Link component="button" onClick={() => handleMapClick(row)}>
                    <img 
                      src={getStaticMapUrl(row.latitude, row.longitude)} 
                      alt="Map Location" 
                      style={{ width: '100px', height: '100px' }}
                    />
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Dialog to display location details */}
      {selectedLocation && (
        <Dialog
          open={isLocationDialogOpen}
          onClose={handleCloseLocationDialog}
        >
          <DialogTitle>Location Details</DialogTitle>
          <DialogContent>
            <Typography>Latitude: {selectedLocation.latitude}</Typography>
            <Typography>Longitude: {selectedLocation.longitude}</Typography>
            
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default DailySignListTable;
