import React, { useState, useEffect } from 'react';
import { collection, query, where, getFirestore, getDocs, doc, getDoc } from 'firebase/firestore';
import { Button, Box, Select, MenuItem, FormControl, InputLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Snackbar } from '@mui/material';
import { Tag } from 'antd';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const RosterManager = () => {
  const [events, setEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState('');
  const [equipmentData, setEquipmentData] = useState({});
  const [isExported, setIsExported] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
      const fetchEvents = async () => {
          const db = getFirestore();
          const q = query(collection(db, 'stormEvents'), where('status', '==', 'active'));
          const querySnapshot = await getDocs(q);
          const fetchedEvents = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setEvents(fetchedEvents);
      };

      fetchEvents();
  }, []);

  const fetchMemberEquipment = async (db, memberId) => {
    const memberRef = doc(db, 'members', memberId);
    const memberSnap = await getDoc(memberRef);

    if (memberSnap.exists()) {
        const equipmentMap = memberSnap.data().equipment;
        
        console.log("Equipment Map in fetchMemberEquipment:", equipmentMap);

        if (equipmentMap && typeof equipmentMap === 'object' && Object.keys(equipmentMap).length > 0) {
          const firstKey = Object.keys(equipmentMap)[0];
          const equipmentDetails = equipmentMap[firstKey];
          return equipmentDetails
            ? `${equipmentDetails['IDNumber'] || ''} - ${equipmentDetails['Type'] || ''}`.trim()
            : '';
        }
    }
    return '';
  };

  useEffect(() => {
    const fetchAllEquipmentData = async () => {
      const db = getFirestore();
      const selectedEvent = events.find(event => event.id === selectedEventId);

      console.log('Selected event: ', selectedEvent);

      if (!selectedEvent || !selectedEvent.teams) return;

      const newEquipmentData = {};

      try {
          for (const team of selectedEvent.teams) {
              for (const member of team.roster) {
                  console.log('Fetching member equipment for id: ', member.id);
                  const memberEquipment = await fetchMemberEquipment(db, member.id);
                  if (memberEquipment) {
                      newEquipmentData[member.id] = memberEquipment;
                  } else {
                      const userSnap = await getDoc(doc(db, 'users', member.id));
                      const equipmentMap = userSnap.data()?.equipment;

                      console.log(`Member ID: ${member.id}, Equipment Map:`, equipmentMap);

                      if (equipmentMap && Object.keys(equipmentMap).length > 0) {
                        const firstKey = Object.keys(equipmentMap)[0];
                        const firstEquipment = equipmentMap[firstKey];

                        newEquipmentData[member.id] = firstEquipment 
                            ? `${firstEquipment['IDNumber'] || ''} - ${firstEquipment['Type'] || ''}`.trim()
                            : '';
                      } else {
                          newEquipmentData[member.id] = '';
                      }
                  }
              }
          }
      } catch (error) {
          console.error("Error fetching equipment data:", error);
      }

      setEquipmentData(newEquipmentData);
    };

    if (selectedEventId) {
        fetchAllEquipmentData();
    }
  }, [selectedEventId, events]);


    const handleEventChange = (event) => {
        setSelectedEventId(event.target.value);
    };

    const handleExportClick = async () => {
        const selectedEvent = events.find(event => event.id === selectedEventId);
        if (selectedEvent) {
            setIsExported(true);
            const exportUrl = `https://us-central1-mtv-smart-app.cloudfunctions.net/export_roster?eventName=${encodeURIComponent(selectedEvent.eventName)}`;
            try {
                const response = await fetch(exportUrl);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.text();
                console.log(data); // Log the response
    
                // If the export is successful, update the button status and show the Snackbar
                
                setSnackbarOpen(true);
            } catch (error) {
                console.error("Error during export:", error);
    
            }
        }
    };
    // Handle closing of Snackbar
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const selectedEvent = events.find(event => event.id === selectedEventId);

    const getRoleColor = (roleOrClassification) => {
        switch(roleOrClassification) {
          case 'Lineman':
            return <Tag color="lime">Lineman</Tag>;
          case 'Line Foreman':
            return <Tag color="magenta">Line Foreman</Tag>;
          case 'Line Apprentice':
            return <Tag color="red">Line Apprentice</Tag>;
          case 'Line Equipment Operator':
            return <Tag color="volcano">Line Equipment Operator</Tag>;
          case 'Line Groundman':
            return <Tag color="orange">Line Groundman</Tag>;
          case 'Line Cable Splicer':
            return <Tag color="gold">Line Cable Splicer</Tag>;
          case 'Working Foreman':
            return <Tag color="lime">Working Foreman</Tag>;
          case 'Service Crew Electrician':
            return <Tag color="green">Service Crew Electrician</Tag>;
          case 'Field Safety':
            return <Tag color="cyan">Field Safety</Tag>;
          case 'Field Support':
            return <Tag color="blue">Field Support</Tag>;
          case 'Flagger':
            return <Tag color="geekblue">Flagger</Tag>;
          case 'Line General Foreman':
            return <Tag color="magenta">Line General Foreman</Tag>;
          case 'Tree General Foreman':
            return <Tag color="red">Tree General Foreman</Tag>;
          case 'Vehicle Mechanic':
            return <Tag color="volcano">Vehicle Mechanic</Tag>;
          case 'Damage Assessor':
            return <Tag color="orange">Damage Assessor</Tag>;
          case 'Driver':
            return <Tag color="gold">Driver</Tag>;
          case 'Wires Down Guard':
            return <Tag color="lime">Wires Down Guard</Tag>;
          case 'Tree Climber':
            return <Tag color="green">Tree Climber</Tag>;
          case 'Tree Foreman':
            return <Tag color="cyan">Tree Foreman</Tag>;
          case 'Tree Groundman':
            return <Tag color="blue">Tree Groundman</Tag>;
          default:
            return <Tag color="geekblue">{roleOrClassification}</Tag>;
        }
      };
      const getCrewTypeColor = (crewType) => {
        switch(crewType) {
          case 'Contractor Line':
            return <Tag color="#E57373">Contractor Line</Tag>;
          case 'Contractor Service':
            return <Tag color="#81C784">Contractor Service</Tag>;
          case 'Damage Appraiser':
            return <Tag color="#08d456">Damage Appraiser</Tag>;
          case 'Wire Down':
            return <Tag color="#8930fc">Wire Down</Tag>;
          case 'Sub-Contractors':
            return <Tag color="#BA68C8">Sub-Contractors</Tag>;
          case 'Transmission':
            return <Tag color="#BA68C8">Transmission</Tag>;
          case 'Contractor Tree':
            return <Tag color="#BA68C8">Contractor Tree</Tag>;
          case 'Contractor Support':
            return <Tag color="#BA68C8">Contractor Support</Tag>;
          default:
            return <Tag color="geekblue">{crewType}</Tag>;
        }
      };

    return (
        <div>
            <Typography variant="h6" style={{ marginTop: 20, marginBottom: 10, fontFamily: 'Monospace', fontWeight: 'bold', color: '#1976d2' }}>Active Roster View</Typography>
            <FormControl fullWidth style={{ marginBottom: 20 }}>
                <InputLabel id="event-select-label">Select Event</InputLabel>
                <Select
                    labelId="event-select-label"
                    id="event-select"
                    value={selectedEventId}
                    label="Select Event"
                    onChange={handleEventChange}
                >
                    {events.map(event => (
                        <MenuItem key={event.id} value={event.id}>
                            {`${event.eventName} - ${event.utilityName} - ${event.eventDate}`}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {selectedEventId && (
                <Box display="flex" flexDirection="column" mb={2} alignItems="left">
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleExportClick}
                        disabled={isExported}
                        startIcon={isExported ? <CheckCircleOutlineIcon /> : null}
                        sx={{ marginBottom: 2, width: 'auto', maxWidth: '300px' }} // Adjusted button width
                    >
                        {isExported ? 'Exported' : 'Export Draft Roster - Eversource'}
                    </Button>
                </Box>
            )}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message="Roster exported successfully!"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} // Position the Snackbar
            />
            {selectedEvent && (
                <div>
                    <Typography variant="h6" style={{ marginTop: 20, fontFamily: 'Monospace', fontWeight: 'bold', color: '#1976d2' }}>Event Details</Typography>
                    <Typography><strong>Date:</strong> {selectedEvent.eventDate}</Typography>
                    <Typography><strong>Event Name:</strong> {selectedEvent.eventName}</Typography>
                    <Typography><strong>Location:</strong> {selectedEvent.location}</Typography>
                    <Typography><strong>Status:</strong> {selectedEvent.status}</Typography>
                    <Typography><strong>Storm Work Order:</strong> {selectedEvent.stormWorkOrder}</Typography>

                    
                    <br/>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>First Name</TableCell>
                                    <TableCell>Last Name</TableCell>
                                    <TableCell>Member Type</TableCell>
                                    <TableCell>Crew Type</TableCell>
                                    <TableCell>Equipment</TableCell>
                                    <TableCell>Gender</TableCell>
                                    <TableCell>Lodging Needed</TableCell>
                                    <TableCell>Lodging Type</TableCell>
                                    <TableCell>Personal Vehicle</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
    {selectedEvent.teams.flatMap((team, teamIndex) => 
        team.roster.map((member, memberIndex) => {
            const uniqueKey = `team-${teamIndex}-member-${memberIndex}`;
            return (
                <TableRow key={uniqueKey}>
                    <TableCell>{member.firstName}</TableCell>
                    <TableCell>{member.lastName}</TableCell>
                    <TableCell>{getRoleColor(member.role)}</TableCell>
                    <TableCell>{getCrewTypeColor(member.crewType)}</TableCell>
                    <TableCell>{equipmentData[member.id] || 'None'}</TableCell>
                    <TableCell>{member.gender}</TableCell>
                    <TableCell>{member.lodgingNeeded ? 'Yes' : 'No'}</TableCell>
                    <TableCell>{member.lodgingType}</TableCell>
                    <TableCell>{member.personalVehicle ? 'Yes' : 'No'}</TableCell>
                </TableRow>
            );
        })
    )}
</TableBody>
</Table>
</TableContainer>
</div>
)}
</div>
);
};

export default RosterManager;

