import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, getDoc, query, where } from "firebase/firestore";
import { firestore } from '../firebase'; 
import {FormControl, InputLabel, Box, CircularProgress, Button, Container, Select, IconButton, MenuItem, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { Tag } from 'antd';
import ActionDialog from '../components/ActionDialog';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';

const useTeams = () => {
  const [teams, setTeams] = useState([]); 
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const stormEventsSnapshot = await getDocs(collection(firestore, 'stormEvents'));
        const teamNames = [];

        stormEventsSnapshot.forEach((doc) => {
          const stormEvent = doc.data();

          if (stormEvent.status === 'active') {
            stormEvent.teams.forEach((team) => {
              if (!teamNames.includes(team.teamName)) {
                teamNames.push(team.teamName);
              }
            });
          }
        });

        setTeams(teamNames);
      } catch (error) {
        console.error("Error fetching team names: ", error);
      } finally {
        setLoading(false);
      }
    }

    fetchTeams();
  }, []);

  return {teams, loading};
}

const useMembers = (selectedTeam) => {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    const fetchMembers = async (teamName) => {
      const stormEventsSnapshot = await getDocs(collection(firestore, 'stormEvents'));

      stormEventsSnapshot.forEach((doc) => {
        const stormEvent = doc.data();
        stormEvent.teams.forEach((team) => {
          if (team.teamName === teamName) {
            setMembers(team.roster); // Assuming each team has a 'roster' field that is an array of members
          }
        });
      });
    }

    if (selectedTeam) {
      fetchMembers(selectedTeam);
    }
  }, [selectedTeam]);

  return {members};
}

const useEquipment = () => {
  const [equipment, setEquipment] = useState([]);

 useEffect(() => {
    const fetchEquipment = async () => {
      try {
        const equipmentSnapshot = await getDocs(collection(firestore, 'equipment'));
        const equipmentList = [];

        equipmentSnapshot.forEach((doc) => {
          const equipmentData = doc.data();
          equipmentList.push({
            id: doc.id,
            name: `${equipmentData.IDNumber} - ${equipmentData.Make} ${equipmentData.Model}`,
            imageUrl: equipmentData.imageUrl,
            year: equipmentData.Year,
            type: equipmentData.Type,
            plate: equipmentData.Plate
          });
        });
 
        setEquipment(equipmentList);
      } catch (error) {
        console.error("Error fetching equipment: ", error);
      }
    }

    fetchEquipment();
  }, []);

  return {equipment};
}
const AssignEquipment = () => {

  const [selectedTeam, setSelectedTeam] = useState('');
  const [assignConfirm, setAssignConfirm] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState({});
  const [selectedEquipmentDetails, setSelectedEquipmentDetails] = useState({});  // New state variable
  const {teams, loading} = useTeams();
  const {members} = useMembers(selectedTeam);
  const {equipment} = useEquipment();
  const [assignedEquipmentDetails, setAssignedEquipmentDetails] = useState({});
  
  // State to control the action dialog visibility
const [openActionDialog, setOpenActionDialog] = useState(false);
// State to keep track of the selected member for actions
const [selectedMember, setSelectedMember] = useState(null);

  const theme = useTheme();

    // Function to clear the table
    const clearTable = () => {
      setSelectedTeam('');  // Reset the selected team
    };

  const handleTeamSelect = (e) => {
    setSelectedTeam(e.target.value);
  }

  const handleAssign = async (equipmentId, member) => {
    setAssignConfirm(true);
    const selectedEquip = equipment.find(equip => equip.id === equipmentId);

    setSelectedEquipment({
      ...selectedEquipment,
      [member.id]: equipmentId  
    });

    setSelectedEquipmentDetails({
      ...selectedEquipmentDetails,
      [member.id]: selectedEquip  
    });
  }


  const handleReassignment = async (equipmentId, newAssigneeId) => {
    let prevAssigneeId = null;
  
    for (const collectionName of ['users', 'members']) {
      const querySnapshot = await getDocs(query(collection(firestore, collectionName), where(`equipment.${equipmentId}`, '!=', null)));
      for (const doc of querySnapshot.docs) {
        if (doc.id !== newAssigneeId) {
          prevAssigneeId = doc.id; // Previous assignee found
  
          // Remove the equipment from the previous assignee
          const updatedEquipment = { ...doc.data().equipment };
          delete updatedEquipment[equipmentId];
          await updateDoc(doc.ref, { equipment: updatedEquipment });
  
          break;
        }
      }
  
      if (prevAssigneeId) break; // Break the outer loop if previous assignee is found
    }
  
    return prevAssigneeId;
  };

  const confirmAssign = async () => {
    const updatedAssignedEquipmentDetails = { ...assignedEquipmentDetails };
  
    for (const memberId in selectedEquipment) {
      const equipmentId = selectedEquipment[memberId];
      const member = members.find((member) => member.id === memberId);
  
      if (equipmentId && member) {
        try {
          const selectedEquip = equipment.find((equip) => equip.id === equipmentId);
          if (!selectedEquip || !selectedEquip.name) {
            console.error("Error: Selected equipment not found.");
            continue;
          }
  
          // Handle potential reassignment and get previous assignee ID
          const prevAssigneeId = await handleReassignment(equipmentId, memberId);
  
          // Extract Make, Model, and IDNumber from the name property
          const [IDNumber, MakeModel] = selectedEquip.name.split(" - ");
          const [Make, ...ModelParts] = MakeModel.split(" ");
          const Model = ModelParts.join(" ");
  
          // Updating the 'equipment' collection
          const equipmentRef = doc(firestore, "equipment", equipmentId);
          await updateDoc(equipmentRef, {
            Assigned: `${member.firstName} ${member.lastName}`,
          });
  
          // Prepare equipment data
          const equipmentData = {
            IDNumber: IDNumber,
            Make: Make,
            Model: Model,
            Plate: selectedEquip.plate,
            imageUrl: selectedEquip.imageUrl,
            Year: selectedEquip.year,
            Type: selectedEquip.type,
          };
  
          // Update both 'users' and 'members' collections
          for (const collectionName of ["users", "members"]) {
            const ref = doc(firestore, collectionName, memberId);
            const snap = await getDoc(ref);
  
            if (snap.exists()) {
              const data = snap.data();
              const updatedEquipment = data.equipment ? { ...data.equipment } : {};
              updatedEquipment[equipmentId] = equipmentData;
  
              await updateDoc(ref, {
                equipment: updatedEquipment,
              });
            } else {
              console.error(`Document (${memberId}) does not exist in ${collectionName}.`);
            }
          }
  
          // Update the 'stormEvents' collection
          const stormEventsQuery = query(
          collection(firestore, "stormEvents"),
          where("teams.roster.id", "==", memberId)
          );
        const querySnapshot = await getDocs(stormEventsQuery);
      querySnapshot.forEach((doc) => {
    const stormEvent = doc.data();
  stormEvent.teams.forEach((team) => {
    team.roster.forEach((rosteredMember) => {
      if (rosteredMember.id === memberId) {
        Object.entries(equipmentData).forEach(([key, value]) => {
          rosteredMember.equipment[key] = value;
        });
        // Write the updated stormEvent back to the database
        updateDoc(doc.ref, stormEvent).catch((error) => {
          console.error("Error while updating stormEvent document:", error);
        });
      }
    });
  });
});
        // Log reassignment (if occurred)
          if (prevAssigneeId) {
            console.log(`Equipment ${equipmentId} reassigned from ${prevAssigneeId} to ${memberId}.`);
            // Additional logic for handling reassignment can be added here
          }
  
          updatedAssignedEquipmentDetails[memberId] = selectedEquip;
  
        } catch (error) {
          console.error("Error in assignment process: ", error);
        }
      }
    }
  
    setAssignedEquipmentDetails(updatedAssignedEquipmentDetails);
    setAssignConfirm(false);
  };
  
  const cancelAssign = () => {
    setAssignConfirm(false);
    setSelectedEquipment({});
    setSelectedEquipmentDetails({});
  }

  if (loading) {
    return <CircularProgress style={{ color: '#4287f5' }} /> // Changed the color of the loading circle
  }

  // Function to handle action click for a member
const handleActionClick = (member) => {
  // Set state to open the dialog and pass the selected member
  setOpenActionDialog(true);
  setSelectedMember(member);
};

// Function to close the action dialog
const handleCloseActionDialog = () => {
  setOpenActionDialog(false);
};

// Function to remove a member from the team
const handleRemoveMember = async (member) => {
  console.log('handleRemoveMember called with member:', member);

  const stormEventRef = collection(firestore, 'stormEvents');
  const querySnapshot = await getDocs(stormEventRef);

  const filteredDocs = querySnapshot.docs.filter(doc => {
    const stormEvent = doc.data();
    return stormEvent.status === 'active' && stormEvent.teams.some(team => team.teamName === selectedTeam);
  });

  if (filteredDocs.length > 0) {
    console.log('Found storm event document for selected team');
    const stormEventDoc = filteredDocs[0];
    const stormEvent = stormEventDoc.data();

    const teams = stormEvent.teams.map((team) => {
      if (team.teamName === selectedTeam) {
        console.log('Processing team:', team.teamName);
        const updatedRoster = team.roster.filter((teamMember) => teamMember.id !== member.id);
        console.log('Updated roster after removal:', updatedRoster);
        return { ...team, roster: updatedRoster };
      }
      return team;
    });

    console.log('Updated teams array:', teams);
    await updateDoc(stormEventDoc.ref, { teams });
    console.log('Storm event document updated');
  } else {
    console.log('No storm event document found for the selected team');
  }

  handleCloseActionDialog();
};

// Function to reassign a member to another team
const handleReassignMember = async (member, newTeamName) => {
  console.log('handleReassignMember called with member:', member, 'and newTeamName:', newTeamName);

  const stormEventRef = collection(firestore, 'stormEvents');
  const querySnapshot = await getDocs(stormEventRef);

  let teamDoc = null;

  console.log('Fetching storm events from Firestore...');

  // Find the document containing both the old and new teams
  querySnapshot.forEach(doc => {
    const stormEvent = doc.data();
    console.log(`Processing storm event: ${doc.id}, Status: ${stormEvent.status}`);

    if (stormEvent.status === 'active' && stormEvent.teams.some(team => team.teamName === selectedTeam || team.teamName === newTeamName)) {
      teamDoc = doc;
      console.log('Team document found:', doc.id);
    }
  });

  if (!teamDoc) {
    console.error("Error: Team document not found. Selected Team:", selectedTeam, "New Team:", newTeamName);
    return;
  }

  // Update the team's roster within the same document
  let teams = teamDoc.data().teams;
  teams = teams.map(team => {
    if (team.teamName === selectedTeam) {
      // Remove the member from the old team
      return {...team, roster: team.roster.filter(teamMember => teamMember.id !== member.id)};
    } else if (team.teamName === newTeamName) {
      // Add the member to the new team
      return {...team, roster: [...team.roster, member]};
    }
    return team;
  });

  console.log('Updating team document:', teamDoc.id);
  await updateDoc(teamDoc.ref, { teams });

  console.log('Member reassigned successfully to new team:', newTeamName);
  handleCloseActionDialog();
};

  const getRoleColor = (roleOrClassification) => {
    switch(roleOrClassification) {
      case 'Lineman':
        return <Tag color="lime">Lineman</Tag>;
      case 'Line Foreman':
        return <Tag color="magenta">Line Foreman</Tag>;
      case 'Line Apprentice':
        return <Tag color="red">Line Apprentice</Tag>;
      case 'Line Equipment Operator':
        return <Tag color="volcano">Line Equipment Operator</Tag>;
      case 'Line Groundman':
        return <Tag color="orange">Line Groundman</Tag>;
      case 'Line Cable Splicer':
        return <Tag color="gold">Line Cable Splicer</Tag>;
      case 'Working Foreman':
        return <Tag color="lime">Working Foreman</Tag>;
      case 'Service Crew Electrician':
        return <Tag color="green">Service Crew Electrician</Tag>;
      case 'Field Safety':
        return <Tag color="cyan">Field Safety</Tag>;
      case 'Field Support':
        return <Tag color="blue">Field Support</Tag>;
      case 'Flagger':
        return <Tag color="geekblue">Flagger</Tag>;
      case 'Line General Foreman':
        return <Tag color="magenta">Line General Foreman</Tag>;
      case 'Tree General Foreman':
        return <Tag color="red">Tree General Foreman</Tag>;
      case 'Vehicle Mechanic':
        return <Tag color="volcano">Vehicle Mechanic</Tag>;
      case 'Damage Assessor':
        return <Tag color="orange">Damage Assessor</Tag>;
      case 'Driver':
        return <Tag color="gold">Driver</Tag>;
      case 'Wires Down Guard':
        return <Tag color="lime">Wires Down Guard</Tag>;
      case 'Tree Climber':
        return <Tag color="green">Tree Climber</Tag>;
      case 'Tree Foreman':
        return <Tag color="cyan">Tree Foreman</Tag>;
      case 'Tree Groundman':
        return <Tag color="blue">Tree Groundman</Tag>;
      default:
        return <Tag color="geekblue">{roleOrClassification}</Tag>;
    }
  };
  const getCrewTypeColor = (crewType) => {
    switch(crewType) {
      case 'Contractor Line':
        return <Tag color="#E57373">Contractor Line</Tag>;
      case 'Contractor Service':
        return <Tag color="#81C784">Contractor Service</Tag>;
      case 'Damage Appraiser':
        return <Tag color="#08d456">Damage Appraiser</Tag>;
      case 'Wire Down':
        return <Tag color="#8930fc">Wire Down</Tag>;
      case 'Sub-Contractors':
        return <Tag color="#BA68C8">Sub-Contractors</Tag>;
      case 'Transmission':
        return <Tag color="#BA68C8">Transmission</Tag>;
      case 'Contractor Tree':
        return <Tag color="#BA68C8">Contractor Tree</Tag>;
      case 'Contractor Support':
        return <Tag color="#BA68C8">Contractor Support</Tag>;
      default:
        return <Tag color="geekblue">{crewType}</Tag>;
    }
  };
  const cellStyles = {
    firstName: { width: '5%' },
    lastName: { width: '5%' },
    crewType: { width: '5%' },
    memberType: { width: '5%' },
    gender: { width: '5%' },
    lodgingNeeded: { width: '10%' },
    lodgingType: { width: '10%' },
    personalVehicle: { width: '5%' },
    assignEquipment: { width: '10%' },
    actions: { width: '10%', padding: '0', textAlign: 'center' },
  };

  return (
    <Box style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.secondary }}>
      <Container >
        <Box textAlign="center" mt={2} mb={2}>
          <Typography variant="h6" color="secondary">
            Crew Management  
          </Typography>
        </Box>
        <FormControl variant="outlined" fullWidth sx={{ width: '100%' }}>
          <InputLabel id="active-team-select-label">Active Crews</InputLabel>
          <Select 
            value={selectedTeam}
            onChange={handleTeamSelect}
            labelId="active-team-select-label"
            label="Select an Active Crew"
            fullWidth
            variant="outlined"
            mb={5}
            sx={{ width: '100%' }}
          >
            <MenuItem value="">
              <em>Only crews from an active storm event are displayed</em>
            </MenuItem>

            {teams.map((teamName, index) => (
              <MenuItem key={index} value={teamName}>
                {teamName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
<Box padding={1}>
  <Button variant="outlined" color="secondary" onClick={clearTable}>
    Clear Table
  </Button>
</Box>
       {selectedTeam && (
        <Paper
        elevation={0}
        variant="outlined"
        square
        sx={{
          backgroundColor: 'secondary',
          color: 'secondary',
          width: 'fit-content',
          height: 'auto',
          border: '1px solid #ccc',
          boxShadow: '1px 4px 8px rgba(0, 0, 0, 0.2)',
          borderRadius: '4px',
        }}
      >
          <Table size="small" sx={{ margin: 'auto', color: 'secondary' }}>
            <TableHead>
              <TableRow>
                <TableCell style={cellStyles.firstName}>First Name</TableCell>
                <TableCell style={cellStyles.lastName}>Last Name</TableCell>
                <TableCell style={cellStyles.crewType}>Crew Type</TableCell>
                <TableCell style={cellStyles.memberType}>Member Type</TableCell>
                <TableCell style={cellStyles.gender}>Gender</TableCell>
                <TableCell style={cellStyles.lodgingNeeded}>Lodging Needed</TableCell>
                <TableCell style={cellStyles.lodgingType}>Lodging Type</TableCell>
                <TableCell style={cellStyles.personalVehicle}>Personal Vehicle</TableCell>
                <TableCell style={cellStyles.assignEquipment}>Assign Equipment</TableCell>
                <TableCell style={cellStyles.actions}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {members.map((member, index) => (
                <TableRow key={index}>
                  <TableCell>{member.firstName}</TableCell>
                  <TableCell>{member.lastName}</TableCell>
                  <TableCell>{getCrewTypeColor(member.crewType)}</TableCell>
                  <TableCell>{getRoleColor(member.classification || member.role)}</TableCell>
                  <TableCell>{member.gender}</TableCell>
                  <TableCell>{member.lodgingNeeded ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{member.lodgingType}</TableCell>
                  <TableCell>{member.personalVehicle ? 'Yes' : 'No'}</TableCell>
                  <TableCell>
                    <FormControl fullWidth >
                      <InputLabel id={`select-label-${member.id}`}>
                      
                      </InputLabel>
                      <Select
                        labelId={`select-label-${member.id}`}
                        id={`simple-select-${member.id}`}
                        value={selectedEquipment[member.id] || ""}
                        onChange={e => handleAssign(e.target.value, member)}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              width: '200px',
                            },
                          },
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {equipment.map((equip, i) => (
                          <MenuItem key={i} value={equip.id}>
                            {equip.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {(selectedEquipmentDetails[member.id] || assignedEquipmentDetails[member.id]) && (
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '2px', marginTop: '5px' }}>
                        <Typography variant="caption" style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                          <b>ID:</b> {(selectedEquipmentDetails[member.id] || assignedEquipmentDetails[member.id]).name.split(' - ')[0].split(' ')[0]}
                        </Typography>
                        <Typography variant="caption" style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                          <b>Make:</b> {(selectedEquipmentDetails[member.id] || assignedEquipmentDetails[member.id]).name.split(' - ')[1].split(' ')[0]}
                        </Typography>
                        <Typography variant="caption" style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                          <b>Model:</b> {(selectedEquipmentDetails[member.id] || assignedEquipmentDetails[member.id]).name.split(' - ')[1].split(' ').slice(1).join(' ')}
                        </Typography>
                        <Typography variant="caption" style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                          <b>Year:</b> {(selectedEquipmentDetails[member.id] || assignedEquipmentDetails[member.id]).year}
                        </Typography>
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="secondary"
                      size="small"
                      onClick={() => handleActionClick(member)}
                    >
                      <AssignmentLateIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}

      {/* Confirmation modal */}
      {assignConfirm && (
        <ConfirmDialog
          onConfirm={confirmAssign}
          onCancel={cancelAssign}
        />
      )}
  {/* Action Dialog */}
  <ActionDialog
    open={openActionDialog}
    onClose={handleCloseActionDialog}
    member={selectedMember}
    onRemove={handleRemoveMember}
    onReassign={handleReassignMember}
    teams={teams}
  />
    </Container>
  </Box>
);
}
function ConfirmDialog({ onConfirm, onCancel }) {
  return (
    <Dialog open onClose={onCancel}>
      <DialogTitle>Confirm Assignment</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to make this assignment?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary">
          <Close /> Cancel
        </Button>
        <Button onClick={onConfirm} color="secondary">
          <Check /> Confirm
        </Button> 
      </DialogActions>
    </Dialog>
    
  )
}
export default AssignEquipment;