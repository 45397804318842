import React, { useState, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import dayjs from 'dayjs';
import '../Timesheet.css';

// Import the addData function
import { addData } from '../firebase';

const Timesheet = () => {
  const [date, setDate] = useState(new Date());
  const [employeeName, setEmployeeName] = useState('');
  const [classification, setClassification] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [totalHours, setTotalHours] = useState(0);
  const [supervisorSignature, setSupervisorSignature] = useState('');
  const employeeSigCanvas = useRef(null);
  const [employeeSignature, setEmployeeSignature] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const timesheetData = {
      date: date.toISOString(),
      employeeName,
      classification,
      startTime,
      endTime,
      totalHours: parseFloat(totalHours),
      employeeSignature,
      supervisorSignature,
    };

    try {
      const docId = await addData('timesheets', timesheetData);
      console.log(`Form submitted and saved with doc ID: ${docId}`);
    } catch (error) {
      console.error('Error saving form data:', error);
    }
  };

  const clearEmployeeSignature = () => {
    if (employeeSigCanvas.current) {
      employeeSigCanvas.current.clear();
    }
  };

  const calculateHours = () => {
    const start = dayjs(startTime, 'HH:mm');
    const end = dayjs(endTime, 'HH:mm');
    const diffInMinutes = end.diff(start, 'minute');
    const diffInHours = diffInMinutes / 60;
    setTotalHours(diffInHours.toFixed(2));
  };

  return (
    <Box
    minHeight="30vh"
    display="flex"
    flexDirection="column"
    justifyContent="space-between"
    
  >
    
      <Box flexGrow={1}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Container maxWidth="sm">
          <Box textAlign="center" mt={8} mb={4}>

              <AccessAlarmIcon
                sx={{
                  fontSize: '2rem',
                  color: 'primary.main',
                }}
              />
              <Typography
                variant="h4"
                component="h1"
                textAlign="center"
                sx={{
                  color: 'secondary.main',
                }}
                gutterBottom
              >
                Daily Timesheet
              </Typography>
            </Box>
            <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center" style={{ padding: '0 16px' }}>
              <Grid item xs={12} className="grid-item">
                <TextField              
                  type="date"
                  required style={{ width: '33%' }} />
              </Grid>
              <Grid item xs={12} className="grid-item">
              <DateTimePicker
    label="Work hours"
    inputFormat="hh:mm:ss"
    value={date}
    onChange={(newValue) => setDate(newValue)}
    renderInput={(params) => <TextField {...params} fullWidth />}
  />
</Grid>
<Grid item xs={12} className="grid-item">
  <TextField
      label="Employee Name"
      value={employeeName}
      onChange={(e) => setEmployeeName(e.target.value)}
      fullWidth
              />
</Grid>
  <Grid item xs={12} className="grid-item" >
        <FormControl fullWidth>
        <InputLabel>Classification</InputLabel>
        <Select
         value={classification}
        onChange={(e) => setClassification(e.target.value)}
        >
  <MenuItem value="Admin">Admin</MenuItem>
       <MenuItem value="Contractor">Contractor</MenuItem>
        <MenuItem value="Other">Other</MenuItem>
         </Select>
          </FormControl>
        </Grid>
<Grid item xs={6} className="grid-item" >
<TextField
label="Start Time"
type="time"
value={startTime}
onChange={(e) => setStartTime(e.target.value)}
InputLabelProps={{
 shrink: true,
}}
fullWidth
/>
</Grid>
  <Grid item xs={6} className="grid-item">
  <TextField
  label="End Time"
  type="time"
  value={endTime}
  onChange={(e) => setEndTime(e.target.value)}
  InputLabelProps={{
  shrink: true,
}}
fullWidth
/>
</Grid>
<Grid item xs={12}className="grid-item" >
<Button onClick={calculateHours} variant="contained">
Calculate Total Hours
</Button>
</Grid>
<Grid item xs={12} className="grid-item">
<TextField
label="Total Hours"
value={totalHours}
InputProps={{
readOnly: true,
}}
fullWidth
/>
</Grid>

{/* Employee Signature */}
<Grid item xs={12} className="grid-item">
  <Box display="flex" flexDirection="column" justifyContent="flex-start">
    <h6>Employee Signature:</h6>
    <SignatureCanvas
      ref={employeeSigCanvas}
      onEnd={() => setEmployeeSignature(employeeSigCanvas.current.toDataURL())}

      canvasProps={{
        width: 500,
        height: 100,
        className: 'sigCanvas',
      }}
    />
  </Box>
</Grid>
<Grid item xs={12} className="grid-item">
  <Box display="flex" justifyContent="space-between">
    <Button
      onClick={clearEmployeeSignature}
      variant="contained"
      color="error"
    >
      Clear Signature
    </Button>
  </Box>
</Grid>

</Grid> 

<Grid item xs={12} className="grid-item">
  <TextField
    label="Supervisor Name"
    value={supervisorSignature}
    onChange={(e) => setSupervisorSignature(e.target.value)}
    fullWidth
  />
</Grid>

<Grid item xs={12} className="grid-item">
  <Box display="flex" justifyContent="center" mt={4}>
    <Button
      type="submit"
      variant="contained"
      color="primary"
      size="large"
      startIcon={<SaveIcon />}
    >
      Save
    </Button>
  </Box>
</Grid>

</form>
</Container>
</LocalizationProvider>
</Box>

</Box>
);
};

export default Timesheet;
