import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, DoughnutController, ArcElement } from 'chart.js';
import { getDocs, collection } from "firebase/firestore";
import { firestore } from '../firebase';
import { Typography } from '@mui/material';  // Import Typography for displaying text

// Register the required components for Doughnut chart
Chart.register(DoughnutController, ArcElement);

const ActivePersonnelChart = () => {
  const [rosterData, setRosterData] = useState({});
  const [totalPersonnel, setTotalPersonnel] = useState(0);  // New state variable for total personnel

  const fetchData = async () => {
    const querySnapshot = await getDocs(collection(firestore, "stormEvents"));
    const crewTypeCounts = {};
    let total = 0;  // Variable to keep track of total personnel
    const uniqueIds = new Set(); // Keep track of unique 'id's
  
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.status === "active") {  
        if (data.teams) {
          data.teams.forEach(team => {
            if (team.roster) {
              team.roster.forEach(member => {
                if (member.id && !uniqueIds.has(member.id)) {  // Check if id is unique and exists
                  uniqueIds.add(member.id);  // Add the id to the set
                  total++;  // Increment the total count for each unique member
                }
                if (member.crewType) { 
                  const crewType = member.crewType;
                  crewTypeCounts[crewType] = (crewTypeCounts[crewType] || 0) + 1;
                }
              });
            }
          });
        }
      }
    });
    
    setTotalPersonnel(total);  // Set the total personnel count based on unique 'id'
    setRosterData(crewTypeCounts);  // Set the roster data based on 'crewType'
  };
  
  
  
  
  useEffect(() => {
    fetchData();
  }, []);

  const chartData = {
    labels: Object.keys(rosterData),
    datasets: [
      {
        data: Object.values(rosterData),
        backgroundColor: [
          'rgba(33, 150, 243, 0.5)',
          'rgba(30, 136, 229, 0.5)',
          'rgba(25, 118, 210, 0.5)',
          'rgba(21, 101, 192, 0.5)',
          'rgba(100, 181, 246, 0.5)',
          'rgba(144, 202, 249, 0.5)'
        ],
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        position: 'top',  // Keeps the legend above the chart
        align: 'start',  // Aligns the legend items to the start (left for 'top' and 'bottom' positions)
        border: '1px solid #fffff',  // Adds a border around the legend
        labels: {
          boxWidth: 50,  // Reduces the box width
          font: {
            size: 10,  // Increases the font size
          },
          padding: 9,  // Adds padding around each item
          
        },
      },
    },
  };
  
  
  
  return (
    <div>
      <Doughnut data={chartData} options={chartOptions} />
      <Typography variant="subtitle2" style={{ fontWeight: 'bold', padding: '5px' }}>Total Personnel: {totalPersonnel}</Typography>
    </div>
  );
  
};

export default ActivePersonnelChart;
