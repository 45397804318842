import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  Box,
} from '@mui/material';

const alertKeywords = ['storm', 'hurricane', 'tornado', 'blizzard', 'wind', 'snow', 'ice', 'rain', 'thunderstorm', 'tropical', 'flooding', 'flood', 'tsunami', 'earthquake',];
const eastCoastStates = ['FL', 'GA','NJ', 'NY', 'CT', 'RI', 'MA', 'NH', 'ME', 'VA', 'NC', 'SC'];

const NoaaStormData = () => {
  const [alerts, setAlerts] = useState([]);
  const [forecast, setForecast] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch NOAA alerts
    const fetchAlerts = async () => {
      let allAlerts = [];
      for (const state of eastCoastStates) {
        const response = await axios.get(`https://api.weather.gov/alerts/active?area=${state}`, {
          headers: { 'User-Agent': 'myweatherapp.com, contact@myweatherapp.com' },
        });
        const relevantAlerts = response.data.features.filter((alert) =>
          alertKeywords.some((keyword) => alert.properties.event.toLowerCase().includes(keyword.toLowerCase()))
        );
        allAlerts = [...allAlerts, ...relevantAlerts];
      }
      setAlerts(allAlerts);
    };
  
    // Fetch NOAA forecast data for a sample location (latitude: 39.7456, longitude: -97.0892)
    const fetchForecast = async () => {
      const pointResponse = await axios.get('https://api.weather.gov/points/44.20333252,-70.3038537846', {
        headers: { 'User-Agent': 'myweatherapp.com, contact@myweatherapp.com' },
      });
      const forecastUrl = pointResponse.data.properties.forecast;
      const forecastResponse = await axios.get(forecastUrl, {
        headers: { 'User-Agent': 'myweatherapp.com, contact@myweatherapp.com' },
      });
      setForecast(forecastResponse.data.properties.periods);
    };
  
    // Fetch all the data
    Promise.all([fetchAlerts(), fetchForecast()]).then(() => {
      setLoading(false);
    });
  }, []);
  
  return (
    <Container>
      <Box sx={{ my: 2 }}>
        {loading ? (
          <Typography variant="h6">Loading...</Typography>
        ) : (
          <>
            <Typography variant="h4" gutterBottom>Weather Alerts</Typography>
            <Grid container spacing={1}>
              {alerts.map((alert, index) => (
                <Grid item xs={6} sm={6} md={4} key={index}>
                  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', margin: 1 }}>
                    <CardHeader title={alert.properties.headline} />
                    <CardContent sx={{ flexGrow: 1, padding: 2 }}>
                      <Typography variant="body2" paragraph>Area: {alert.properties.areaDesc}</Typography>
                      <Typography variant="body2" paragraph>Severity: {alert.properties.severity}</Typography>
                      <Typography variant="body2" paragraph>Effective Time: {alert.properties.effective}</Typography>
                      <Typography variant="body2" paragraph>Urgency: {alert.properties.urgency}</Typography>
                      <Typography variant="body2" paragraph>Alert: {alert.properties.senderName}</Typography>
                      <Typography variant="body2" paragraph>Info: {alert.properties.description}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>

            <Typography variant="h6" gutterBottom style={{ marginTop: '2rem' }}>
              NOAA Forecast New England
            </Typography>
            <Grid container spacing={1}>
              {forecast.slice(0, 8).map((period, index) => (
                <Grid item xs={6} sm={6} md={3} key={index}>
                  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', margin: 1 }}>
                    <CardHeader title={period.name} />
                    <CardContent sx={{ flexGrow: 1, padding: 2 }}>
                      <Typography variant="body2" paragraph>Temperature: {period.temperature} {period.temperatureUnit}</Typography>
                      <Typography variant="body2" paragraph>Wind: {period.windSpeed} {period.windDirection}</Typography>
                      <Typography variant="body2" paragraph>Short Forecast: {period.shortForecast}</Typography>
                      
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Box>
    </Container>
  );
};

export default NoaaStormData;