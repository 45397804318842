import React from 'react';
import { Box } from '@mui/material';

function PowerOutageChart() {
    return (
        <Box>
            <a href="https://poweroutage.report/" title="United States Power Outage Statistics">
                <img 
                    src="https://poweroutage.report/img/united-states-power-outage-statistics.png" 
                    alt="United States Power Outage Statistics" 
                    style={{ width: '100%', height: 'auto', maxWidth: '960px' }}
                />
            </a>
            <br/>
            More Power Outage Statistics at 
            <a href="https://poweroutage.report/">PowerOutage.Report</a>
        </Box>
    );
}

export default PowerOutageChart;
