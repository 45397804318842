import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const ActionDialog = ({ open, onClose, member, onRemove, onReassign, teams }) => {
  const [newTeamId, setNewTeamId] = useState('');

  // Handle the removal of the member
  const handleRemove = () => {
    onRemove(member);
    onClose();
  };

  // Handle the reassignment of the member
  const handleReassign = () => {
    if (newTeamId) {
      onReassign(member, newTeamId);
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Action for {member?.firstName} {member?.lastName}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Choose an action for this member.
        </DialogContentText>
        <FormControl fullWidth margin="normal">
          <InputLabel id="team-select-label">Reassign to Team</InputLabel>
          <Select
            labelId="team-select-label"
            value={newTeamId}
            onChange={(e) => setNewTeamId(e.target.value)}
            displayEmpty
          >
            <MenuItem value="">
              <em></em>
            </MenuItem>
            {teams.map((teamName) => (
              <MenuItem key={teamName} value={teamName}>{teamName}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRemove} color="error" variant="outlined"> 
          Remove Member
        </Button>
        <Button onClick={handleReassign} color="secondary" disabled={!newTeamId}>
          Reassign Member
        </Button>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActionDialog;