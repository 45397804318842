import React, { useEffect } from 'react';
import { Button, Box, Grid, Container, Typography } from '@mui/material';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';  // Import the icon for the new button
import TimesheetManager from './TimesheetManager';  // Importing TimesheetManager
import FolderIcon from '@mui/icons-material/Folder';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import RosterManager from '../components/RosterManager';
import { useNavigate } from 'react-router-dom';

const AdminPanel = () => {
  // Define state variables if any
  
  // Using react-router-dom for navigation
  const navigate = useNavigate();

  // Fetch data from Firestore or other APIs
  useEffect(() => {
    // Fetch logic here
  }, []);

  return (
    <Container sx={{ padding: '5px', margin: '5px' }}>
      {/* Admin Panel title */}
      <Grid container justifyContent="center">
        <Typography variant="h4" style={{ marginTop: 20, marginBottom: 10, fontFamily: 'Monospace', fontWeight: 'bold', color: '#1976d2' }}>
          Admin Panel
        </Typography>
      </Grid>

      <Grid container spacing={3} justifyContent="center">

      <Grid container spacing={3} justifyContent="center">

{/* Container for buttons */}
<Grid container item xs={12} justifyContent="center">
  <Box sx={{
    p: '3%', // shorthand for padding
    display: 'flex',
    flexWrap: 'wrap', // allows buttons to wrap in smaller viewports
    justifyContent: 'center',
  }}>
    {/* Office Timesheet Button */}
    <Button
      variant="contained"
      color="secondary"
      size="large"
      startIcon={<AccessAlarmIcon sx={{ fontSize: { xs: '20px', sm: '30px' } }} />} // Responsive icon size
      onClick={() => navigate('/timesheet')}
      sx={{
        m: 1, // shorthand for margin with responsive spacing
        minWidth: { xs: '150px', sm: '200px' }, // Responsive minimum width
        fontSize: { xs: '12px', sm: '16px' }, // Responsive font size
        fontWeight: '500'
      }}
    >
      Office Timesheet
    </Button>
    {/* Add Employee Button */}
    <Button
      variant="contained"
      color="secondary"
      size="large"
      startIcon={<PersonAddAlt1Icon sx={{ fontSize: { xs: '20px', sm: '30px' } }} />} // Responsive icon size
      onClick={() => navigate('/signup')}
      sx={{
        m: 1,
        minWidth: { xs: '150px', sm: '200px' },
        fontSize: { xs: '12px', sm: '16px' },
        fontWeight: '500'
      }}
    >
      Add Employee
    </Button>
    {/* New Add Employee Button */}
    <Button
      variant="contained"
      color="secondary"
      size="large"
      startIcon={<PersonAddAlt1Icon sx={{ fontSize: { xs: '20px', sm: '30px' } }} />} // Responsive icon size
      onClick={() => navigate('/add-employee')}
      sx={{
        m: 1,
        minWidth: { xs: '150px', sm: '200px' },
        fontSize: { xs: '12px', sm: '16px' },
        fontWeight: '500'
      }}
    >
      Add New Member
    </Button>
    {/* Timesheet Drive Button */}
    <Button
      variant="contained"
      color="secondary"
      size="large"
      startIcon={<FolderIcon sx={{ fontSize: { xs: '20px', sm: '30px' } }} />} // Responsive icon size
      component="a" // Use the Button as an anchor tag
      href="https://drive.google.com/drive/folders/1bkFZO9apfHWwvq98PH1pweKg5nwGpaRv?usp=sharing"
      target="_blank" // Open the link in a new tab
      rel="noopener noreferrer" // Prevents security vulnerabilities
      sx={{
        m: 1,
        minWidth: { xs: '150px', sm: '200px' },
        fontSize: { xs: '12px', sm: '16px' },
        fontWeight: '500'
      }}
    >
      Timesheet Drive
    </Button>
    {/* Roster Drive Button */}
    <Button
      variant="contained"
      color="secondary"
      size="large"
      startIcon={<FolderIcon sx={{ fontSize: { xs: '20px', sm: '30px' } }} />} // Responsive icon size
      component="a" // Use the Button as an anchor tag
      href="https://drive.google.com/drive/folders/1YE9fbZQxWAiheaDZ_YbtXNIWzIBWfooa"
      target="_blank" // Open the link in a new tab
      rel="noopener noreferrer" // Prevents security vulnerabilities
      sx={{
        m: 1,
        minWidth: { xs: '150px', sm: '200px' },
        fontSize: { xs: '12px', sm: '16px' },
        fontWeight: '500'
      }}
    >
      Roster Drive
    </Button>
  </Box>
</Grid>

</Grid>
{/* Roster Manager Section */}
<Grid item xs={12} sm={12} md={12} lg={12}>
   <Box sx={{ 
    padding: '5px', 
    border: '0px solid #ccc', 
    borderRadius: '10px', 
    margin: '10px'
          }}>
    <RosterManager />
      </Box>
    </Grid>
{/* TimesheetManager Section */}
  <Grid item xs={12} sm={12} md={12} lg={12}>
   <Box sx={{ 
    padding: '5px', 
    border: '0px solid #ccc', 
    borderRadius: '10px', 
    margin: '10px'
          }}>
    <TimesheetManager />
      </Box>
    </Grid>



      </Grid>
    </Container>
  );
}

export default AdminPanel;


