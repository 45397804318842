import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, TextField, Button } from '@mui/material';
import { getFirestore, collection, query, onSnapshot, doc, updateDoc } from 'firebase/firestore';

const EquipmentTable = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [editMode, setEditMode] = useState({});
  const [editedData, setEditedData] = useState({});

  useEffect(() => {
    const db = getFirestore();
    const equipmentQuery = query(collection(db, 'equipment'));
    onSnapshot(equipmentQuery, (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSearchResults(data);
    });
  }, []);

  const handleEditToggle = (equipmentId) => {
    setEditMode((prev) => ({ ...prev, [equipmentId]: !prev[equipmentId] }));
    if (!editMode[equipmentId]) {
      setEditedData((prev) => ({ ...prev, [equipmentId]: searchResults.find((item) => item.id === equipmentId) }));
    }
  };

  const handleEditChange = (equipmentId, field, event) => {
    const value = event.target.value;
    setEditedData((prev) => ({ ...prev, [equipmentId]: { ...prev[equipmentId], [field]: value } }));
  };

  const handleSave = async (equipmentId) => {
    const db = getFirestore();
    const equipmentRef = doc(db, 'equipment', equipmentId);
    await updateDoc(equipmentRef, editedData[equipmentId]);
    setEditMode((prev) => ({ ...prev, [equipmentId]: false }));
  };

  const keys = [
    'Make', 'Model', 'Year', 'Owner', 'Type', 'IDNumber', 'Plate', 'VIN', 'PurchaseDate', 'Renewal', 'Assigned', 'Location'
  ];

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 900, tableLayout: 'auto' }}>
        <TableHead>
          <TableRow>
            {keys.map(key => (
              <TableCell key={key} sx={{ fontWeight: "bold", position: "sticky", top: 0, backgroundColor: "primary", padding: '6px', fontSize: '0.875rem' }}>
                {key}
              </TableCell>
            ))}
            <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {searchResults.map((equipment) => {
            const isEditMode = editMode[equipment.id];
            return (
              <TableRow key={equipment.id}>
                {keys.map((key) => {
                  const cellValue = isEditMode ? editedData[equipment.id][key] : equipment[key];

                  return (
                    <TableCell key={key} sx={{ padding: '6px' }}>
                      {isEditMode ? (
                        key === 'Type' ? (
                          <Select
                            value={cellValue}
                            onChange={(e) => handleEditChange(equipment.id, key, e)}
                            fullWidth
                          >
            <MenuItem value="Eversource Provided Bucket">Eversource Provided Bucket</MenuItem>
            <MenuItem value="Bucket Truck">Bucket Truck</MenuItem>
            <MenuItem value="Digger/Derrick">Digger/Derrick</MenuItem>
            <MenuItem value="Pick Up">Pick Up</MenuItem>
            <MenuItem value="Transmission Bucket">Transmission Bucket</MenuItem>
            <MenuItem value="Car/Van/SUV">Car/Van/SUV</MenuItem>
            <MenuItem value="Dump Truck">Dump Truck</MenuItem>
            <MenuItem value="Mechanic Truck">Mechanic Truck</MenuItem>
            <MenuItem value="Off Road Digger">Off Road Digger</MenuItem>
            <MenuItem value="Off Road Other">Off Road Other</MenuItem>
            <MenuItem value="Other Equipment">Other Equipment</MenuItem>
            <MenuItem value="Trailer-Pole">Trailer-Pole</MenuItem>
            <MenuItem value="Backhoe">Backhoe</MenuItem>
            <MenuItem value="Off Road Bucket">Off Road Bucket</MenuItem>
            <MenuItem value="Dump Truck w/ Backhoe">Dump Truck w/ Backhoe</MenuItem>
            <MenuItem value="MS Van">MS Van</MenuItem>
            <MenuItem value="I&R Truck">I&R Truck</MenuItem>
                          </Select>
                        ) : (
                          <TextField
                            value={cellValue}
                            onChange={(e) => handleEditChange(equipment.id, key, e)}
                            fullWidth
                          />
                        )
                      ) : (
                        cellValue
                      )}
                    </TableCell>
                  );
                })}
                <TableCell sx={{ padding: '10px' }}>
                  {isEditMode ? (
                    <Button variant="contained" color="secondary" onClick={() => handleSave(equipment.id)}>Save</Button>
                  ) : (
                    <Button variant="contained" color="secondary" onClick={() => handleEditToggle(equipment.id)}>Edit</Button>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EquipmentTable;

