import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext';
import { getFirestore, collection, query, where, getDocs } from './firebase';

const AccessRoles = ({ roles, children }) => {
  const [userRole, setUserRole] = useState(null);
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();

  // Memoize db to ensure it's only created once
  const db = useMemo(() => getFirestore(), []);

  useEffect(() => {
    console.log("Current User:", currentUser);
    if (!currentUser) {
      navigate('/login');
      return;
    }

    // Create a query against the 'users' collection where 'uid' matches 'currentUser.uid'
    const usersCollectionRef = collection(db, 'users');
    const q = query(usersCollectionRef, where("uid", "==", currentUser.uid));

    // Execute the query
    getDocs(q).then(querySnapshot => {
      if (!querySnapshot.empty) {
        // Assuming 'uid' is unique, there should only be one match
        const userDoc = querySnapshot.docs[0];
        const { userAccessRole } = userDoc.data();
        setUserRole(userAccessRole);
      } else {
        console.log("No such document!");
      }
    }).catch(err => {
      console.log("Error fetching document:", err);
    });

    // No cleanup function is required here as getDocs does not set up a subscription
  }, [currentUser, navigate, db]);

  if (roles.includes(userRole)) {
    return <>{children}</>;
  } else {
    return <div></div>;
  }
};

export default AccessRoles;

