import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import '../DailySignIn.css';

import { getActiveStormEventsWithTeams,firestore, doc, collection  } from '../firebase';
import { setDoc } from 'firebase/firestore';

const DailySignIn = () => {
  const [date, setDate] = useState(new Date());
  const [name, setName] = useState('');
  const [homeLocal, setHomeLocal] = useState('');
  const [cellPhone, setCellPhone] = useState('');
  const [classification, setClassification] = useState('');
  const [checkInTime, setCheckInTime] = useState('');
  const [ableToSendReceiveTexts, setAbleToSendReceiveTexts] = useState('');
  const [teams, setTeams] = useState([]);
  const [team, setTeam] = useState('');
  const [setLatitude] = useState(null);
  const [setLongitude] = useState(null);


  useEffect(() => {
    getActiveStormEventsWithTeams()
      .then((activeTeams) => {
        setTeams(activeTeams);
      })
      .catch((err) => console.error('Error fetching teams:', err));
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const selectedTeam = teams.find((t) => t.teamName === team);
  
    if (
      !selectedTeam.roster.some(
        (user) => user.firstName + ' ' + user.lastName === name
      )
    ) {
      alert('You are not a member of this team.');
      return;
    }
  
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
  
          const formData = {
            date,
            name,
            homeLocal,
            cellPhone,
            classification,
            checkInTime,
            ableToSendReceiveTexts,
            team,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          const teamsCollectionRef = collection(firestore, 'dailysignin',);
          const teamDocRef = doc(teamsCollectionRef, team);
          
          await setDoc(teamDocRef, { 
            [name]: formData
          }, { merge: true });
  
          console.log('Form submitted');
          console.log('Latitude:', position.coords.latitude);
          console.log('Longitude:', position.coords.longitude);
          
          setDate('');
          setName('');
          setHomeLocal('');
          setCellPhone('');
          setClassification('');
          setCheckInTime('');
          setAbleToSendReceiveTexts('');
          setTeam('');

          console.log('Form submission complete and fields reset');
  
        },
        (error) => {
          console.error('Error obtaining user location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };
  return (
    <>
      
      <Box mt={4} mb={4}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={8} md={6} mb={4}>
            <Container maxWidth="sm" className="daily-signin-container">
              <Typography variant="h5" component="h5" textAlign="center" className="form-title" mb={2}>
                Daily Sign In
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                <Grid item xs={12} className="grid-item">
            <TextField
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />
          </Grid>
                <Grid item xs={12} className="grid-item">
            <TextField
              label=""
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              fullWidth
            />
          </Grid>
                  <Grid item xs={12} className="grid-item">
                    <FormControl fullWidth>
                      <InputLabel>Team</InputLabel>
                      <Select
                        value={team}
                        onChange={(e) => setTeam(e.target.value)}
                      >
                        {teams.map((team) => (
                          <MenuItem key={team.teamName} value={team.teamName}>
                            {team.teamName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className="grid-item">
            <TextField
              label="Home Local"
              value={homeLocal}
              onChange={(e) => setHomeLocal(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} className="grid-item">
            <TextField
              label="Cell Phone"
              value={cellPhone}
              onChange={(e) => setCellPhone(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} className="grid-item">
  <Typography variant="h6" component="h3">
    Are you able to send and receive text messages?
  </Typography>
  <Grid container justifyContent="center">
    <Grid item xs={12}>
      <FormControl component="fieldset">
        <RadioGroup
          row
          value={ableToSendReceiveTexts}
          onChange={(e) => setAbleToSendReceiveTexts(e.target.value)}
        >
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
    </Grid>
  </Grid>
</Grid>
          <Grid item xs={12} className="grid-item">
            <FormControl fullWidth>
              <InputLabel>Classification</InputLabel>
              <Select
                value={classification}
                onChange={(e) => setClassification(e.target.value)}
              >
                <MenuItem value="Foreman">Foreman</MenuItem>
                <MenuItem value="General Foreman">General Foreman</MenuItem>
                <MenuItem value="Wire Guard">Wire Guard</MenuItem>
                <MenuItem value="Safety">Safety</MenuItem>
                <MenuItem value="Support">Support</MenuItem>
                <MenuItem value="Lineman">Lineman</MenuItem>          
                <MenuItem value="Equiptment Operator">Equiptment Operator</MenuItem>
                <MenuItem value="Mechanic">Mechanic</MenuItem>         
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} className="grid-item" container alignItems="center" spacing={2}>
  <Grid item xs={6}>
    <TextField
      label="Check-In Time"
      type="time"
      value={checkInTime}
      onChange={(e) => setCheckInTime(e.target.value)}
      InputLabelProps={{
        shrink: true,
      }}
      fullWidth
    />
  </Grid>
  <Grid item xs={6}>
    <Button
      variant="contained"
      color="primary"
      onClick={() => {
        const currentTime = new Date();
        const hours = currentTime.getHours().toString().padStart(2, '0');
        const minutes = currentTime.getMinutes().toString().padStart(2, '0');
        setCheckInTime(`${hours}:${minutes}`);
      }}
    >
      Log Current Time
    </Button>
  </Grid>
</Grid>
      <Grid item xs={6}>
            
              <Button type="submit" variant="contained" color="primary" 
               className="submit-button">
                Submit
              </Button>
    
          </Grid>
                </Grid>
              </form>
            </Container>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DailySignIn;
