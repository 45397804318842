import React, { useState, useEffect } from 'react';
import { Table,
         TableBody, 
         TableRow, 
         TableCell, 
         TableHead, 
         TableContainer, 
         Box, 
         Typography, 
         Button,
         Dialog, 
         DialogTitle, 
         DialogContent, 
         List, 
         ListItem, 
         ListItemText, 
         ListItemButton, 
         DialogActions,
         Checkbox, 
         Select, 
         MenuItem
} from '@mui/material';
import { Tag } from 'antd';
import { 
  getFirestore, collection, query, onSnapshot, where, doc, updateDoc, getDoc, getDocs 
} from 'firebase/firestore';

const getRoleColor = (roleOrClassification) => {
  switch(roleOrClassification) {
    case 'Lineman':
      return <Tag color="lime">Lineman</Tag>;
    case 'Line Foreman':
      return <Tag color="magenta">Line Foreman</Tag>;
    case 'Line Apprentice':
      return <Tag color="red">Line Apprentice</Tag>;
    case 'Line Equipment Operator':
      return <Tag color="volcano">Line Equipment Operator</Tag>;
    case 'Line Groundman':
      return <Tag color="orange">Line Groundman</Tag>;
    case 'Line Cable Splicer':
      return <Tag color="gold">Line Cable Splicer</Tag>;
    case 'Working Foreman':
      return <Tag color="lime">Working Foreman</Tag>;
    case 'Service Crew Electrician':
      return <Tag color="green">Service Crew Electrician</Tag>;
    case 'Field Safety':
      return <Tag color="cyan">Field Safety</Tag>;
    case 'Field Support':
      return <Tag color="blue">Field Support</Tag>;
    case 'Flagger':
      return <Tag color="geekblue">Flagger</Tag>;
    case 'Line General Foreman':
      return <Tag color="magenta">Line General Foreman</Tag>;
    case 'Tree General Foreman':
      return <Tag color="red">Tree General Foreman</Tag>;
    case 'Vehicle Mechanic':
      return <Tag color="volcano">Vehicle Mechanic</Tag>;
    case 'Damage Assessor':
      return <Tag color="orange">Damage Assessor</Tag>;
    case 'Driver':
      return <Tag color="gold">Driver</Tag>;
    case 'Wires Down Guard':
      return <Tag color="lime">Wires Down Guard</Tag>;
    case 'Tree Climber':
      return <Tag color="green">Tree Climber</Tag>;
    case 'Tree Foreman':
      return <Tag color="cyan">Tree Foreman</Tag>;
    case 'Tree Groundman':
      return <Tag color="blue">Tree Groundman</Tag>;
    default:
      return <Tag color="geekblue">{roleOrClassification}</Tag>;
  }
};
const getCrewTypeColor = (crewType) => {
  switch(crewType) {
    case 'Contractor Line':
      return <Tag color="#E57373">Contractor Line</Tag>;
    case 'Contractor Service':
      return <Tag color="#81C784">Contractor Service</Tag>;
    case 'Damage Appraiser':
      return <Tag color="#08d456">Damage Appraiser</Tag>;
    case 'Wire Down':
      return <Tag color="#8930fc">Wire Down</Tag>;
    case 'Sub-Contractors':
      return <Tag color="#BA68C8">Sub-Contractors</Tag>;
    case 'Transmission':
      return <Tag color="#BA68C8">Transmission</Tag>;
    case 'Contractor Tree':
      return <Tag color="#BA68C8">Contractor Tree</Tag>;
    case 'Contractor Support':
      return <Tag color="#BA68C8">Contractor Support</Tag>;
    default:
      return <Tag color="geekblue">{crewType}</Tag>;
  }
};

const OnDeck = ({ selectedRows }) => {
  const [activeStormEvents, setActiveStormEvents] = useState([]);
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedMembers, setSelectedMembers] = useState(selectedRows || []);
  const [selectedMemberIds, setSelectedMemberIds] = useState([]);
  const [batchAssignTeam, setBatchAssignTeam] = useState('');
  const [selectAll, setSelectAll] = useState(false);



// Fetch active storm events from Firestore
useEffect(() => {
  const fetchActiveStormEvents = async () => {
    const db = getFirestore();
    const activeStormEventsQuery = query(collection(db, 'stormEvents'), where('status', '==', 'active'));
    onSnapshot(activeStormEventsQuery, (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setActiveStormEvents(data);
    });
  };
  fetchActiveStormEvents();
}, []);

// Handle Assign button click
const handleAssign = (user) => {
  setSelectedUser(user);
  setAssignDialogOpen(true);
};

// Handle team selection in the dialog
const handleTeamClick = (team) => {
  setSelectedTeam(team);
};



// Handle assigning user to the selected team
const handleAssignToTeam = async () => {
  if (!selectedTeam) {
    alert('Please select a team to assign the user to.');
    return;
  }

  const db = getFirestore();
  const activeStormEventsQuery = query(collection(db, 'stormEvents'), where('status', '==', 'active'));
  const querySnapshot = await getDocs(activeStormEventsQuery);
  const isUserAssignedToActiveEvent = querySnapshot.docs.some(doc => {
    const event = doc.data();
    return event.teams.some(team =>
      team.roster && team.roster.some(member => member.id === selectedUser.id)
    );
  });

  if (isUserAssignedToActiveEvent) {
    alert('User is already assigned to an active storm event.');
    return;
  }

  const stormEventRef = doc(db, 'stormEvents', selectedTeam.stormEventId);
  const stormEventSnap = await getDoc(stormEventRef);
  if (stormEventSnap.exists()) {
    const stormEventData = stormEventSnap.data();
    const team = stormEventData.teams.find(team => team.id === selectedTeam.id);
    if (team) {
      team.roster = team.roster ? [...team.roster, selectedUser] : [selectedUser];
    }
    await updateDoc(stormEventRef, {
      teams: stormEventData.teams,
    });
    alert('User assigned to the team successfully.');
    setAssignDialogOpen(false);
    setSelectedUser(null);
    setSelectedTeam(null);
  }
};

// Helper function to fetch member data
const fetchMemberData = async (db, memberId) => {
  const usersRef = collection(db, 'users');
  const membersRef = collection(db, 'members');

  const userDoc = await getDoc(doc(usersRef, memberId));
  if (userDoc.exists()) {
    return userDoc.data();
  }

  const memberDoc = await getDoc(doc(membersRef, memberId));
  if (memberDoc.exists()) {
    return memberDoc.data();
  }

  return null; // Member not found in both collections
};

const handleBatchAssign = async () => {
  if (!batchAssignTeam || selectedMemberIds.length === 0) {
    alert('Please select a team and members to assign.');
    return;
  }

  const db = getFirestore();
  let stormEventId;
  let team;

  // Find the storm event that contains the team
  for (const event of activeStormEvents) {
    const foundTeam = event.teams.find(t => t.id === batchAssignTeam);
    if (foundTeam) {
      stormEventId = event.id;
      team = foundTeam;
      break;
    }
  }

  if (!stormEventId || !team) {
    alert('The selected team does not exist in the active storm events.');
    return;
  }

  const stormEventRef = doc(db, 'stormEvents', stormEventId);
  const stormEventSnap = await getDoc(stormEventRef);

  if (!stormEventSnap.exists()) {
    alert('The selected storm event does not exist.');
    return;
  }

  const stormEventData = stormEventSnap.data();
  // Fetch all active storm events for checking existing member assignments
  const activeStormEventsQuery = query(collection(db, 'stormEvents'), where('status', '==', 'active'));
  const querySnapshot = await getDocs(activeStormEventsQuery);
  const allActiveStormEvents = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

  for (const memberId of selectedMemberIds) {
    const memberData = await fetchMemberData(db, memberId);
    if (!memberData) {
      alert(`Member with ID ${memberId} not found.`);
      continue;
    }

    // Check if the member is already assigned to any team in all active storm events
    const isAlreadyAssigned = allActiveStormEvents.some(event => 
      event.teams.some(t => t.roster && t.roster.some(member => member.id === memberId))
    );

    if (isAlreadyAssigned) {
      alert(`Member ${memberData.firstName} ${memberData.lastName} is already assigned to a team in an active storm event.`);
      return;
    }
  }

  // Proceed with the roster update
  let updatedRoster = [...team.roster];

  for (const memberId of selectedMemberIds) {
    const memberData = await fetchMemberData(db, memberId);
    if (memberData) {
      updatedRoster.push(memberData);
    }
  }

  // Update the team roster in the storm event
  const updatedTeams = stormEventData.teams.map(t => t.id === team.id ? { ...t, roster: updatedRoster } : t);

  await updateDoc(stormEventRef, {
    teams: updatedTeams,
  });

  alert('Members assigned to the team successfully.');

  // Reset state
  setSelectedMemberIds([]);
  setBatchAssignTeam('');
};

const handleSelectMember = (memberId) => {
  setSelectedMemberIds(prevIds => {
    if (prevIds.includes(memberId)) {
      return prevIds.filter(id => id !== memberId);
    } else {
      return [...prevIds, memberId];
    }
  });
  setSelectAll(selectedMemberIds.length + 1 === selectedMembers.length);
};

const handleSelectAll = () => {
  setSelectAll(!selectAll);
  if (!selectAll) {
    setSelectedMemberIds(selectedMembers.map(member => member.id));
  } else {
    setSelectedMemberIds([]);
  }
};



// Handle dialog close
const handleAssignDialogClose = () => {
  setAssignDialogOpen(false);
  setSelectedUser(null);
  setSelectedTeam(null);
};
  useEffect(() => {
    // Function to check if two members are equal based on their properties
    const isEqualMember = (member1, member2) => {
      return member1.firstName === member2.firstName && member1.lastName === member2.lastName;
    };

    setSelectedMembers(prevSelectedMembers => {
      // Find members to add and remove
      const membersToAdd = selectedRows.filter(row => !prevSelectedMembers.some(member => isEqualMember(row, member)));
      const membersToRemove = prevSelectedMembers.filter(member => !selectedRows.some(row => isEqualMember(row, member)));

      // Update selectedMembers if there are any changes
      if (membersToAdd.length > 0 || membersToRemove.length > 0) {
        return [...prevSelectedMembers, ...membersToAdd].filter(member => !membersToRemove.some(removed => isEqualMember(removed, member)));
      }
      return prevSelectedMembers;
    });

  }, [selectedRows]);  // Only selectedRows in dependency array

// Populate table rows
const tableRows = selectedMembers.map((member, index) => (
  <TableRow key={index}>
    
    <TableCell>
      <Checkbox
        checked={selectedMemberIds.includes(member.id)}
        onChange={() => handleSelectMember(member.id)}
        color="secondary" // Use secondary color for the Checkbox
      />
    </TableCell>
    <TableCell>{member.firstName}</TableCell>
    <TableCell>{member.lastName}</TableCell>
    <TableCell>{getCrewTypeColor(member.crewType)}</TableCell>
    <TableCell>{getRoleColor(member.classification ? member.classification : member.role)}</TableCell>
    <TableCell>
      <Button variant="outlined" color="secondary" onClick={() => handleAssign(member)}>
        Assign Team
      </Button>
    </TableCell>
  </TableRow>
));


  return (
    
    <Box border={2} borderColor="secondary" borderRadius={1}>
      <Typography variant="h6" component="div" align="center" color="secondary">
        On Deck Table
      </Typography>
      <TableContainer>
<Box sx={{ margin: 2, border: '1px solid', borderColor: 'secondary', borderRadius: 1, padding: 2 }}>
  <Select
    value={batchAssignTeam}
    onChange={(e) => setBatchAssignTeam(e.target.value)}
    displayEmpty
    fullWidth
    variant="outlined"
  >
    <MenuItem value="" disabled>Select a Crew for Batch Assignment</MenuItem>
    {activeStormEvents.map((event) => 
      event.teams.map((team) => (
        <MenuItem key={team.id} value={team.id}>{team.teamName}</MenuItem>
      ))
    )}
  </Select>
  <Box sx={{ textAlign: 'right', marginTop: 2 }}>
    <Button
      variant="contained"
      color="secondary"
      onClick={handleBatchAssign}
      disabled={!batchAssignTeam || selectedMemberIds.length === 0}
    >
      Batch Assign to Team
    </Button>
  </Box>
</Box>
     <Table>
          <TableHead>
            <TableRow>
            <TableCell padding="checkbox">
      <Checkbox
        color="secondary"
        indeterminate={selectedMemberIds.length > 0 && selectedMemberIds.length < selectedMembers.length}
        checked={selectAll}
        onChange={handleSelectAll}
      />
    </TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Crew Type</TableCell>
              <TableCell>Classification</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedMembers.length > 0 ? tableRows : null}
          </TableBody>
        </Table>
      </TableContainer>

{/* Assign Dialog */}
<Dialog open={assignDialogOpen} onClose={handleAssignDialogClose}>
  <DialogTitle>Assign {selectedUser && `${selectedUser.firstName} ${selectedUser.lastName}`} to a Team</DialogTitle>
  <DialogContent>
    <List>
      {activeStormEvents.map((event) => (
        <React.Fragment key={event.id}>
          <ListItem style={{ backgroundColor: "#258df5" }}>  {/* Darker background */}
          <ListItemText primary={<b style={{ color: '#fafbfc' }}>Storm Event: {event.eventName}</b>} />
          </ListItem>
          <hr />  {/* Line divider */}
          {event.teams && event.teams.map((team) => {
            const generalForeman = team.roster?.find((member) => member.userAccessRole === 'General Foreman') || {};
            return (
              <React.Fragment key={`${event.id}-${team.id}`}>
                <ListItemButton
                  selected={selectedTeam && selectedTeam.id === team.id}
                  onClick={() => handleTeamClick({ ...team, stormEventId: event.id })}
                >
                  <ListItemText primary={`${team.teamName} - ${generalForeman.firstName || ''} ${generalForeman.lastName || ''} - ${team.location}`} />
                </ListItemButton>
                <hr />  {/* Line divider */}
              </React.Fragment>
            );
          })}
        </React.Fragment>
      ))}
    </List>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleAssignDialogClose} color="secondary" variant="outlined">
      Cancel
    </Button>
    <Button onClick={handleAssignToTeam} color="secondary" variant="outlined">
      Assign to Team
    </Button>
  </DialogActions>
</Dialog>
    </Box>
  );
};

export default OnDeck;










