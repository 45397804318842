import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, DoughnutController, ArcElement } from 'chart.js';
import { getDocs, collection } from "firebase/firestore";
import { firestore } from '../firebase';
import { Typography } from '@mui/material';

// Register the required components for Doughnut chart
Chart.register(DoughnutController, ArcElement);

const DailySignListChart = () => {
  const [totalSignIns, setTotalSignIns] = useState(0); // State for today's sign-ins
  

  // Fetch data from Firestore
  const fetchData = async () => {
    const querySnapshot = await getDocs(collection(firestore, "dailySignList"));
    let todayTotal = 0;
    const todaySet = new Set();  // Set to hold unique memberId for today
    

    const today = new Date().toISOString().split('T')[0];  // Current date in YYYY-MM-DD
    

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      
      if (data.checkIns) {
        data.checkIns.forEach((checkIn) => {
          const { date, memberId } = checkIn;
          
          if (date === today && !todaySet.has(memberId)) {
            todayTotal++;
            todaySet.add(memberId);
          
          }
        });
      }
    });

    setTotalSignIns(todayTotal);
    
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Prepare chart data and options
  const chartData = {
    labels: ['Today'],
    datasets: [
      {
        data: [totalSignIns],
        backgroundColor: [
          'rgba(33, 150, 243, 0.5)',  // Light blue for 'Today'
          'rgba(100, 181, 246, 0.5)'  // Another shade of light blue for 'Yesterday'
        ],
      },
    ],
  };
  
  

  const chartOptions = {
    plugins: {
      legend: {
        position: 'top',
        align: 'start',
        labels: {
          boxWidth: 50,
          font: {
            size: 10,
          },
          padding: 9,
        },
      },
    },
  };

  return (
    <div>
      <Doughnut data={chartData} options={chartOptions} />
      <Typography variant="subtitle2" style={{ fontWeight: 'bold', padding: '5px' }}>Total Sign-Ins Today: {totalSignIns}</Typography>
      
    </div>
  );
};

export default DailySignListChart;


