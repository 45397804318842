import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { addData, uploadBytes, ref, getDownloadURL, storage } from '../firebase';
import { Button, TextField, Box, Container, Grid, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckIcon from '@mui/icons-material/Check';

function ExpenseReports() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [file, setFile] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [fileUploaded, setFileUploaded] = useState(false);

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue); // Update the selectedDate state
  };

  const onSubmit = async (data) => {
    // Check if the selectedDate state is set and add it to the data object
    if (selectedDate) {
      const formattedDate = selectedDate.toISOString(); // Format the date to an ISO string
      data.date = formattedDate; // Include the formatted date in the data object
    }
  
    // Check if a file is selected for upload
    if (file) {
      const currentDate = new Date();
      // Define a unique storage path for the file
      const storagePath = `expenseReports/${data.firstName}_${data.lastName}_${currentDate.toISOString()}_${file.name}`;
      const storageRef = ref(storage, storagePath);
  
      // Upload the file to Firebase Storage
      await uploadBytes(storageRef, file);
      // Retrieve the download URL of the uploaded file
      const downloadURL = await getDownloadURL(storageRef);
      // Include the download URL in the data object
      data.receipt = downloadURL;
    }
  
    // Submit the data object, including any conditional fields, to Firestore
    const docId = await addData('expenseReports', data);
    console.log('Document ID:', docId); // Log the document ID for confirmation
    setSubmitted(true); // Update state to reflect that the form has been submitted
  };
  

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h5" sx={{ mb: 2 }}>Expense Reports</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="firstName"
                label="First Name"
                fullWidth
                {...register('firstName', { required: true })}
                error={!!errors.firstName}
                helperText={errors.firstName && 'First Name is required.'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="lastName"
                label="Last Name"
                fullWidth
                {...register('lastName', { required: true })}
                error={!!errors.lastName}
                helperText={errors.lastName && 'Last Name is required.'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="supervisorName"
                label="Supervisor Name"
                fullWidth
                {...register('supervisorName', { required: true })}
                error={!!errors.supervisorName}
                helperText={errors.supervisorName && 'Supervisor Name is required.'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date"
                  inputFormat="MM/DD/YYYY"
                  value={selectedDate}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="description"
                label="Description"
                fullWidth
                multiline
                rows={4}
                {...register('description', { required: true })}
                error={!!errors.description}
                helperText={errors.description && 'Description is required.'}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="amount"
                label="$ Amount"
                type="number"
                fullWidth
                inputProps={{ step: '0.01' }}
                {...register('amount', { required: true })}
                error={!!errors.amount}
                helperText={errors.amount && 'Amount is required.'}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                component="label"
                startIcon={fileUploaded ? <CheckIcon /> : <CloudUploadIcon />}
                sx={{ width: '100%', bgcolor: fileUploaded ? 'grey.400' : 'primary.main' }}
                disabled={fileUploaded}
              >
                {fileUploaded ? 'File Uploaded' : 'Upload Receipt'}
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={(event) => {
                    onFileChange(event);
                    setFileUploaded(true);
                  }}
                />
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" type="submit" sx={{ width: '100%' }}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
        {submitted && (
          <Box mt={2}>
            <Typography variant="h6" color="success">
              Expense report submitted successfully!
            </Typography>
          </Box>
        )}
      </Box>
    </Container>
  );
}

export default ExpenseReports;


