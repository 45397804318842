import React, { useEffect, useState } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Select, MenuItem } from '@mui/material';
import { getData, fetchTeamData } from '../firebase';  // adjust the path according to your file structure


const DailySignList = () => {
    const [teams, setTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState('');
    const [teamData, setTeamData] = useState([]);

    useEffect(() => {
        const fetchTeams = async () => {
            const fetchedTeams = await fetchTeamData();
            console.log('Fetched Teams:', fetchedTeams);
            setTeams(fetchedTeams);
            // Set initial value only when teams data is not empty
            if (fetchedTeams.length > 0) {
                setSelectedTeam(fetchedTeams[0].id);
            }
        };
        fetchTeams();
    }, []);
    

    useEffect(() => {
        const fetchTeamData = async () => {
          if (selectedTeam) {
            const fetchedTeam = await getData('dailysignin', selectedTeam);
            if (fetchedTeam) {
              setTeamData(fetchedTeam.users);
            }
          }
        };
        fetchTeamData();
      }, [selectedTeam]);
      
    
      

    const handleTeamChange = (e) => {
        setSelectedTeam(e.target.value);
    };

    return (
        <div>
            
            <Select value={selectedTeam} onChange={handleTeamChange}>
                {teams.map((team) => (
                    <MenuItem value={team.id} key={team.id}>
                        {team.id}  {/* The team name is now the id */}
                    </MenuItem>
                ))}
            </Select>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Team</TableCell>
                            <TableCell>Home Local</TableCell>
                            <TableCell>Classification</TableCell>
                            <TableCell>Cellphone</TableCell>
                            <TableCell>Check-in Time</TableCell>
                            <TableCell>Latitude</TableCell>
                            <TableCell>Longitude</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {teamData.map((user, index) => (
                            <TableRow key={index}>
                                <TableCell>{user.name}</TableCell>
                                <TableCell>{user.team}</TableCell>
                                <TableCell>{user.homeLocal}</TableCell>
                                <TableCell>{user.classification}</TableCell>
                                <TableCell>{user.cellPhone}</TableCell>
                                <TableCell>{user.checkInTime}</TableCell>
                                <TableCell>{user.latitude}</TableCell>
                                <TableCell>{user.longitude}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default DailySignList;

