import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, Paper, Button, Dialog, DialogContent, Box, DialogTitle } from '@mui/material';
import NoaaStormData from '../components/NoaaStormData';
import StormChart from '../components/StormChart';
import PowerOutageChart from './PowerOutageChart';
import ActiveStorm from './ActiveStorm';
import ActivePersonnelChart from '../components/ActivePersonnelChart';
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';
import TotalBucketTrucks from './TotalBucketTrucks';
import TotalDiggerTrucks from './TotalDiggerTrucks';
import DailyReportCard from './DailyReportCard';
import DailySignListChart from '../components/DailySignListChart';
import DailySignListTable from '../components/DailySignListTable'; // Adjust the import path as needed
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SafetyFormFetcher from '../components/SafetyFormFetcher';
import { useNavigate } from 'react-router-dom';



const Dashboard = () => {
  // State to control the visibility of the modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  // State for Power Outage Chart modal visibility
  const [isPowerOutageModalOpen, setIsPowerOutageModalOpen] = useState(false);
  const [isStormChartModalOpen, setIsStormChartModalOpen] = useState(false);
  const [isLiveMapModalOpen, setIsLiveMapModalOpen] = useState(false);
  const [isDailySignListModalOpen, setIsDailySignListModalOpen] = useState(false);
  const [isSafetyChecksModalOpen, setIsSafetyChecksModalOpen] = useState(false);
  
  // Function to toggle DailySignListTable modal visibility
  const toggleDailySignListModal = () => {
    setIsDailySignListModalOpen(!isDailySignListModalOpen);
  };
  // Function to toggle modal visibility
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

// Function to toggle Power Outage Chart modal visibility
const togglePowerOutageModal = () => {
  setIsPowerOutageModalOpen(!isPowerOutageModalOpen);
};

const toggleStormChartModal = () => setIsStormChartModalOpen(!isStormChartModalOpen);
  useEffect(() => {
    // Any data fetching or subscriptions can go here
    return () => {
      // Cleanup
    };
  }, []);

  const toggleLiveMapModal = () => {
    setIsLiveMapModalOpen(!isLiveMapModalOpen);
  };

  const toggleSafetyChecksModal = () => {
    setIsSafetyChecksModalOpen(!isSafetyChecksModalOpen);
  };

  const navigate = useNavigate();

  const handleNavigateToStormEventTeams = () => {
    navigate('/storm-event-teams');
  };

  return (
    <>
{/* Title Section */}
<Typography variant="h4" style={{ marginTop: 20, marginBottom: 10, fontFamily: 'Monospace', fontWeight: 'bold', color: '#1976d2', textAlign: 'center' }}>
  <Box sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', margin:'5px' }}>
    StormBoard
  </Box>
  <ThunderstormIcon sx={{ margin: 1 }} />
</Typography>

      {/* Main Content */}
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
        <Container maxWidth="lg" sx={{ paddingTop: 1, margin: 'auto' }}>
    
          {/* Buttons Row */}
          <Box border={2} borderColor="secondary" borderRadius="10px" p={1} mb={1}>
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12} sm={6} md={3}>
                <Button variant="outlined" color="secondary" fullWidth onClick={toggleModal}>
                  View NOAA Storm Data
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Button variant="outlined" color="secondary" fullWidth onClick={togglePowerOutageModal}>
                  US Power Outage Chart
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Button variant="outlined" color="secondary" fullWidth onClick={toggleStormChartModal}>
                  Storm Chart - East Coast
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Button variant="outlined" color="secondary" fullWidth onClick={toggleLiveMapModal}>
                  Live Hurricane Map
                </Button>
              </Grid>
            </Grid>
          </Box>
    
           {/* First Row */}
      <Box border={3} borderColor="secondary" borderRadius="10px" p={2} mb={4}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <Paper sx={{ padding: 1, height: "100%", position: 'relative' }}>
              <Typography variant="h6">Daily User Sign-in</Typography>
              {/* IconButton to open the DailySignListTable modal */}
              <IconButton
  sx={{ position: 'absolute', top: 5, right: 5, color: (theme) => theme.palette.secondary.main }}
  onClick={toggleDailySignListModal}
>
  <VisibilityIcon />
</IconButton>
      <DailySignListChart />
        </Paper>
          </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Paper sx={{ padding: 1, height: "100%" }}>
                  <Typography variant="h6">Bucket Trucks</Typography>
                  <TotalBucketTrucks />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Paper sx={{ padding: 1, height: "100%" }}>
                  <Typography variant="h6">Diggers</Typography>
                  <TotalDiggerTrucks />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Paper sx={{ padding: 1, height: "100%" }}>
                  <Typography variant="h6">Active Personnel</Typography>
                  <ActivePersonnelChart />
                </Paper>
              </Grid>
            </Grid>
          </Box>
    
          {/* Second Row */}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ padding: 1 }}>
                <Typography variant="h6">Active Storm Event Details</Typography>
                <ActiveStorm />
{/* Button to navigate to Storm Event Teams */}
              <Box mt={2}>
              <Button variant="outlined" color="secondary" onClick={handleNavigateToStormEventTeams}>
                Storm Event Team Board
              </Button>
              </Box>

              </Paper>
            </Grid>
          </Grid>
          <Container>
      <h2>Daily Reports and Safety Checks</h2>
      <Button variant="outlined" color="secondary" onClick={toggleSafetyChecksModal} sx={{ mt: 2, mb: 2 }}>
        Safety Checks
      </Button>
      <DailyReportCard />
    </Container>
        
    
        {/* Dialog for the DailySignListTable */}
      <Dialog
        open={isDailySignListModalOpen}
        onClose={toggleDailySignListModal}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Daily Sign-In List</DialogTitle>
        <DialogContent>
          <DailySignListTable />
        </DialogContent>
      </Dialog>

       {/* Dialog for Safety Checks */}
       <Dialog open={isSafetyChecksModalOpen} onClose={toggleSafetyChecksModal} maxWidth="md" fullWidth>
        <DialogTitle>Safety Checks</DialogTitle>
        <DialogContent>
          <SafetyFormFetcher />
        </DialogContent>
      </Dialog>

        
        
        {/* Dialog for the NOAA Storm Data */}
        <Dialog open={isModalOpen} onClose={toggleModal} maxWidth="lg">
          <DialogTitle>NOAA Storm Data</DialogTitle>
          <DialogContent>
            <NoaaStormData />
          </DialogContent>
        </Dialog>


        <Dialog open={isPowerOutageModalOpen} onClose={togglePowerOutageModal} maxWidth="md">
          <DialogTitle>Power Outage Chart</DialogTitle>
          <DialogContent>
            <PowerOutageChart />
          </DialogContent>
        </Dialog>

        <Dialog 
  open={isStormChartModalOpen} 
  onClose={toggleStormChartModal} 
  PaperProps={{
    style: {
      minWidth: "80%",
      minHeight: "80%",
    },
  }}
>
  <DialogTitle>Storm Chart</DialogTitle>
  <DialogContent>
    <StormChart />
  </DialogContent>
</Dialog>

<Dialog 
  open={isLiveMapModalOpen} 
  onClose={toggleLiveMapModal}
  PaperProps={{
    style: {
      minWidth: '80%',
      minHeight: '40vh',
    },
  }}
>
  <DialogTitle>Live Hurricane Map</DialogTitle>
  <DialogContent>
    <iframe 
      title="Live Hurricane Map"
      src="https://www.rainviewer.com/hurricane-map.html?loc=31.5036,-78.5742,3&oFa=0&oC=0&oCS=0&oF=0&oAP=1&layer=sat&hu=1"
      width="100%" 
      style={{ border: "0", height: "50vh" }}
      allowFullScreen>
    </iframe>
  </DialogContent>
</Dialog>


      </Container>
    </Box>
    </>
  );
};

export default Dashboard;
