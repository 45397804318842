import React, { useEffect, useState, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Select,
  MenuItem,
  Checkbox,
  Button,
  TextField,
  InputLabel,
  FormControl,
  Typography,
  Grid,
  Box,
  IconButton,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { fetchActiveStormEventsData, addData } from '../firebase';
import { useNavigate } from 'react-router-dom';
import {Tag} from 'antd';

const StormEventList = () => {
  const [activeEvents, setActiveEvents] = useState([]);
  const [selectedTeamId, setSelectedTeamId] = useState('');
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [checkedInList, setCheckedInList] = useState({});
  const [exceptionNote, setExceptionNote] = useState('');
  const [submitStatus, setSubmitStatus] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMemberIndex, setSelectedMemberIndex] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split("T")[0]);
  const [uniqueSignatureId, setUniqueSignatureId] = useState(null);
  const [allCheckInData, setAllCheckInData] = useState([]);
  const [isConfirmingCheckIn, setIsConfirmingCheckIn] = useState(false);

  const navigate = useNavigate();
  const sigPad = useRef(null);

  useEffect(() => {
    const fetchEvents = async () => {
      const fetchedEvents = await fetchActiveStormEventsData();
      const activeEvents = fetchedEvents.filter(event => event.status === 'active');
      setActiveEvents(activeEvents);
    };
    fetchEvents();
  }, []);

  useEffect(() => {
    if (selectedTeamId) {
      for (const event of activeEvents) {
        const team = event.teams.find(team => team.id === selectedTeamId);
        if (team) {
          setSelectedTeam(team);
          break;
        }
      }
    }
  }, [selectedTeamId, activeEvents]);

  const openCheckInDialog = (memberIndex) => {
    // Generate a unique ID for the signature
    const signatureId = `sig_${Date.now()}_${Math.random().toString(36).substring(2, 15)}`;
    setUniqueSignatureId(signatureId);

    setSelectedMemberIndex(memberIndex);
    setOpenDialog(true);
  };

  const closeCheckInDialog = () => {
    setOpenDialog(false);
  };

  // This function assumes addData is a helper function that handles adding documents to Firestore
const handleConfirmCheckIn = async () => {
  // Prevent double clicking by early exit if already confirming check-in
  if (isConfirmingCheckIn) return;

  // Indicate that the check-in process has started
  setIsConfirmingCheckIn(true);

  const signatureDataURL = sigPad.current.toDataURL();

  // Create a promise to wrap the geolocation API
  const getPosition = new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });

  try {
    const position = await getPosition;
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    // Get the current timestamp
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const timeString = `${hours}:${minutes}`; // HH:MM format

    // Create the data object with geolocation and formatted timestamp
    const checkInData = {
      memberId: selectedTeam.roster[selectedMemberIndex].id,
      signature: signatureDataURL,
      date: selectedDate,
      uniqueSignatureId,
      latitude,
      longitude,
      timestamp: timeString // HH:MM format
    };

    setAllCheckInData(prevData => [...prevData, checkInData]);

    // Update checkedInList to include more details
    setCheckedInList(prevState => ({
      ...prevState,
      [`${selectedMemberIndex}`]: {
        confirmed: true,
        date: selectedDate,
        signatureId: uniqueSignatureId,
      }
    }));

    // Save the check-in data to Firestore
    await addData('checkIns', checkInData);

    // Close the dialog
    closeCheckInDialog();
  } catch (error) {
    if (error.code === 1) {
      alert('Permission denied for geolocation. Proceeding without location data.');
    } else {
      alert('An error occurred while getting location. Proceeding without location data.');
    }
    setSubmitStatus('Error occurred during submission!');
  } finally {
    // Re-enable the confirm button after the process is complete or if an error occurs
    setIsConfirmingCheckIn(false);
  }
};

const getRoleColor = (roleOrClassification) => {
  switch(roleOrClassification) {
    case 'Lineman':
      return <Tag color="lime">Lineman</Tag>;
    case 'Line Foreman':
      return <Tag color="magenta">Line Foreman</Tag>;
    case 'Line Apprentice':
      return <Tag color="red">Line Apprentice</Tag>;
    case 'Line Equipment Operator':
      return <Tag color="volcano">Line Equipment Operator</Tag>;
    case 'Line Groundman':
      return <Tag color="orange">Line Groundman</Tag>;
    case 'Line Cable Splicer':
      return <Tag color="gold">Line Cable Splicer</Tag>;
    case 'Working Foreman':
      return <Tag color="lime">Working Foreman</Tag>;
    case 'Service Crew Electrician':
      return <Tag color="green">Service Crew Electrician</Tag>;
    case 'Field Safety':
      return <Tag color="cyan">Field Safety</Tag>;
    case 'Field Support':
      return <Tag color="blue">Field Support</Tag>;
    case 'Flagger':
      return <Tag color="geekblue">Flagger</Tag>;
    case 'Line General Foreman':
      return <Tag color="magenta">Line General Foreman</Tag>;
    case 'Tree General Foreman':
      return <Tag color="red">Tree General Foreman</Tag>;
    case 'Vehicle Mechanic':
      return <Tag color="volcano">Vehicle Mechanic</Tag>;
    case 'Damage Assessor':
      return <Tag color="orange">Damage Assessor</Tag>;
    case 'Driver':
      return <Tag color="gold">Driver</Tag>;
    case 'Wires Down Guard':
      return <Tag color="lime">Wires Down Guard</Tag>;
    case 'Tree Climber':
      return <Tag color="green">Tree Climber</Tag>;
    case 'Tree Foreman':
      return <Tag color="cyan">Tree Foreman</Tag>;
    case 'Tree Groundman':
      return <Tag color="blue">Tree Groundman</Tag>;
    default:
      return <Tag color="geekblue">{roleOrClassification}</Tag>;
  }
};
const getCrewTypeColor = (crewType) => {
  switch(crewType) {
    case 'Contractor Line':
      return <Tag color="#E57373">Contractor Line</Tag>;
    case 'Contractor Service':
      return <Tag color="#81C784">Contractor Service</Tag>;
    case 'Damage Appraiser':
      return <Tag color="#08d456">Damage Appraiser</Tag>;
    case 'Wire Down':
      return <Tag color="#8930fc">Wire Down</Tag>;
    case 'Sub-Contractors':
      return <Tag color="#BA68C8">Sub-Contractors</Tag>;
    case 'Transmission':
      return <Tag color="#BA68C8">Transmission</Tag>;
    case 'Contractor Tree':
      return <Tag color="#BA68C8">Contractor Tree</Tag>;
    case 'Contractor Support':
      return <Tag color="#BA68C8">Contractor Support</Tag>;
    default:
      return <Tag color="geekblue">{crewType}</Tag>;
  }
};

  
  
  

  const handleTeamChange = (e) => {
    setSelectedTeamId(e.target.value);
  };

  const handleCheckIn = (memberIndex) => {
    setCheckedInList(prevState => {
      const newState = { ...prevState };
      newState[`${memberIndex}`] = !newState[`${memberIndex}`];
      return newState;
    });
  };

  const handleCheckInSubmit = async () => {
    const allMembersCheckedIn = selectedTeam.roster.every((_, memberIndex) => checkedInList[`${memberIndex}`]);
    
    if (!allMembersCheckedIn && exceptionNote === '') {
      alert('All members must be checked in or provide a reason for the exception.');
      return;
    }
  
    // Create an array of checked-in members
    const checkedInMembers = selectedTeam.roster.filter((_, memberIndex) => checkedInList[`${memberIndex}`]);
  
    try {
      // Include allCheckInData when saving to Firestore
      await addData('dailySignList', { teams: checkedInMembers, checkIns: allCheckInData, exceptionNote });
  
      // Clear the allCheckInData state after successful submission
      setAllCheckInData([]);
  
      // Update the status
      setSubmitStatus('Successfully submitted check-in list!');
    } catch (error) {
      setSubmitStatus('Error occurred during submission!');
    }
  };
  

  return (
    <div>
      
      <IconButton 
  edge="start" 
  color="secondary" 
  aria-label="back" 
  onClick={() => window.history.back()} 
  style={{ position: 'absolute', top: 99, left: 15, padding: 5 }} // Adjust the 'top' value as needed
>
  <ArrowBackIcon />
</IconButton>

      <Box padding={3}>
        <Typography variant="h6" style={{ margin: '20px 0', color: '#3480eb' }}>
          Daily Sign-in
        </Typography>
        <FormControl style={{ minWidth: 300, marginBottom: 20 }}>
          <InputLabel id="team-select-label">Please select an active team</InputLabel>
          <Select
            labelId="team-select-label"
            value={selectedTeamId}
            onChange={handleTeamChange}
            color="primary"
          >
            {activeEvents.map((event) =>
              event.teams.map((team) => (
                <MenuItem value={team.id} key={team.id}>
                    <span>
                        <strong>Team:</strong> {team.teamName}, 
                        <strong> Event:</strong> {event.eventName}, 
                        <strong> AWC Location:</strong> {team.AWClocation}, 
                        <strong> State:</strong> {team.location}, 
                        <strong> Work Order:</strong> {event.stormWorkOrder}
                    </span>
                </MenuItem>
            ))
        )}
    </Select>
</FormControl>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Check-in</TableCell>
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Crew Type</TableCell>
                            <TableCell>Member Type</TableCell>
                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {selectedTeam && selectedTeam.roster.map((member, memberIndex) => (
                <TableRow key={member.id} style={checkedInList[`${memberIndex}`]?.confirmed ? { backgroundColor: "#f2f2f2" } : {}}
                >
                  <TableCell>
                    <Checkbox
                      checked={!!checkedInList[`${memberIndex}`]}
                      onChange={() => openCheckInDialog(memberIndex)}
                    />
                  </TableCell>
                                <TableCell>{member.firstName}</TableCell>
                                <TableCell>{member.lastName}</TableCell>
                                <TableCell>{getCrewTypeColor(member.crewType)}</TableCell>
                                <TableCell>{getRoleColor(member.role || member.classification)}</TableCell>
                                
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
    <Grid container spacing={2} style={{marginTop: '20px', padding: '0 10px'}}>
    <Grid item xs={12}>
        <TextField
            label="Exception Note"
            multiline
            rows={3}
            variant="outlined"
            value={exceptionNote}
            style={{width: '400px'}}
            onChange={(e) => setExceptionNote(e.target.value)}
        />
    </Grid>
    <Grid item xs={12} style={{display: 'flex', justifyContent: 'left'}}>
    <Button
    variant="outlined"
    color="secondary"
    style={{width: '200px', marginBottom: '20px'}}
    onClick={handleCheckInSubmit}
>
    Submit Check-In List
</Button>
    </Grid>
    {submitStatus && 
        <Grid item xs={12}>
            <Box mt={2} mb={5}>
                <Typography variant="body1" color="textSecondary">{submitStatus}</Typography>
            </Box>
        </Grid>
    }
    
</Grid>



<Dialog open={openDialog} onClose={closeCheckInDialog}>
  <DialogTitle>Confirm Check-In</DialogTitle>
  <DialogContent>
    <DialogContentText style={{ margin: '20px 0' }}>
      Please sign below to confirm that you are on-site and have received the Safety Briefing.
    </DialogContentText>
    
    <SignatureCanvas
          ref={sigPad}  // Attach the ref here
          penColor='black'
          canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
        />
    <DialogContentText style={{ margin: '20px 0' }}>
  {/* Check if selectedTeam and selectedMemberIndex are not null before displaying */}
  Confirming check-in for {selectedTeam && selectedMemberIndex !== null ? `${selectedTeam.roster[selectedMemberIndex]?.firstName} ${selectedTeam.roster[selectedMemberIndex]?.lastName}` : 'No team or member selected'}
</DialogContentText>

    <TextField
      id="date"
      label="Check-in Date"
      type="date"
      value={selectedDate}
      onChange={(e) => setSelectedDate(e.target.value)}
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={closeCheckInDialog} color="secondary">
      Cancel
    </Button>
    <Button
  onClick={handleConfirmCheckIn}
  color="secondary"
  disabled={isConfirmingCheckIn}
>
  Confirm
</Button>

  </DialogActions>
</Dialog>

</Box>
</div>
);
}

export default StormEventList;
