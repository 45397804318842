import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import {
  Button,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
  Box,
  RadioGroup,
  Radio,
  Typography,
  Container
} from '@mui/material';
import { CheckCircle, Error, Today } from '@mui/icons-material';


const RadioOption = ({question, value, setValue}) => (
  <>
    <Grid item xs={12}><h3>{question}</h3></Grid>
    <Grid item xs={12}>
      <RadioGroup row value={value} onChange={(e) => setValue(e.target.value)}>
        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="no" control={<Radio />} label="No" />
      </RadioGroup>
    </Grid>
  </>
);

const DailyTailboardSafety = () => {
  const [crewWorking, setCrewWorking] = useState('');
  const employeeSigCanvas = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // TODO: Sync with Google Sheets
  };

  const clearEmployeeSignature = () => {
    if (employeeSigCanvas.current) {
      employeeSigCanvas.current.clear();
    }
  };

  const copyright = '© 2023';
  const version = 'MTV Smart App v0.5';

  return (
    <>
      
      <Container maxWidth="lg" className="content-container">
        <div className="app-container" style={{ paddingTop: '1rem', minHeight: '300vh' }}>
          <Box padding={2} paddingLeft={4} paddingTop={1}>
            <Typography variant="h4" component="h1" gutterBottom>
              Tailboard Report
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField label="Foreman Name" required style={{ width: '65%' }} />
                </Grid>
                <Grid item xs={12}>
                  <TextField label="Safety Personel Name" required style={{ width: '65%' }} />
                </Grid>
                <Grid item xs={12}>
                  <TextField label="Email" type="email" required style={{ width: '65%' }} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Date"
                    type="date"
                    InputProps={{
                      startAdornment: (
                        <Today color="action" style={{ marginRight: '0.5rem' }} />
                      ),
                    }}
                    required style={{ width: '33%' }} />
                </Grid>
                <Grid item xs={12}>
                  <TextField label="Location" required style={{ width: '65%' }} />
                </Grid>
                <Grid item xs={12}>
                  <TextField label="Job Number" required style={{ width: '33%' }} />
                </Grid>
                
                <RadioOption question="Emergency action procedures discussed?" value={crewWorking} setValue={setCrewWorking} />

                <Grid item xs={12}>
                  <TextField label="Identify any hazardous conditions:" required style={{ width: '50%' }} />
                </Grid>

                <Grid item xs={12}>
                  <h3>Answer each of the following questions:</h3>
                </Grid>

                <RadioOption question="Do you have enough of the proper saftey equiptment to perform this job?" value={crewWorking} setValue={setCrewWorking} />
                <RadioOption question="Were there any hazards associated with this job?" value={crewWorking} setValue={setCrewWorking} />   
                <RadioOption question="Have all crew members been included in this meeting?" value={crewWorking} setValue={setCrewWorking} />
                <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox
                    icon={<Error />}
                    checkedIcon={<CheckCircle />} />}
                  label="If not, please state why any crew members were not included:" />
                <TextField label="Describe" fullWidth />
              </Grid>   
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox
                      icon={<Error />}
                      checkedIcon={<CheckCircle />}
                      color="primary"
                    />}
                    label="I have verified that the crew is aware of the safety requirements and has the appropriate safety equipment."
                    required
                  />
                </Grid>

                <RadioOption question="Any special procautions needed?" value={crewWorking} setValue={setCrewWorking} />
                <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox
                    icon={<Error />}
                    checkedIcon={<CheckCircle />} />}
                  label="Special Procations?" />
                <TextField label="Describe" fullWidth />
              </Grid>
                <RadioOption question="Personal protective equipt needed?" value={crewWorking} setValue={setCrewWorking} />
                <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox
                    icon={<Error />}
                    checkedIcon={<CheckCircle />} />}
                  label="Personal protective equipt needed:" />
                <TextField label="Describe" fullWidth />
              </Grid>
                <RadioOption question="Grounding needed for personal protections?" value={crewWorking} setValue={setCrewWorking} />
                <RadioOption question="Grounding needed for Equiptment protections?" value={crewWorking} setValue={setCrewWorking} />
                <RadioOption question="Methods needed for public protection?" value={crewWorking} setValue={setCrewWorking} />
                
                <Grid item xs={12}>
                  <SignatureCanvas
                    ref={employeeSigCanvas}
                    canvasProps={{
                      width: 500,
                      height: 200,
                      className: 'sigCanvas'
                    }} />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    onClick={clearEmployeeSignature}
                    variant="outlined"
                    style={{ marginRight: '1rem' }}>
                    Clear Signature
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
            <footer style={{ marginTop: '2rem' }}>
              <hr />
              <p style={{ fontSize: '0.8rem', textAlign: 'center' }}>
                {`${copyright} - ${version}`}
              </p>
            </footer>
          </Box>
        </div>
      </Container>
    </>
  );
};

export default DailyTailboardSafety;
