import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
  Tooltip,
} from "@mui/material";

import { getDataByField, updateData } from "../firebase";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FlashOnIcon from '@mui/icons-material/FlashOn';

const LightningBoltAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const ActiveStorm = () => {
  const [activeStormEvents, setActiveStormEvents] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);

  const fetchStormEvents = async () => {
    const activeEvents = await getDataByField("stormEvents", "status", "active");
    setActiveStormEvents(activeEvents);
  };

  useEffect(() => {
    fetchStormEvents();
  }, []);

  const handleStatusChange = async () => {
    const closeDate = new Date().toISOString().split('T')[0]; // Gets the current date in YYYY-MM-DD format
    await updateData('stormEvents', selectedEventId, { 
      status: 'closed',
      closeDate: closeDate // Adds the current date to the 'closeDate' field
    });
    fetchStormEvents();
    setOpenDialog(false);
  }
  

  const handleOpenDialog = (eventId) => {
    setSelectedEventId(eventId);
    setOpenDialog(true);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const navigate = useNavigate();

  const handleViewMore = (event) => {
    navigate(`/event-details/${event.id}`);
  }

  return (
    <>
<Container>
<Box mt={3}>
<Box display="flex" flexWrap="wrap" gap={6}>
  {activeStormEvents.map((event) => {
  const teamCount = event.teams ? event.teams.length : 0;
  const activePersonnel = event.teams
  ? event.teams.reduce((acc, team) => acc + (team.roster ? team.roster.length : 0), 0)
  : 0;           
  return (
        <Card 
        key={event.id} 
        sx={{
        border: '1px solid #ccc', 
        p: 1, 
        m: 1, 
        '&:hover': { 
        boxShadow: '0 4px 20px 0 rgba(0,0,255,0.8)', // Changed the box shadow color to a darker shade of blue on hover
        cursor: 'pointer' 
      }
    }}
      >
  <CardHeader
  avatar={
      <LightningBoltAvatar>
      <FlashOnIcon />
     </LightningBoltAvatar>
  }
      action={
      <Tooltip title="Close Storm Event">
        <IconButton aria-label="settings" onClick={() => handleOpenDialog(event.id)}>
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
  }
    title={event.eventName}
    subheader={event.eventDate}
  />
<CardContent>
  <Typography variant="body2" color="text.secondary">
    <strong>Location:</strong> {event.location}
  </Typography>
  <Typography variant="body2" color="text.secondary">
    <strong>Status:</strong> {event.status}
  </Typography>
  <Typography variant="body2" color="text.secondary">
    <strong>Crews:</strong> {teamCount}
  </Typography>
  <Typography variant="body2" color="text.secondary">
    <strong>Active Personnel:</strong> {activePersonnel}
  </Typography>
  <Typography variant="body2" color="text.secondary">
    <strong>Utility Name:</strong> {event.utilityName}
  </Typography>
  <Typography variant="body2" color="text.secondary">
    <strong>Storm Work Order:</strong> {event.stormWorkOrder}
  </Typography>
</CardContent>
<CardActions disableSpacing>
  <Tooltip title="Storm Event Details">
    <Button variant="outlined" color="secondary" onClick={() => handleViewMore(event)}>
      View More
    </Button>
  </Tooltip>
  </CardActions>
  </Card>
 );
})}
</Box>
</Box>
  </Container>
{/* Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Close Event</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to close this event?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            No
          </Button>
          <Button onClick={handleStatusChange} color="secondary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ActiveStorm;