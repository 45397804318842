import React, { useState, useEffect } from 'react';
import { Box, Button, Card, CardContent, Divider, Grid, Typography, Avatar } from '@mui/material';
import { auth, getDocs, firestore, collection, query, where, storage, getDownloadURL, ref } from '../firebase';

import { Link } from 'react-router-dom';

const Profile = () => {
  const [user, setUser] = useState(null);
  const [photoURL, setPhotoURL] = useState('');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async authUser => {
      if (authUser) {
        const userCollection = collection(firestore, 'users');
        const q = query(userCollection, where('uid', '==', authUser.uid));
        const userDocs = await getDocs(q);
        if (!userDocs.empty) {
          const userData = userDocs.docs[0].data();
          setUser(userData);
        }
               // Fetch the profile image URL from Firebase Storage
               const imagePath = `profilepic/${authUser.uid}`;
               const storageRef = ref(storage, imagePath);
               try {
                 const imageURL = await getDownloadURL(storageRef);
                 setPhotoURL(imageURL);
               } catch (error) {
                 // If there's an error (e.g., file not found), use a placeholder image or handle appropriately
                 setPhotoURL('https://firebasestorage.googleapis.com/v0/b/mtv-smart-app.appspot.com/o/Images%2Fnickwhite0x_placeholder_image_of_an_electrical_lineman_with_a_h_2a49a5aa-2d93-4dc1-8994-a05292a6dd0c.png?alt=media&token=b632b6db-6729-4e25-a2d8-96701b11823b');
               }
             } else {
               setUser(null);
             }
           });

    return unsubscribe;
  }, []);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <>
<Box pt={8}>
  <Card sx={{ maxWidth: 600, margin: 'auto' }}>
  <CardContent>
  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb={4}>
  <Avatar alt={`${user.firstName} ${user.lastName}`} src={photoURL} sx={{ width: 100, height: 100, mb: 2 }} />
  <Typography sx={{ fontSize: 18, fontWeight: 'bold' }} color="divider">
    {user.firstName} {user.lastName}
  </Typography>
</Box>
    <Divider />
<Grid container spacing={2} mb={2} mt={1}>
  <Grid item xs={4}>
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color:"#488bf7" }}>Email:</Typography>
  </Grid>
  <Grid item xs={8}>
    <Typography variant="body1">
      <a href={`mailto:${user.email}`} style={{ textDecoration: 'none', color: 'inherit' }}>
        {user.email}
      </a>
    </Typography>
  </Grid>
  <Grid item xs={4}>
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color:"#488bf7" }}>Phone:</Typography>
  </Grid>
  <Grid item xs={8}>
    <Typography variant="body1">
      <a href={`tel:${user.phone}`} style={{ textDecoration: 'none', color: 'inherit' }}>
        {user.phone}
      </a>
    </Typography>
  </Grid>
  <Grid item xs={4}>
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color:"#488bf7" }}>Classification:</Typography>
  </Grid>
  <Grid item xs={8}>
    <Typography variant="body1">{user.role}</Typography>
  </Grid>
  <Grid item xs={4}>
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color:"#488bf7" }}>Crew Type:</Typography>
  </Grid>
  <Grid item xs={8}>
    <Typography variant="body1">{user.crewType}</Typography>
  </Grid>
  
</Grid>
<Box textAlign="center">
  <Button variant="outlined" color="secondary" component={Link} to="/edit-profile">
    Edit Profile
   </Button>
</Box>
</CardContent>
</Card>
</Box>
</>
  );
};

export default Profile;
