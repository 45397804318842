import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  getFirestore,
  doc,
  getDoc
} from 'firebase/firestore';
import {
  Typography,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from '@mui/material';
import { Tag } from 'antd';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';


const EventDetails = () => {
  const { id } = useParams(); // Get the id from the URL
  const [eventDetails, setEventDetails] = useState(null);

  useEffect(() => {
    if (!id) return;
    const fetchEventDetails = async () => {
      console.log('Fetching event details:', id);
      const db = getFirestore();
      const stormEventRef = doc(db, 'stormEvents', id);
      const stormEventSnapshot = await getDoc(stormEventRef);

      if (stormEventSnapshot.exists()) {
        const data = { id: stormEventSnapshot.id, ...stormEventSnapshot.data() };
        console.log('Fetched event details:', data);
        setEventDetails(data);
      } else {
        console.log('No such document!');
        setEventDetails(null);
      }
    };

    fetchEventDetails();
  }, [id]);

  if (id && !eventDetails) {
    return <Typography>Loading...</Typography>;
  }
  const getRoleColor = (roleOrClassification) => {
    switch(roleOrClassification) {
      case 'Lineman':
        return <Tag color="lime">Lineman</Tag>;
      case 'Line Foreman':
        return <Tag color="magenta">Line Foreman</Tag>;
      case 'Line Apprentice':
        return <Tag color="red">Line Apprentice</Tag>;
      case 'Line Equipment Operator':
        return <Tag color="volcano">Line Equipment Operator</Tag>;
      case 'Line Groundman':
        return <Tag color="orange">Line Groundman</Tag>;
      case 'Line Cable Splicer':
        return <Tag color="gold">Line Cable Splicer</Tag>;
      case 'Working Foreman':
        return <Tag color="lime">Working Foreman</Tag>;
      case 'Service Crew Electrician':
        return <Tag color="green">Service Crew Electrician</Tag>;
      case 'Field Safety':
        return <Tag color="cyan">Field Safety</Tag>;
      case 'Field Support':
        return <Tag color="blue">Field Support</Tag>;
      case 'Flagger':
        return <Tag color="geekblue">Flagger</Tag>;
      case 'Line General Foreman':
        return <Tag color="magenta">Line General Foreman</Tag>;
      case 'Tree General Foreman':
        return <Tag color="red">Tree General Foreman</Tag>;
      case 'Vehicle Mechanic':
        return <Tag color="volcano">Vehicle Mechanic</Tag>;
      case 'Damage Assessor':
        return <Tag color="orange">Damage Assessor</Tag>;
      case 'Driver':
        return <Tag color="gold">Driver</Tag>;
      case 'Wires Down Guard':
        return <Tag color="lime">Wires Down Guard</Tag>;
      case 'Tree Climber':
        return <Tag color="green">Tree Climber</Tag>;
      case 'Tree Foreman':
        return <Tag color="cyan">Tree Foreman</Tag>;
      case 'Tree Groundman':
        return <Tag color="blue">Tree Groundman</Tag>;
      default:
        return <Tag color="geekblue">{roleOrClassification}</Tag>;
    }
  };
  const getCrewTypeColor = (crewType) => {
    switch(crewType) {
      case 'Contractor Line':
        return <Tag color="#E57373">Contractor Line</Tag>;
      case 'Contractor Service':
        return <Tag color="#81C784">Contractor Service</Tag>;
      case 'Damage Appraiser':
        return <Tag color="#08d456">Damage Appraiser</Tag>;
      case 'Wire Down':
        return <Tag color="#8930fc">Wire Down</Tag>;
      case 'Sub-Contractors':
        return <Tag color="#BA68C8">Sub-Contractors</Tag>;
      case 'Transmission':
        return <Tag color="#BA68C8">Transmission</Tag>;
      case 'Contractor Tree':
        return <Tag color="#BA68C8">Contractor Tree</Tag>;
      case 'Contractor Support':
        return <Tag color="#BA68C8">Contractor Support</Tag>;
      default:
        return <Tag color="geekblue">{crewType}</Tag>;
    }
  };

  return (
    <>
      <Container sx={{ gap: 4 }}>
      <Box sx={{ border: '2px solid', borderColor: "secondary.main", borderRadius: "5px", padding: 2, margin: 1 }}>
        <Typography variant="h4" component="div" gutterBottom>
          {eventDetails?.eventName}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Date: {eventDetails && new Date(eventDetails.eventDate).toLocaleDateString()}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Work Order#: {eventDetails?.stormWorkOrder}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Status: {eventDetails?.status === 'active' ? (
            <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center' }}>
              {/* Increased glow effect to the icon */}
              <FiberManualRecordIcon style={{ color: 'green', fontSize: '1rem', filter: 'drop-shadow(0 0 1.5rem green)' }} />
              Active
            </Box>
          ) : eventDetails?.status === 'closed' ? (
            <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center' }}>
              {/* Increased glow effect to the icon */}
              <FiberManualRecordIcon style={{ color: 'red', fontSize: '1rem', filter: 'drop-shadow(0 0 1.5rem red)' }} />
              Closed
            </Box>
          ) : (
            eventDetails?.status
          )}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Location: {eventDetails?.location}
        </Typography>
        {/* Added utility name display */}
        <Typography variant="body1" gutterBottom>
          Utility Name: {eventDetails?.utilityName}
        </Typography>
      </Box>
  
        {eventDetails?.teams && eventDetails.teams.map((teams, index) => {
          const generalForeman = teams.roster.find(user => user.userAccessRole === 'General Foreman');
          return (
            <div key={index}>

             {/* Single Box to wrap both Typography and TableContainer */}
            <Box sx={{ border: '2px solid', borderColor: "secondary", borderRadius: "5px", padding: 2, margin: 1 }}>
              
              {/* Typography Section */}
              <Typography variant="h6" gutterBottom>
                {teams.teamName} - GF: {generalForeman?.firstName} {generalForeman?.lastName} - AWC Location: {teams.AWClocation}
              </Typography>
              
              {/* Table Section */}
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>First Name</TableCell>
                      <TableCell>Last Name</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>Member Type</TableCell>
                      <TableCell>Crew Type</TableCell>
                      <TableCell>Gender</TableCell>
                      <TableCell>Lodging Needed</TableCell>
                      <TableCell>Lodging Type</TableCell>
                      <TableCell>Personal Vehicle</TableCell>
                      <TableCell>Equipment</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {teams.roster.map((roster) => {
                      // Extract the first piece of equipment from the equipment map
                      const equipment = roster.equipment && Object.values(roster.equipment)[0];
                      return (
                        <TableRow key={roster.uid}>
                          <TableCell>{roster.firstName}</TableCell>
                          <TableCell>{roster.lastName}</TableCell>
                          <TableCell>{roster.phone}</TableCell>
                          <TableCell>{getRoleColor(roster.role)}</TableCell>
                          <TableCell>{getCrewTypeColor(roster.crewType)}</TableCell>
                          <TableCell>{roster.gender}</TableCell>
                          <TableCell>{roster.lodgingNeeded ? 'Yes' : 'No'}</TableCell>
                          <TableCell>{roster.lodgingType}</TableCell>
                          <TableCell>{roster.personalVehicle ? 'Yes' : 'No'}</TableCell>
                          <TableCell>
                            {/* Display equipment details if available */}
                            {equipment ? `${equipment.IDNumber || ''} - ${equipment.Make || ''} - ${equipment.Model || ''} - ${equipment.Plate || ''}` : 'N/A'}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              
            </Box>
          </div>
        );
      })}
    </Container>
  </>
);
};

export default EventDetails;