import React, { useState, useEffect } from 'react';
import { getFirestore, collection, query, onSnapshot} from 'firebase/firestore';
import { Grid, Button, TextField, Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import styles from './EquipMan.module.css';
import EquipmentTable from './EquipmentTable';
import EquipmentCard from '../components/EquipmentCard';

const EquipmentManagement = () => {
  
  const [searchResults, setSearchResults] = useState([]);
  const [equipmentSearchTerm, setEquipmentSearchTerm] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();
      const equipmentQuery = query(collection(db, 'equipment'));
      onSnapshot(equipmentQuery, (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSearchResults(data);
      });
    };
    fetchData();
  }, []);

  const filteredEquipment = searchResults.filter(equipment => {
    return Object.values(equipment).some(value =>
      value.toString().toLowerCase().includes(equipmentSearchTerm.toLowerCase())
    );
  });

  const handleClick = (path) => () => {
    // Navigate to the specified path when the button is clicked
    window.location.href = path;
  };

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  return (
    <div className={styles.container}>
      
      <main className={styles['main-content']}>
      <Typography variant="h4" style={{ marginTop: 20, marginBottom: 10, fontFamily: 'Monospace', fontWeight: 'bold', color: '#1976d2' }}>Equipment Overview</Typography>

      <Grid container spacing={2} mt={1} justifyContent="center" alignItems="center">
  <Grid item xs={6}>
    <Box display="flex" justifyContent="center">
      <Button
        variant="outlined"
        color="secondary"
        onClick={handleClick('/addEquipment')}
        sx={{
          width: '200px',  // or '200px' for a specific width
          padding: 1,
          margin: 1,
          marginRight: 1  // Adds 20px of spacing to the right of this button
        }}
      >
        Add Equipment
      </Button>

      <Button
          variant="outlined"
          color="secondary"
          onClick={toggleDialog}
          sx={{ width: '200px', padding: 1, margin: 1 }}
        >
          Table View
        </Button>
    </Box>
  </Grid>
</Grid>

{/* Equipment Cards Display */}
      <TextField 
        value={equipmentSearchTerm}
        onChange={(e) => setEquipmentSearchTerm(e.target.value)}
        placeholder="Search equipment..."
        sx={{ width: '60%', marginTop: 2, backgroundColor: 'secondary', paddingLeft: 3 }}
      />

      <Grid container spacing={2} padding={2} justifyContent="center" alignItems="center">
        {filteredEquipment.map((equipment) => (
          <Grid item xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'}>
            <EquipmentCard key={equipment.id} equipment={equipment} />
          </Grid>
        ))}
      </Grid>

</main>
      <Dialog open={isDialogOpen} onClose={toggleDialog} fullWidth maxWidth="xl">
        <DialogTitle>Equipment Table</DialogTitle>
        <DialogContent>
          <EquipmentTable />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleDialog} color="secondary">Close</Button>
        </DialogActions>
      </Dialog>
</div>
);
}
export default EquipmentManagement;
