import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth'; // Step 1: Import sendPasswordResetEmail
import { Button, TextField, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import logo from '../assets/logo.png'; 
import backgroundImage from '../assets/backgroundImage.jpg';

import '../Login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [open, setOpen] = useState(false); // State to control dialog visibility
  const [resetEmail, setResetEmail] = useState(''); // State for the email input in the dialog
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
   

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/'); 
    } catch (error) {
      alert(error.message);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleForgotPassword = async () => {
    if (!email) {
      alert("Please enter your email address.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Password reset email sent!");
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div className="login-page" style={{ '--bg-image': `url(${backgroundImage})` }}>
      <div className="login-box">
        <div className="logo-container">
          <img src={logo} alt="logo" className="logo"/> 
        </div>

        <form onSubmit={handleSubmit}>
          <TextField 
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal" 
            required
            fullWidth
          />

          <TextField
            label="Password" 
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="normal"
            required
            fullWidth  
          />
       
          <Button 
            type="submit"
            variant="contained"
            color="secondary"
            fullWidth
          >
            Login  
          </Button>

          {/* <Grid container justifyContent="center">
            <Grid item>
              <Button 
                variant="text"
                onClick={() => navigate('/signup')}
                color="secondary"  
              >
                Don't have an account? Sign up
              </Button>
            </Grid>
          </Grid> */}
<Grid container justifyContent="flex-end">
  <Grid item>
    <Button variant="outlined" color="secondary" onClick={handleClickOpen} style={{ marginTop: '8px' }}>
      Forgot password?
    </Button>
  </Grid>
</Grid>
</form>
  </div>
  <Dialog open={open} onClose={handleClose}>
    <DialogTitle>Reset Password</DialogTitle>
    <DialogContent>
    <DialogContentText>
            To reset your password, please enter your email address here. We will send you an email with instructions on how to reset your password.
     </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="secondary">Cancel</Button>
          <Button onClick={handleForgotPassword} variant="outlined" color="secondary">Send</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Login;