import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { storage, ref, getDownloadURL } from '../firebase';

import { Paper, Box, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
}));

const StyledInput = styled('input')({
  display: 'none',
});

function PaperFile() {
  const [file, setFile] = useState(null);

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const onFileUpload = () => {
    const uploadTask = storage.ref(`files/${file.name}`).put(file);
    uploadTask.on(
      "state_changed",
      snapshot => {},
      error => {
        console.log(error);
      },
      () => {
        storage
          .ref("files")
          .child(file.name)
          .getDownloadURL()
          .then(url => {
            console.log(url);
          });
      }
    );
  };

  const downloadFile = (folderName, fileName) => {
    const fileRef = ref(storage, `Paper Forms/${folderName}/${fileName}`);
    getDownloadURL(fileRef)
      .then((url) => {
        // Code to handle the file download
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function(event) {
          const blob = new Blob([xhr.response]);
          const a = document.createElement('a');
          a.style = 'display: none';
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
        };
        xhr.open('GET', url);
        xhr.send();
      })
      .catch((error) => {
        console.log("Error downloading file:", error);
      });
  };
  

  return (
    <div>

<IconButton 
  edge="start" 
  color="secondary" 
  aria-label="back" 
  onClick={() => window.history.back()} 
  style={{ position: 'absolute', top: 99, left: 15, padding: 5 }} // Adjust the 'top' value as needed
>
  <ArrowBackIcon />
</IconButton>
      
      <StyledPaper>
        <Box p={2}>


{/* upload file removed for now */}

          {/* <h2>Upload Files</h2>
          <Stack direction="row" spacing={2}>
            <StyledInput type="file" onChange={onFileChange} id="contained-button-file" />
            <label htmlFor="contained-button-file">
              <Button variant="contained" component="span">Choose file</Button>
            </label>
            <Button variant="contained" onClick={onFileUpload}>Upload</Button>
          </Stack> */}

          <h2>Download Files</h2>
          <Stack direction="row" spacing={2}>
          <Button variant="contained" onClick={() => downloadFile('Daily Report', 'Timesheet.pdf')}>Download Timesheet</Button>
          <Button variant="contained" onClick={() => downloadFile('Safety', 'Safety_Report.pdf')}>Download Safety Report</Button>
          <Button variant="contained" onClick={() => downloadFile('CSV_Template', 'CSV MTV Member Upload Template.csv')}>Download Batch Upload Template</Button>


          </Stack>
        </Box>
      </StyledPaper>
    </div>
  );
}

export default PaperFile;

