
import React from 'react';
import { Button, Box, Container, Grid, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styles from './Home.module.css';
import AccessRoles from '../AccessRoles';

const Home = () => {
  const navigate = useNavigate();

  const handleClick = (route) => () => {
    navigate(route);
  };

  console.log('Home component rendered');

  return (
    <>
      <Box pt={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center" padding={3}>
        <Container maxWidth="md">
          
        <Box m={2} className={styles.iframe-Container}>
          <iframe
            title="Power Outage Regions"
            src="https://outagemap.eversource.com/external/default.html"
            className={styles.iframe}
          />
        </Box>
          <Grid container spacing={1.5} justifyContent="center" position="center" padding={3}>
            <Grid item xs={6}>
            <AccessRoles roles={['Storm Manager', 'Admin', 'General Foreman', 'Foreman']}>
              <Tooltip title="Submit daily reports">
                <Button
                  variant="outlined"
                  onClick={handleClick('/daily-report')}
                  sx={{ width: '100%' }}
                  color="secondary"
                >
                  Daily Report
                </Button>
              </Tooltip>
              </AccessRoles>
            </Grid>

            <Grid item xs={6}>
            <AccessRoles roles={['Storm Manager', 'Admin', 'General Foreman', 'Foreman']}>
              <Tooltip title="Submit daily timesheets">
                <Button
                  variant="outlined"
                  onClick={handleClick('/weeklytimesheet')}
                  sx={{ width: '100%' }}
                  color="secondary"
                >
                  Timesheet
                </Button>
              </Tooltip>
              </AccessRoles>
            </Grid>
            <Grid item xs={6}>
            <AccessRoles roles={['Storm Manager', 'Admin', 'General Foreman', 'Foreman']}>
              <Tooltip title="Crew daily signin">
                <Button
                  variant="outlined"
                  onClick={handleClick('/storm-event-list')}
                  sx={{ width: '100%' }}
                  color="secondary"
                >
                  Daily Signin
                </Button>
              </Tooltip>
              </AccessRoles>
            </Grid>
            <Grid item xs={6}>
            <AccessRoles roles={['Storm Manager', 'Admin', 'General Foreman', 'Foreman']}>
              <Tooltip title="Submit basic expense reports">
                <Button
                  variant="outlined"
                  onClick={handleClick('/expense-reports')}
                  sx={{ width: '100%' }}
                  color="secondary"
                >
                  Expenses
                </Button>
              </Tooltip>
              </AccessRoles>
            </Grid>
            <Grid item xs={6}>
              <Tooltip title="Access downloadable files for printing">
                <Button
                  variant="outlined"
                  onClick={handleClick('/paperfile')}
                  sx={{ width: '100%' }}
                  color="secondary"
                >
                  Paper File
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
            <AccessRoles roles={['Storm Manager', 'Admin', 'General Foreman', 'Safety']}>
              <Tooltip title="Submit daily safety report">
                <Button
                  variant="outlined"
                  onClick={handleClick('/safety-form')}
                  sx={{ width: '100%' }}
                  color="secondary"
                >
                  Safety
                </Button>
              </Tooltip>
              </AccessRoles>
            </Grid>
            </Grid>
          </Container>
          </Box>
        </>
      );
    };

export default Home;
