/*
 .----------------.  .----------------.  .----------------. 
| .--------------. || .--------------. || .--------------. |
| | ____    ____ | || |  _________   | || | ____   ____  | |
| ||_   \  /   _|| || | |  _   _  |  | || ||_  _| |_  _| | |
| |  |   \/   |  | || | |_/ | | \_|  | || |  \ \   / /   | |
| |  | |\  /| |  | || |     | |      | || |   \ \ / /    | |
| | _| |_\/_| |_ | || |    _| |_     | || |    \ ' /     | |
| ||_____||_____|| || |   |_____|    | || |     \_/      | |
| |              | || |              | || |              | |
| '--------------' || '--------------' || '--------------' |
 '----------------'  '----------------'  '----------------' 

                        
   _____       __      __  _                 
  / ___/____  / /_  __/ /_(_)___  ____  _____
  \__ \/ __ \/ / / / / __/ / __ \/ __ \/ ___/
 ___/ / /_/ / / /_/ / /_/ / /_/ / / / (__  ) 
/____/\____/_/\__,_/\__/_/\____/_/ /_/____/  

Created by: Nicholas White
*/
import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import "../RosterWrangler.css";

const RosterWrangler = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [optIn, setOptIn] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Send the form data to Google Sheets
    // Replace this with your writeToGoogleSheets function
    //await writeToGoogleSheets(formData);

    console.log("Form submitted");
  };

  return (
    <>
      
      <Box mt={4}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={8} md={6}>
            <Container maxWidth="sm" className="roster-wrangler-container">
              <Typography
                variant="h4"
                component="h1"
                textAlign="center"
                className="form-title"
              >
                New Storm Event
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} className="grid-item">
                    <TextField
                      label="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} className="grid-item">
                    <TextField
                      label="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} className="grid-item">
                    <TextField
                      label="Phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} className="grid-item">
  <FormControl component="fieldset">
   
    <Typography variant="h6" component="h6">
      Select Yes to join the active roster, select NO to opt out of the roster
    </Typography>
    <RadioGroup
      row
      value={optIn}
      onChange={(e) => setOptIn(e.target.value)}
    >
      <FormControlLabel
        value="yes"
        control={<Radio />}
        label="Yes"
      />
      <FormControlLabel
        value="no"
        control={<Radio />}
        label="No"
      />
    </RadioGroup>
  </FormControl>
</Grid>
                  <Grid item xs={12} className="grid-item">
                    <Box
                      display="flex"
                      justifyContent="center"
                      className="submit-button"
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                      >
                        Submit
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Container>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default RosterWrangler;
