import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, addDoc, query, where, getDocs, getDoc, onSnapshot, doc, updateDoc, } from 'firebase/firestore';
import { getStorage, ref as fbRef, uploadBytes as fbUploadBytes, getDownloadURL as fbGetDownloadURL } from 'firebase/storage';

const config = {
  apiKey: 'AIzaSyDRs7rrnKS9mP_AAGcW45JlNLPtapzl-xA',
  authDomain: 'mtv-smart-app.firebaseapp.com',
  projectId: 'mtv-smart-app',
  storageBucket: 'mtv-smart-app.appspot.com',
  messagingSenderId: '206230180246',
  appId: '1:206230180246:web:2cabea9274b0339cbc4db7',
  measurementId: 'G-B06MPTGBLH'
};

const app = initializeApp(config);


export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);

export const addData = async (collectionName, data) => {
  try {
    const docRef = await addDoc(collection(getFirestore(), collectionName), data);
    return docRef.id;
  } catch (error) {
    console.error("Error adding document: ", error);
    return null;
  }
};

export const getData = async (collectionName, docId) => {
  try {
    const docRef = doc(firestore, collectionName, docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      // Transform the document data into an array of user objects
      const users = Object.keys(data).map((key) => {
        if (key !== 'id') {
          return { name: key, ...data[key] };
        }
        return null;  // or return {};
      }).filter(Boolean);  // filter out any undefined values, just in case
      return { id: docSnap.id, users };
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error getting document: ", error.message);
    return null;
  }
};

// New function to fetch active storm events
export const fetchActiveStormEventsData = async () => {
  try {
    return await getDataByField('stormEvents', 'status', 'active');
  } catch (error) {
    console.error("Error getting active storm events: ", error.message);
    return null;
  }
};

// New function to get a specific storm event's data
export const getStormEventData = async (docId) => {
  try {
    const docRef = doc(firestore, 'stormEvents', docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      return data.teams ? data.teams.flatMap(team => team.roster) : [];
    } else {
      console.log("No such document!");
      return [];
    }
  } catch (error) {
    console.error("Error getting document: ", error.message);
    return [];
  }
};

export const fetchTeamData = async () => {
  try {
    const q = query(collection(firestore, 'dailysignin'));  // change 'teams' to 'dailysignin'
    const querySnapshot = await getDocs(q);
    const teams = [];
    querySnapshot.forEach((doc) => {
      teams.push({ id: doc.id, ...doc.data() });
    });
    return teams;
  } catch (error) {
    console.error("Error getting team data: ", error.message);
    return null;
  }
};

export const getDataByField = async (collectionName, field, value) => {
  const q = query(collection(getFirestore(), collectionName), where(field, "==", value));
  const querySnapshot = await getDocs(q);
  const data = [];
  querySnapshot.forEach((doc) => {
    data.push({ id: doc.id, ...doc.data() });
  });
  return data;
};

export function ref(storage, path) {
  return fbRef(storage, path);
}

export function uploadBytes(storageRef, file) {
  return fbUploadBytes(storageRef, file);
}

export function getDownloadURL(storageRef) {
  return fbGetDownloadURL(storageRef);
}

export const getActiveStormEventsWithTeams = async () => {
  try {
    const activeStormEvents = await getDataByField('stormEvents', 'status', 'active');
    return activeStormEvents.flatMap(event => event.teams);
  } catch (error) {
    console.error("Error getting active storm events with teams: ", error.message);
    return null;
  }
};

export const updateData = async (collectionName, docId, data) => {
  const docRef = doc(firestore, collectionName, docId);
  await updateDoc(docRef, data);
};

export function subscribeToSubCollection(parentCollectionName, parentId, subCollectionName, callback) {
  const parentRef = doc(firestore, parentCollectionName, parentId);
  const subCollection = collection(parentRef, subCollectionName);

  const unsubscribe = onSnapshot(subCollection, (querySnapshot) => {
    let data = [];
    querySnapshot.forEach((doc) => {
      data.push({ id: doc.id, ...doc.data() });
    });
    callback(data);
  });
  return unsubscribe;
}

export function subscribeToCollection(collectionName, callback) {
  const q = query(collection(firestore, collectionName));
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    let data = [];
    querySnapshot.forEach((doc) => {
      data.push({ id: doc.id, ...doc.data() });
    });
    callback(data);
  });
  return unsubscribe;
}

export { getDocs, collection, getFirestore, doc, getDoc, 
  updateDoc, query, where, onSnapshot } from 'firebase/firestore';


