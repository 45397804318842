import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { query, where, collection, getDocs, getFirestore } from "firebase/firestore";
import { Typography, Grid } from '@mui/material';
import dayjs from 'dayjs';

const TotalBucketTrucks = () => {
  // Initialize state for storing current and previous day's bucket trucks
  const [currentTotalBuckets, setCurrentTotalBuckets] = useState(0);
  const [prevTotalBuckets, setPrevTotalBuckets] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();
      const currentDate = dayjs().format('YYYY-MM-DD');
      const prevDate = dayjs().subtract(1, 'day').format('YYYY-MM-DD');

      // Query for current date
      const currentQuery = query(
        collection(db, "dailyReports"),
        where("date", "==", currentDate)
      );

      // Query for previous date
      const prevQuery = query(
        collection(db, "dailyReports"),
        where("date", "==", prevDate)
      );

      // Fetch and set current date total
      const currentSnapshot = await getDocs(currentQuery);
      let currentSum = 0;
      currentSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.selectedStormEvent) {
          currentSum += parseInt(data.numBuckets, 10);
        }
      });
      setCurrentTotalBuckets(currentSum);

      // Fetch and set previous date total
      const prevSnapshot = await getDocs(prevQuery);
      let prevSum = 0;
      prevSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.selectedStormEvent) {
          prevSum += parseInt(data.numBuckets, 10);
        }
      });
      setPrevTotalBuckets(prevSum);
    };
    fetchData();
  }, []);

  const chartData = {
    labels: ['Previous Day', 'Current Day'],
    datasets: [
      {
        label: 'Bucket Trucks',
        data: [prevTotalBuckets, currentTotalBuckets],
        backgroundColor: ['rgba(244, 67, 54, 0.5)', 'rgba(33, 150, 243, 0.5)'], // Different colors for previous and current day
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: false
      },
    },
    scales: {
      x: {
        beginAtZero: true
      },
      y: {
        beginAtZero: true
      }
    },
    maintainAspectRatio: false,
  };

  return (
    <div style={{ height: '250px', width: '100%' }}>
      <Grid container spacing={6} alignItems="center">
        <Grid item xs={12}>
          <Bar data={chartData} options={chartOptions} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" align="center" style={{ fontWeight: 'bold', padding: '1px' }}>
            Previous: {prevTotalBuckets} | Today: {currentTotalBuckets}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default TotalBucketTrucks;
